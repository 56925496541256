import React, { useState } from "react";
import ImageUpload from "./../../../Pages/ImageUpload";

const Question2 = () => {
    // State variables
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [childSubCategory, setChildSubCategory] = useState('');
    const [questions, setQuestions] = useState([
        { id: 1, language: 'English', question: '', options: ['', '', '', ''], answer: '', answersDescri: '', approved: false },
        // Add more initial questions as needed
    ]);

    // Function to handle input change for questions and options
    const handleInputChange = (index, event, field) => {
        const newQuestions = [...questions];
        if (field === 'question') {
            newQuestions[index].question = event.target.value;
        } else if (field.startsWith('option')) {
            const optionIndex = parseInt(field.slice(-1), 10);
            newQuestions[index].options[optionIndex] = event.target.value;
        } else if (field === 'answer') {
            newQuestions[index].answer = event.target.value;
        } else if (field === 'answersDescri') {
            newQuestions[index].answersDescri = event.target.value;
        }
        setQuestions(newQuestions);
    };

    const categoryToSubcategories = {
        Nursery: ['LKG 1', 'LKG 2', 'LKG 3', 'LKG 4'],
        '10th': ['Hindi', 'English', 'Mathematics', 'Computer'],
        '12th': ['Hindi', 'English', 'Mathematics', 'Computer', 'Biology', 'Sciences'],
        'Competitive Exam': {
            SSC: ['Hindi', 'English', 'Maths', 'Reasoning'],
            Railway: ['General Awareness', 'Reasoning', 'Quantitative Aptitude'],
            UPCS: ['General Studies', 'Optional Subjects', 'Essay Writing'],
            CGPSC: ['Prelims', 'Mains', 'Interview']
        }
    };

    // Function to add a new question
    const addQuestion = () => {
        const newId = questions.length + 1;
        const newEnglishQuestion = { id: newId, language: 'English', question: '', options: ['', '', '', ''], answer: '', answersDescri: '', approved: false };
        setQuestions([...questions, newEnglishQuestion]);
    };

    // Function to handle approval of a question
    const handleApprove = (index) => {
        const newQuestions = [...questions];
        newQuestions[index].approved = true;
        setQuestions(newQuestions);
    };

    // Function to handle deletion of a question
    const handleDelete = (index) => {
        const newQuestions = [...questions];
        newQuestions.splice(index, 1);
        setQuestions(newQuestions);
    };

    return (
        <>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className="main-header-examine-m">
                        Question Master - Approval Page
                    </div>
                    <div className="input-text-box-main">
                        {/* Category and Subcategory selection */}
                        <div className="input-box-examine">
                            <select
                                className="input-examine-master"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                required
                            >
                                <option value="" disabled>--Select Category--</option>
                                <option value="Nursery">Nursery</option>
                                <option value="10th">10th</option>
                                <option value="12th">12th</option>
                                <option value="Competitive Exam">Competitive Exam</option>
                            </select>
                            <label className="input-label-master-examine-fixed2">Category</label>
                        </div>

                        {/* Subcategory selection */}
                        {category && (
                            <div className="input-box-examine">
                                <select
                                    className="input-examine-master"
                                    value={subCategory}
                                    onChange={(e) => setSubCategory(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>--Select Sub Category--</option>
                                    {Array.isArray(categoryToSubcategories[category])
                                        ? categoryToSubcategories[category].map((subcat, index) => (
                                            <option key={index} value={subcat}>{subcat}</option>
                                        ))
                                        : Object.keys(categoryToSubcategories[category]).map((key, index) => (
                                            <option key={index} value={key}>{key}</option>
                                        ))
                                    }
                                </select>
                                <label className="input-label-master-examine-fixed2">Sub Category</label>
                            </div>
                        )}

                        {/* Child Subcategory selection */}
                        {subCategory && categoryToSubcategories[category][subCategory] && (
                            <div className="input-box-examine">
                                <select
                                    className="input-examine-master"
                                    value={childSubCategory}
                                    onChange={(e) => setChildSubCategory(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>--Select Child Sub Category--</option>
                                    {categoryToSubcategories[category][subCategory].map((childSubcat, index) => (
                                        <option key={index} value={childSubcat}>{childSubcat}</option>
                                    ))}
                                </select>
                                <label className="input-label-master-examine-fixed2">Child Sub Category</label>
                            </div>
                        )}

                        {/* Status input */}
                        <div className="input-box-examine">
                            <input type="text" className="input-examine-master" required />
                            <label className="input-label-master-examine">Status</label>
                        </div>

                        {/* Category Description input */}
                        <div className="input-box-examine">
                            <textarea className="input-examine-master" required></textarea>
                            <label className="input-label-master-examine-fixed">Category Description</label>
                        </div>

                        {/* Image upload */}
                        <div style={{ height: "50px" }} className="input-box-examine">
                            <ImageUpload />
                        </div>
                    </div>

                    {/* Display questions for approval */}
                    <div className="input-text-box-main">
                        {questions.map((question, index) => (
                            <div key={question.id} className="question-card">
                                <div className="main-header-examine-m">
                                    {question.id} - {question.language} Question
                                </div>
                                <div className="input-box-examine">
                                    <input
                                        type="text"
                                        className="input-examine-master"
                                        value={question.question}
                                        onChange={(e) => handleInputChange(index, e, 'question')}
                                        required
                                    />
                                    <label className="input-label-master-examine">{question.language} Question</label>
                                </div>
                                <div className="input-text-box-main">
                                    {question.options.map((option, optIndex) => (
                                        <div key={optIndex} className="input-box-examine">
                                            <input
                                                type="text"
                                                className="input-examine-master"
                                                value={option}
                                                onChange={(e) => handleInputChange(index, e, `option${optIndex}`)}
                                                required
                                            />
                                            <label className="input-label-master-examine">Option {String.fromCharCode(65 + optIndex)}</label>
                                        </div>
                                    ))}
                                    <div className="input-box-examine">
                                        <input
                                            type="text"
                                            className="input-examine-master"
                                            value={question.answer}
                                            onChange={(e) => handleInputChange(index, e, 'answer')}
                                            required
                                        />
                                        <label className="input-label-master-examine">Answer</label>
                                    </div>
                                    <div style={{ height: "50px" }} className="input-box-examine">
                                        {/* Additional content */}
                                    </div>
                                </div>
                                <div className="input-box-examine">
                                    <textarea
                                        className="input-examine-master"
                                        value={question.answersDescri}
                                        onChange={(e) => handleInputChange(index, e, 'answersDescri')}
                                        required
                                    ></textarea>
                                    <label className="input-label-master-examine-fixed">Answer Description</label>
                                </div>
                                <div className="button-container">
                                    <button onClick={() => handleApprove(index)} className="appdrove-btn-main-approve">Approve</button>
                                    <button style={{paddingLeft:"10px"}} onClick={() => handleDelete(index)} className="appdrove-btn-main-delete">Delete</button>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Button to add new question */}
                    <button onClick={addQuestion} className="add-question-button">Add Question</button>
                </div>
            </div>
        </>
    );
};

export default Question2;

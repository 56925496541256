import api, { getHeaders } from "./Server";

export const GetAdmin = async () => {
    try {
        const response = await api.get('./admins', {
            headers: getHeaders()
        })
        return response.data;

    } catch (error) {
        console.error('Error fetching admins:', error);
        throw error;
    }

}
export const createAdmin = async (formData) => {
    try {
        const response = await api.post('/admins', formData, {
            headers: getHeaders()
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error creating admin:', error);
        throw error;
    }
};


export const updateAdmin = async (id, data) => {
    try {
        const response = await api.put(`/admins/${id}`, data, {
            headers: getHeaders()
        })
        return response.data;
    } catch (error) {
        console.error('Error updating admin:', error);
        throw error;
    }
}
export const getByIdAdmin = async (id, data) => {
    try {
        const response = await api.get(`/admins/${id}`, data, {
            headers: getHeaders()
        })
        return response.data;
    } catch (error) {
        console.error('Error updating admin:', error);
        throw error;
    }
}

export const deleteAdmin = async (id, data) => {
    try {
        const response = await api.delete(`/admins/${id}`,data, {
            headers: getHeaders()
        })
        return response.data;
    } catch (error) {
        console.error('Error deleting admin:', error);
        throw error;
    }
}

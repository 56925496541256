import React from 'react';
import swal from 'sweetalert';

// bhai ye ha khana de te he 
const InsertSuccessAlert = ({ message }) => {
  // Display the success alert when the component is mounted
  React.useEffect(() => {
    swal("Success!", "Item has been inserted successfully!", "success");
  }, [message]);

  return null;
};

export default InsertSuccessAlert;

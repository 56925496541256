import React, { useContext, useEffect, useState } from 'react';
import './../Assets/css/ExamplePaper.css';
import UsersHeader from '../Components/UsersHeader';
import { MyContext } from '../AppRoutes';
import { useNavigate, useParams } from 'react-router-dom';
import { getByChildCategoryId } from '../Api/PreviousYearPaper';

function ExamplePaper() {
  const { UserLogout, API_BASE_URL } = useContext(MyContext);
  const [loading, setLoading] = useState(true);
  const [updatedData, setUpdatedData] = useState([]); // Initialize as an array
  const [selectedYear, setSelectedYear] = useState('2021');
  const { id } = useParams();

  const handleYearClick = (year) => {
    setSelectedYear(year);
  };

  useEffect(() => {
    const fetchCategoryById = async () => {
      try {
        const data = await getByChildCategoryId(id);
        if (data.data && Array.isArray(data.data) && data.data.length > 0) {
          console.log('-----Fetched Data---->', data.data); // Log the fetched data
          setUpdatedData(data.data);
        } else {
          console.log('No data found for the given child_category_id.');
          setUpdatedData([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setUpdatedData([]);
      }
    };
    fetchCategoryById();
  }, [id]);

  const languages = ['English', 'Hindi'];
  const paperTypes = ['Unsolved', 'Solved'];



  const renderContentForYear = (year) => (
    <>

      <div className="contentRow">
        <div className="subjectColumn">
          <div className='papersColumn ' style={{ display: "flex", justifyContent: "space-around" }}>
            <div className='langauge-container'>English</div>
            <div className='langauge-container'>Hindi</div>
          </div>
          {/* <div className='papersColumn ' style={{display:"flex", justifyContent:"space-around"}}>
                <div className='langauge-container'>Unsolved paper</div>
                <div className='langauge-container'>Solved Paper</div>
                <div className='langauge-container'>Unsolved paper</div>
                <div className='langauge-container'>Solved Paper</div>
            </div> */}
          <table>
            <thead>
              <tr className='tab-header-main-xl'>
                <th>Index</th>
                <th>Year</th>
                <th>Subject</th>
                <th>Unsolved PDF (English)</th>
                <th>Solved PDF (English)</th>
                <th>Unsolved PDF (Hindi)</th>
                <th>Solved PDF (Hindi)</th>
              </tr>
            </thead>
            <tbody>
              {updatedData.map((item, index) => (
                <tr className='table-body-main-xl' key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.year}</td>
                  <td>{item.exam_subject.subject_name}</td>
                  <td>
                    {item.file_path_unsolve_english ? (
                      <a
                        href={`${API_BASE_URL}${item.file_path_unsolve_english}`}
                        download={`unsolve-english-${item.exam_subject.subject_name}.pdf`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Download Unsolved PDF (English)
                      </a>
                    ) : (
                      <div>No file available</div>
                    )}
                  </td>
                  <td>
                    {item.file_path_solve_english ? (
                      <a
                        href={`${API_BASE_URL}${item.file_path_solve_english}`}
                        download={`solve-english-${item.exam_subject.subject_name}-${item.id}.pdf`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Download Solved PDF (English)
                      </a>
                    ) : (
                      <div>No file available</div>
                    )}
                  </td>
                  <td>
                    {item.file_path_unsolve ? (
                      <a
                        href={`${API_BASE_URL}${item.file_path_unsolve}`}
                        download={`unsolve-hindi-${item.exam_subject.subject_name}.pdf`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Download Unsolved PDF (Hindi)
                      </a>
                    ) : (
                      <div>No file available</div>
                    )}
                  </td>

                  <td>
                    {item.file_path_solve ? (
                      <a
                        href={`${API_BASE_URL}${item.file_path_solve}`}
                        download={`solve-hindi-${item.exam_subject.subject_name}-${item.id}.pdf`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Download Solved PDF (Hindi)
                      </a>
                    ) : (
                      <div>No file available</div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>


        </div>



      </div>
    </>
  );

  return (
    <>
        <div className='after-login-navbar'>
          <UsersHeader UserLogout={UserLogout} />
        </div>
      <div className='main-contaiter-xl'>
        <div>
          <h3>Previous Years Queastion Paper </h3>
        </div>
        <div className="examplePaperContainer">
          <div className="headerRow">
            {['2021', '2022', '2023', '2024'].map((year) => (
              <div
                key={year}
                className={`yearColumn ${selectedYear === year ? 'active' : ''}`}
                onClick={() => handleYearClick(year)}
              >
                {year}
              </div>
            ))}
          </div>

          {renderContentForYear(selectedYear)}
        </div>
      </div>
    </>
  );
}

export default ExamplePaper;

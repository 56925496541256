import React, { useState, useEffect } from 'react';
import img from './../Assets/img/logo.jpeg';
import Man from "./../Assets/img/man.png";
// import NavTabs from './../Components/navTabs';

const TestSection = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [showResults, setShowResults] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answeredCount, setAnsweredCount] = useState(0);
    const [markedCount, setMarkedCount] = useState(0);
    const [notAnsweredCount, setNotAnsweredCount] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);


    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen().catch((err) => {
            alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
          });
        } else if (document.exitFullscreen) {
          document.exitFullscreen();
        }
        setIsFullscreen(!isFullscreen);
      };
      useEffect(() => {
        // Enable fullscreen on page load
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen();
        }
    
        // Set the initial button state to false (show the button to exit fullscreen)
        setIsFullscreen(true);
      }, []);

    // *******************{Time}**********************
    const [timeLeft, setTimeLeft] = useState(10 * 60); // 30 minutes in seconds

    useEffect(() => {
        if (timeLeft <= 0) {
            handleTimeUp();
            return;
        }

        const intervalId = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const handleTimeUp = () => {
        alert('Time is up! Auto-submitting the test.');
        handleSubmit();
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const questions = [
        // Hindi Questions (6)
        {
            id: 1,
            question: "निम्नलिखित में से किस वाक्य का शुद्ध रूप क्या है?",
            options: ['वह स्कूल जाती है।', 'वह स्कूल जाता है।', 'वह स्कूल जाती हूँ।', 'वह स्कूल जाते हैं।'],
            correctAnswer: 'वह स्कूल जाती है।',
            subtype: 'Hindi'
        },
        {
            id: 2,
            question: "निम्नलिखित में से कौन-सा वाक्य सही है?",
            options: ['मैंने खाना खाया।', 'मैंने खाया खाना।', 'खाया मैंने खाना।', 'खाना मैंने खाया।'],
            correctAnswer: 'मैंने खाना खाया।',
            subtype: 'Hindi'
        },
        {
            id: 3,
            question: "निम्नलिखित में से किस वाक्य का प्रयोग सही है?",
            options: ['वह बाजार गया।', 'वह बाजार गयी।', 'वह बाजार गया हूँ।', 'वह बाजार गया है।'],
            correctAnswer: 'वह बाजार गया।',
            subtype: 'Hindi'
        },
        {
            id: 4,
            question: "निम्नलिखित में से कौन-सा शब्द अशुद्ध है?",
            options: ['गर्मी', 'सर्दी', 'बरसात', 'वर्षा'],
            correctAnswer: 'बरसात',
            subtype: 'Hindi'
        },
        {
            id: 5,
            question: "निम्नलिखित में से कौन-सा वाक्य शुद्ध नहीं है?",
            options: ['राम ने खाना खाया।', 'सीता ने पानी पिया।', 'मोहन ने पुस्तक पढ़ी।', 'गगन ने खेल खेला।'],
            correctAnswer: 'मोहन ने पुस्तक पढ़ी।',
            subtype: 'Hindi'
        },
        {
            id: 6,
            question: "निम्नलिखित में से किस वाक्य का अर्थ सही है?",
            options: ['वह काम कर रही है।', 'वह काम कर रहे हैं।', 'वह काम कर रहा हूँ।', 'वह काम कर रहा है।'],
            correctAnswer: 'वह काम कर रहा है।',
            subtype: 'Hindi'
        },

        // English Questions (6)
        {
            id: 7,
            question: "Which sentence is grammatically correct?",
            options: ['He go to school.', 'He goes to school.', 'He going to school.', 'He gone to school.'],
            correctAnswer: 'He goes to school.',
            subtype: 'English'
        },
        {
            id: 8,
            question: "Identify the synonym of 'happy'.",
            options: ['Sad', 'Joyful', 'Angry', 'Tired'],
            correctAnswer: 'Joyful',
            subtype: 'English'
        },
        {
            id: 9,
            question: "Choose the correct spelling.",
            options: ['Acommodation', 'Accommodation', 'Acommoddation', 'Accomadation'],
            correctAnswer: 'Accommodation',
            subtype: 'English'
        },
        {
            id: 10,
            question: "Which word is an antonym of 'difficult'?",
            options: ['Hard', 'Easy', 'Tough', 'Complicated'],
            correctAnswer: 'Easy',
            subtype: 'English'
        },
        {
            id: 11,
            question: "Fill in the blank: She _____ a beautiful dress.",
            options: ['wear', 'wears', 'wearing', 'wore'],
            correctAnswer: 'wears',
            subtype: 'English'
        },
        {
            id: 12,
            question: "Which sentence is in the past tense?",
            options: ['I am eating.', 'I was eating.', 'I will eat.', 'I eat.'],
            correctAnswer: 'I was eating.',
            subtype: 'English'
        },

        // Maths Questions (7)
        {
            id: 13,
            question: "What is the value of 5 + 7?",
            options: ['10', '11', '12', '13'],
            correctAnswer: '12',
            subtype: 'Maths'
        },
        {
            id: 14,
            question: "What is the square root of 64?",
            options: ['6', '7', '8', '9'],
            correctAnswer: '8',
            subtype: 'Maths'
        },
        {
            id: 15,
            question: "What is 15% of 200?",
            options: ['20', '25', '30', '35'],
            correctAnswer: '30',
            subtype: 'Maths'
        },
        {
            id: 16,
            question: "Solve: 9 * 9",
            options: ['72', '81', '90', '99'],
            correctAnswer: '81',
            subtype: 'Maths'
        },
        {
            id: 17,
            question: "What is the next prime number after 5?",
            options: ['6', '7', '8', '9'],
            correctAnswer: '7',
            subtype: 'Maths'
        },
        {
            id: 18,
            question: "What is the value of π (pi) approximately?",
            options: ['2.14', '2.71', '3.14', '3.71'],
            correctAnswer: '3.14',
            subtype: 'Maths'
        },
        {
            id: 19,
            question: "Solve: 25 ÷ 5",
            options: ['4', '5', '6', '7'],
            correctAnswer: '5',
            subtype: 'Maths'
        },

        // Reasoning Questions (6)
        {
            id: 20,
            question: "एक निश्चित कूट भाषा में, 'ADVANCE' को 'VDAAECN' लिखा जाता है और 'BABYSIT' को 'BABYTIS' लिखा जाता है। उसी भाषा में, 'AFFABLE' को क्या लिखा जाएगा?",
            options: ['VDAAECN', 'FFAAELB', 'FFAAEBL', 'AAFFELB'],
            correctAnswer: 'FFAAEBL',
            subtype: 'Reasoning'
        },
        {
            id: 21,
            question: "अगर 'APPLE' को 'LAPEP' लिखा जाता है, तो 'BANANA' को कैसे लिखा जाएगा?",
            options: ['NABANA', 'ANANAB', 'NABNAA', 'NABANA'],
            correctAnswer: 'ANANAB',
            subtype: 'Reasoning'
        },
        {
            id: 22,
            question: "एक विशेष भाषा में, 'ORANGE' को 'GENARO' लिखा जाता है। उसी भाषा में, 'PINEAPPLE' को क्या लिखा जाएगा?",
            options: ['ELPPAPINE', 'ENIPAPLEP', 'ELPPANIPE', 'ELPPAPINE'],
            correctAnswer: 'ELPPAPINE',
            subtype: 'Reasoning'
        },
        {
            id: 23,
            question: "यदि 'COMPUTER' को 'RETUPMOC' लिखा जाता है, तो 'KEYBOARD' को कैसे लिखा जाएगा?",
            options: ['DRAOBYEK', 'DRAOYEBK', 'DROABYEK', 'DRAOBYEK'],
            correctAnswer: 'DRAOBYEK',
            subtype: 'Reasoning'
        },
        {
            id: 24,
            question: "एक कूट भाषा में, 'MONKEY' को 'YEKONM' लिखा जाता है। उसी भाषा में, 'ELEPHANT' को क्या लिखा जाएगा?",
            options: ['TNAPHELE', 'TNAHEPLE', 'TNAHEPLE', 'TNAPHELE'],
            correctAnswer: 'TNAPHELE',
            subtype: 'Reasoning'
        },
        {
            id: 25,
            question: "यदि 'PENCIL' को 'LICNEP' लिखा जाता है, तो 'PAPER' को कैसे लिखा जाएगा?",
            options: ['REPAP', 'REPPA', 'REAPP', 'REPAP'],
            correctAnswer: 'REPPA',
            subtype: 'Reasoning'
        }
    ];



    const handleSubmit = () => {
        setShowResults(true);
    };

    const [questionStatus, setQuestionStatus] = useState(Array(questions.length).fill('notVisited'));

    const handleNextQuestion = () => {
        debugger
        const currentQuestion = questions[currentQuestionIndex];
        if (selectedOptions[currentQuestion.id]) {
            const newStatus = [...questionStatus];
            newStatus[currentQuestionIndex] = 'answered';
            setQuestionStatus(newStatus);
            setAnsweredCount(answeredCount + 1);
        } else {
            const newStatus = [...questionStatus];
            newStatus[currentQuestionIndex] = 'notAnswered';
            setQuestionStatus(newStatus);
            setNotAnsweredCount(notAnsweredCount + 1);
        }

        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setShowResults(true);
            alert('All questions answered!');
        }
    };


    const handleQuestionClick = (index) => {
        setCurrentQuestionIndex(index);
    };

    const handleOptionChange = (questionId, option) => {
        setSelectedOptions({
            ...selectedOptions,
            [questionId]: option,
        });

        const newStatus = [...questionStatus];
        newStatus[questionId - 1] = 'answered';
        setQuestionStatus(newStatus);
    };

    const handleMarkQuestion = () => {
        const newStatus = [...questionStatus];
        newStatus[currentQuestionIndex] = 'marked';
        setQuestionStatus(newStatus);
        setMarkedCount(markedCount + 1);
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setShowResults(true);
            alert('All questions answered!');
        }
    };

    const handleClearResponse = () => {
        const currentQuestion = questions[currentQuestionIndex];
        const newSelectedOptions = { ...selectedOptions };
        delete newSelectedOptions[currentQuestion.id];
        setSelectedOptions(newSelectedOptions);

        const newStatus = [...questionStatus];
        newStatus[currentQuestionIndex] = 'notAnswered';
        setQuestionStatus(newStatus);
    };

    const getBoxClassName = (index) => {
        let className = 'box';
        if (index === currentQuestionIndex) {
            className += ' active';
        }
        switch (questionStatus[index]) {
            case 'answered':
                return className + ' answered';
            case 'marked':
                return className + ' marked';
            case 'notVisited':
                return className + ' not-visited';
            case 'notAnswered':
                return className + ' not-answered';
            default:
                return className;
        }
    };

    const filterQuestionsByType = (type) => {
        return questions.filter(q => q.type === type);
    };
    const [activeTab, setActiveTab] = useState('English'); // default tab
    const filteredQuestions = questions.filter(q => q.subtype === activeTab);


    return (
        <div className="test-container">

            <div className="company_logo instraction-test-container-title">
                <div>
                    <img className='texi-img-xl2' src={img} alt="logo" />
                </div>
                <div><span className='text-title-ss-xl'>
                    SSC Selection Post Expected Paper (Based on: 20th June 2024)
                </span></div>
                <div>
                    <button className='button-full-screen' onClick={toggleFullscreen}>
                        {isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
                    </button>
                </div>
            </div>

            <div className='instraction-text-title-xxl'>
                <div className='test-text-title-xxl-left'>
                    <div className='navtabs-bar-main-container'>
                        <div>
                            <span className='test-sect3-xl'>Sections</span>
                        </div>
                    </div>
                    <div className='navtabs-que-container'>

                        <div className='nav-sec-xl-left'>
                            <h4 className='que-title-box-xl3'>Question No. {currentQuestionIndex + 1}</h4>
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <div
                                        className={`nav-link ${activeTab === 'English' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('English')}
                                    >
                                        English
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div
                                        className={`nav-link ${activeTab === 'Hindi' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('Hindi')}
                                    >
                                        Hindi
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div
                                        className={`nav-link ${activeTab === 'Maths' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('Maths')}
                                    >
                                        Mathematics
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div
                                        className={`nav-link ${activeTab === 'Reasoning' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('Reasoning')}
                                    >
                                        Reasoning
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className='nav-sec-xl-right'>
                            <div className='nav-sec-xl-test'>
                                <span>Marks</span>
                                <div className='marks-dis-max'>
                                    <label className='text-color-green'>+2</label>
                                    <label className='text-color-danger'>-0.5</label>
                                </div>
                            </div>
                            <div style={{ display: "grid" }} className='nav-sec-xl-test'>
                                Time
                                <label>{formatTime(timeLeft)}</label>
                            </div>
                            <div className='nav-sec-xl-test'>
                                <span>View in</span>
                                <select name="language-text-cc" className="language-text-cc">
                                    <option value="01">Select</option>
                                    <option value="02">English</option>
                                    <option value="03">Hindi</option>
                                </select>
                            </div>
                            <div className='nav-sec-xl-test'>
                                <i className="fa-solid fa-triangle-exclamation"></i>
                                Report
                            </div>
                        </div>
                    </div>


                    {/* <div className='que-body-section-container'>
                        {questions.slice(currentQuestionIndex, currentQuestionIndex + 1).map((q) => (
                            <div key={q.id} className='que-acc-xl34'>
                                <div>{q.question}</div>
                                <div className='que-opject-xll'>
                                    {q.options.map((option, optIndex) => (
                                        <label key={optIndex} className='label-mx-xl2'>
                                            <input
                                                type="radio"
                                                name={`radio-group-${q.id}`}
                                                checked={selectedOptions[q.id] === option}
                                                onChange={() => handleOptionChange(q.id, option)}
                                            />
                                            <span className='label-topix-xkjxz'>{option}</span>
                                        </label>
                                    ))}
                                </div>
                                {showResults && (
                                    <div>
                                        {selectedOptions[q.id] === q.correctAnswer ? (
                                            <span style={{ color: 'green' }}>Correct!</span>
                                        ) : (
                                            <span style={{ color: 'red' }}>Incorrect. The correct answer is {q.correctAnswer}.</span>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div> */}
                    <div className="tab-content">
                        <div id="englishTab" className="tab-pane fade show active">
                            <div className='que-body-section-container'>
                                {filteredQuestions.slice(0, 1).map((q) => (
                                    <div key={q.id} className='que-acc-xl34'>
                                        <div>{q.question}</div>
                                        <div className='que-opject-xll'>
                                            {q.options.map((option, optIndex) => (
                                                <label key={optIndex} className='label-mx-xl2'>
                                                    <input
                                                        type="radio"
                                                        name={`radio-group-${q.id}`}
                                                        checked={selectedOptions[q.id] === option}
                                                        onChange={() => handleOptionChange(q.id, option)}
                                                    />
                                                    <span className='label-topix-xkjxz'>{option}</span>
                                                </label>
                                            ))}
                                        </div>
                                        {showResults && (
                                            <div>
                                                {selectedOptions[q.id] === q.correctAnswer ? (
                                                    <span style={{ color: 'green' }}>Correct!</span>
                                                ) : (
                                                    <span style={{ color: 'red' }}>Incorrect. The correct answer is {q.correctAnswer}.</span>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div id="hindiTab" className="tab-pane fade">
                            <div className='que-body-section-container'>
                                {/* Hindi questions go here, similar to EnglishTab */}
                            </div>
                        </div>
                        <div id="mathematicsTab" className="tab-pane fade">
                            <div className='que-body-section-container'>
                                {/* Mathematics questions go here, similar to EnglishTab */}
                            </div>
                        </div>
                        <div id="reasoningTab" className="tab-pane fade">
                            <div className='que-body-section-container'>
                                {/* Reasoning questions go here, similar to EnglishTab */}
                            </div>
                        </div>
                    </div>
                    {/* </div> */}

                    <div className='btn-save-que-ans-main'>
                        <div>
                            <button className='back-btn-left-btn' onClick={handleMarkQuestion}>Mark For Review & Next</button>
                            <button className='back-btn-left-btn' onClick={handleClearResponse}>Clear Response</button>
                        </div>
                        <div>
                            <button className='next-btn-clr-change-xl' onClick={handleNextQuestion}>Save & Next</button>
                        </div>
                    </div>
                </div>
                <div className="test-text-title-xxl-right">
                    <div className="panel-profile-xxl ">
                        <div className='img-main-xl51'>
                            <img className='texi-img-xxl1' src={Man} alt="logo" />
                        </div>
                        <div className='text-title-name-xm1'>
                            <h3 className='profile-member-name-xl1 '>Viliyan</h3>
                        </div>
                    </div>
                    <div className='ans-leghth-panel-profile-xxl'>
                        <div className='ans-lgth-dis-xl'>
                            <label className='ans-dis-max2' > <span className='fa-success-btn'>{answeredCount}</span> Answered</label>
                            <label className='ans-dis-max2' > <span className='fa-marked-btn'>{markedCount}</span>Marked</label>
                            <label className='ans-dis-max2' > <span className='fa-not-visited-btn'>{questions.length - currentQuestionIndex}</span>Not Visited</label>
                            <label className='ans-dis-max2' style={{ width: "160px" }} > <span className='fa-marked-answered-btn'>{markedCount}</span>Marked And Answered</label>
                            <label className='ans-dis-max2' > <span className='fa-not-answered-btn'>{notAnsweredCount}</span>Not Answered</label>
                        </div>
                    </div>
                    <div className='ans-sec-pk-xl'>
                        <label className='sect-xl3'>Sections : <span className='sec-xl4'>English</span></label>
                    </div>
                    <div>
                        <div className="tab-content">
                            <div id="englishTab" className="tab-pane fade show active">
                                <div className='que-body-section-container'>
                                    {filteredQuestions.slice(0, 1).map((q) => (
                                        <div key={q.id} className='que-acc-xl34'>
                                            <div className="grid-container scrollbar-add">
                                                {Array.from({ length: questions.length }, (_, index) => (
                                                    <div key={index} className={getBoxClassName(index)} onClick={() => handleQuestionClick(index)}>
                                                        <label className='label-mx-xl'>{index + 1}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='final-submit-btn-xl'>
                        <button className='final-submit-btn-xl2' onClick={handleSubmit}>Submit Test</button>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default TestSection;

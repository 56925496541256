import React, { useState, useEffect, useContext } from "react";
import Table from 'rc-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import DeleteConfirmationModal from "../../../Components/DeleteConfirmationModal";
import { useNavigate } from 'react-router-dom';
import { getSubject, deleteSubject } from "../../../Api/Subject";
import Loader from "../../../Components/Loader";
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";

const ChildCategoryList = () => {
    const { type , handleLogout} = useContext(MyContext)
    // const pageTitle = pageTitles[location.pathname] || 'Subject master List';
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10); 
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/Admin');
        }
    },[])

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            setLoading(true);
            const response = await getSubject();
            setData(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteSubject(id);
            setData(data.filter(item => item.id !== id));
        } catch (error) {
            setError('Error deleting category');
        }
    };

    const confirmDelete = (id) => {
        setDeleteId(id);
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        await handleDelete(deleteId);
        setShowModal(false);
        setDeleteId(null);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
        setDeleteId(null);
    };

    const handleEdit = (id) => {
        navigate(`/Admin/SubjectMaster/${id}`);
    };

    const columns = [
        { title: 'S No', dataIndex: 'id', key: 'id', width: 50, className: 'table-header-xl', render: (text, record, index) => (currentPage - 1) * rowsPerPage + index + 1 },
        { title: 'Subject Name', dataIndex: 'subject_name', key: 'subject_name', width: 200, className: 'table-header-xl tab-wid-x2' },
        { title: 'status', dataIndex: 'status', key: 'status', width: 200, className: 'table-header-xl tab-wid-x2' },
        { title: 'priority', dataIndex: 'priority', key: 'priority', width: 100, className: 'table-header-xl tab-wid-x2' },
        { title: 'Date', dataIndex: 'updated_at', key: 'updated_at', width: 100, className: 'table-header-xl tab-wid-x2', render : (text) =>{
            const date = new Date(text);
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
            const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
            return `${formattedDate} ${formattedTime}`;
        }  },
        {
            title: 'Operations',
            key: 'operations',
            className: 'table-header-xl tab-wid-x3',
            render: (text, record) => (
                <>
                    <span><i className="fa-regular fa-pen-to-square approve-icon"
                        onClick={() => handleEdit(record.id)}></i></span>
                    <span className="ant-divider" />
                    <span><FontAwesomeIcon className="del-icon" onClick={() => confirmDelete(record.id)} icon={faTrashAlt} /></span>
                </>
            ),
        },
    ];

    // Pagination logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentData = data.slice(indexOfFirstRow, indexOfLastRow);

    const totalPages = Math.ceil(data.length / rowsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>
            <div className="main-container-examine-btn-section">
                < Header type={type} handleLogout={handleLogout}/>
               
            </div>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <div className="approval-title-box-xl">
                        <div className="scroll-container">
                            <Table columns={columns} data={currentData} />
                            <div style={{ marginTop: "10px" }} className="pagination-controls">
                                <button className="BtnLeftRight" onClick={handlePrevPage} disabled={currentPage === 1}>
                                    <i className="fa-solid fa-angles-left"></i>
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button className="BtnLeftRight" onClick={handleNextPage} disabled={currentPage === totalPages}>
                                    <i className="fa-solid fa-angles-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {showModal && (
                    <DeleteConfirmationModal
                        onConfirm={handleConfirmDelete}
                        onCancel={handleCancelDelete}
                    />
                )}
                {loading && <Loader />}
            </div>
        </>
    );
};

export default ChildCategoryList;

import React, { useState, useEffect, useContext } from "react";
import Table from 'rc-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { faCircleCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import DeleteConfirmationModal from "../../../Components/DeleteConfirmationModal";
import { useNavigate } from 'react-router-dom';
import { getQuestion, deleteQuestion, createQuestion, ApproveQuestion } from "../../../Api/ExamQuestion";
import Loader from "../../../Components/Loader";
import ApproveConfirmationModal from "../../../Components/Alert/ApproveConfirmationModal";
// import '../../../Assets/css/aprovalkrish.css'
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";

const ApprovalMaster12 = () => {
    const {type, handleLogout  } = useContext(MyContext)
    // const pageTitle = pageTitles[location.pathname] || 'Subject master List';
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [currentId, setCurrentId] = useState({});
    const [deleteId, setDeleteId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10); // Fixed at 10 rows per page
    const navigate = useNavigate();

    useEffect(() => {

        fetchCategories();
        console.log(fetchCategories);

    }, []);

    const fetchCategories = async () => {
        try {
            setLoading(true);
            const response = await getQuestion();
            console.log(response.data);
            setData(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }


    };

    const handleDelete = async (id) => {
        try {
            await deleteQuestion(id);
            setData(data.filter(item => item.id !== id));
        } catch (error) {
            setError('Error deleting category');
        }
    };

    const confirmDelete = (id) => {
        setDeleteId(id);
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        await handleDelete(deleteId);
        setShowModal(false);
        setDeleteId(null);
    };
    const confirmApprove = (id, status) => {
        const data = {
            id: id,
            status: status
        }
        setCurrentId(data);
        setShowApproveModal(true); // Show the approval modal
    };

    const handleCancelDelete = () => {
        setShowModal(false);
        setDeleteId(null);
    };
    const handleConfirmApprove = async () => {
        await handleApprove(currentId);
        setShowApproveModal(false); // Hide the approval modal
        setCurrentId(null);
    };
    const handleCancelApprove = () => {
        setShowApproveModal(false); // Hide the approval modal
        setCurrentId(null);
    };

    const handleEdit = (id) => {
        navigate(`/Admin/ChildCategory/${id}`);
    };
    const handleApprove = async (data) => {

        try {

            await ApproveQuestion(data); // Replace with your API call for approval
            fetchCategories();
        } catch (error) {
            setError('Error approving question');
        }
    };


    const truncateText = (text, length = 30) => {
        if (!text) return ''; // Handle undefined or null cases
        return text.length > length ? text.substring(0, length) + '...' : text;
    };

    const columns = [
        {
            title: 'S No',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => (currentPage - 1) * rowsPerPage + index + 1
        },
        {
            title: 'Operator',
            dataIndex: 'admin.name',
            key: 'admin.name',
            width: 50,
            render: (text, record) => record.admin?.name || ''
        },

        // Truncate the 'question_name' field
        {
            title: 'Question',
            dataIndex: 'question_name',
            key: 'question_name',
            width: 200,
            render: text => <div dangerouslySetInnerHTML={{ __html: text }} />
        },

        // Truncate 'objective_a'
        {
            title: 'Obj A',
            dataIndex: 'objective_a',
            key: 'objective_a',
            width: 200,
            render: (text) => truncateText(text)
        },

        // Truncate 'objective_b'
        {
            title: 'Obj B',
            dataIndex: 'objective_b',
            key: 'objective_b',
            width: 200,
            render: (text) => truncateText(text)
        },

        // Truncate 'objective_c'
        {
            title: 'Obj C',
            dataIndex: 'objective_c',
            key: 'objective_c',
            width: 200,
            render: (text) => truncateText(text)
        },

        // Truncate 'objective_d'
        {
            title: 'Obj D',
            dataIndex: 'objective_d',
            key: 'objective_d',
            width: 200,
            render: (text) => truncateText(text)
        },

        // Truncate 'answer'
        {
            title: 'Answer',
            dataIndex: 'answer',
            key: 'answer',
            width: 200,
            render: (text) => truncateText(text)
        },

        // Truncate 'answer_description'
        {
            title: 'Answer_Desc',
            dataIndex: 'answer_description',
            key: 'answer_description',
            width: 100,
            render: text => <div dangerouslySetInnerHTML={{ __html: text }} />
        },

        // Status column
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                const status = Number(record.status);
                return (
                    <span style={{
                        padding: '5px 10px',
                        borderRadius: '4px',
                        color: 'white',
                        backgroundColor: status === 0 ? 'red' : 'green',
                    }}>
                        {status === 0 ? 'Pending' : 'Approved'}
                    </span>
                );
            }
        },
        //view column
        {
            title: 'View',
            key: 'operations',
            render: (text, record) => (
                <FontAwesomeIcon
                    className="details-icon"
                    icon={faEye} // Add an information icon
                    onClick={() => handleShowDetails(record)} // Trigger modal with current row data
                />
            )
        },
        // Operations column
        {
            title: 'Operations',
            key: 'operations',
            render: (text, record) => (
                <>
                    {!record.approved && (
                        <FontAwesomeIcon
                            onClick={() => confirmApprove(record.id, record.status === 0 ? 1 : 0)}
                            className="approve-icon"
                            icon={faCircleCheck}
                        />
                    )}

                    <FontAwesomeIcon
                        className="del-icon"
                        onClick={() => confirmDelete(record.id)}
                        icon={faTrashAlt}
                    />
                </>
            )
        }
    ];




    // Pagination logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentData = data.slice(indexOfFirstRow, indexOfLastRow);

    const totalPages = Math.ceil(data.length / rowsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };



    // modal.............

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [currentRowData, setCurrentRowData] = useState(null);

    const handleShowDetails = (row) => {
        setCurrentRowData(row); // Set the current row data
        setShowDetailsModal(true); // Open the modal
    };

    const handleCloseDetailsModal = () => {
        setShowDetailsModal(false); // Close the modal
        setCurrentRowData(null); // Reset current row data
    };






    return (

        <>
             <div className="main-container-examine-btn-section">
                < Header type={type} handleLogout={handleLogout} />
            </div>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <div className="approval-title-box-xl">
                        <div className="scroll-container">
                            <Table columns={columns} data={currentData} />
                            <div style={{ marginTop: "10px" }} className="pagination-controls">
                                <button className="BtnLeftRight" onClick={handlePrevPage} disabled={currentPage === 1}>
                                    <i className="fa-solid fa-angles-left"></i>
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button className="BtnLeftRight" onClick={handleNextPage} disabled={currentPage === totalPages}>
                                    <i className="fa-solid fa-angles-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {showModal && (
                    <DeleteConfirmationModal
                        onConfirm={handleConfirmDelete}
                        onCancel={handleCancelDelete}
                    />
                )}
                {showApproveModal && (
                    <ApproveConfirmationModal
                        onConfirm={handleConfirmApprove}
                        onCancel={handleCancelApprove}
                    />
                )}
                {loading && <Loader />}

                {/* show modal */}
                {showDetailsModal && currentRowData && (
                    <div className="modal-container">


                        <div className="modal-content">
                            <p>Question Details</p>

                            <div className="question-header">
                                <p><b>Question :</b>
                                    <span className="question-text">{currentRowData.question_name}</span>
                                </p>
                            </div>

                            <div className="options-container">

                                <div className="options-container1">
                                    <div className="option-item">
                                        <p className="option-text"> <b>Obj A:</b> {currentRowData.objective_a}</p>
                                    </div>
                                    <div className="option-item">
                                        <p className="option-text"> <b>Obj B:</b> {currentRowData.objective_b}</p>
                                    </div>
                                    <div className="option-item">
                                        <p className="option-text"> <b>Obj C:</b> {currentRowData.objective_c}</p>
                                    </div>
                                    <div className="option-item">
                                        <p className="option-text"> <b>Obj D:</b> {currentRowData.objective_d}</p>
                                    </div>
                                </div>



                                <div className="answer-container">
                                    <div className="answer-section">
                                        <span className="answer-text"><b>Answer:</b> {currentRowData.answer} </span>
                                    </div>
                                    <div className="description-section">
                                        <span className="description-text"><b>Description:</b> {currentRowData.answer_description}</span>
                                    </div>
                                    <div className="source-section">
                                        <span className="source-text"><b>Source:</b> {currentRowData.source}</span>
                                    </div>
                                    <div className="source-section">
                                        <span className="source-text"><b>Upload By:</b> {currentRowData.admin.name}</span>
                                        <span className="source-text"><b>Upload Time:</b> {currentRowData.admin.name}</span>
                                    </div>
                                </div>

                            </div>

                            <div className="approval-buttons">

                                <button to="#" className="approve-button" onClick={() => confirmApprove(currentRowData.id, currentRowData.status === 0 ? 1 : 0)} >
                                    <div className="button-content">
                                        <span className="button-text">Approved</span>
                                    </div>
                                </button>
                                <button to="#" className="disapprove-button" onClick={() => confirmDelete(currentRowData.id)}>
                                    <div className="button-content">
                                        <span className="button-text">Disapproved</span>
                                    </div>
                                </button>
                                <button to="#" className="disapprove-button" onClick={handleCloseDetailsModal}>
                                    <div className="button-content">
                                        <span className="button-text">Close</span>
                                    </div>
                                </button>

                            </div>
                        </div>

                    </div>
                )}

            </div>
        </>
    );
};

export default ApprovalMaster12;

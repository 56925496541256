import React, { createContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NavBar from './Components/NavBar';
import Home from './Pages/Dashboard/Home';
import LogIn from './Components/LogInPage/LogIn';
import UserLogin from './Components/LogInPage/UserLogin';
import SignUp from './Components/LogInPage/SignUp';
import AutoSlide from './Components/AutoSlide';
import Componemt1 from './Components/Componemt1';
import BlockProps from './Components/BlockProps';
import TestSeries from './Pages/Users/TestSeries';
import Footer from './Pages/Footer';
import Instructions from './Pages/Instructions';
import NotFound from './Pages/NotFound';
import TestSection from './Pages/TestSection';
import PreviousYearPaper from './Pages/PreviousYearPapers';
import Sidebars from './Components/Sidebar';
import ExamineMaster from './Pages/Admin/ExaminerMaster/ExamineMaster';
import ExaminerList from './Pages/Admin/ExaminerMaster/ExaminerList';
import CategoryMaster from './Pages/Admin/Category/CategoryMaster';
import Dashboard from './Pages/Admin/Dashboard';
import QuestionMaster from './Pages/Admin/UploadQuestion/QuestionMaster';
import ApprovalMaster from './Pages/Admin/UploadQuestion/ApprovalMaster';
import Question2 from './Pages/Admin/UploadQuestion/Question2';
import SidebarAdmin from './Pages/Admin/Props/SidebarAdmin';
import CategoryList from './Pages/Admin/Category/CategoryList';
import SubCategoryMaster from './Pages/Admin/SubCategory/SubCategoryMaster';
import SubCategoryList from './Pages/Admin/SubCategory/SubCategoryList';
import ChildCategory from './Pages/Admin/ChildCategory/ChildCategory';
import ChildCategoryList from './Pages/Admin/ChildCategory/ChildCategoryList';
import SubjectMaster from './Pages/Admin/ExaminerMaster/SubjectMaster';
import SubjectMasterList from './Pages/Admin/ExaminerMaster/SubjectMasterList';
import QuestionMaster2 from './Pages/Admin/UploadQuestion/QuestionMaster2';
import QuestionList from './Pages/Admin/UploadQuestion/QuestionList';
import FinalQuestionList from './Pages/Admin/UploadQuestion/FinalQuestionList';
import UsersList from './Pages/Admin/UsersMaster/UserList';
import Users from './Pages/Admin/UsersMaster/Users';
import PendingList from "./Pages/Admin/UploadQuestion/PendingList";
import SubChildCategory from "./Pages/Admin/SubChildCategory/SubChildCategory";
import SubChildCategoryList from './Pages/Admin/SubChildCategory/SubChildCategoryList';
import OperatorAuth from './Pages/Admin/OperatorAuth/OperatorAuth';
import Banners from './Pages/Admin/BannerMaster/Banners';
import BannersList from './Pages/Admin/BannerMaster/BannerList';
import { getcCategories } from "./api"
import TestSeriesList from './Components/TextSeriesList';
import UserSidebar from './Pages/Users/Props/UserSideBar';
import UsersHeader from './Components/UsersHeader';
import CategoryContainer from './Pages/Category';
import ExamplePaper from './Pages/PrePaper';
import CreateYear from './Pages/Admin/PaperYear/CreateYear';
import YearList from './Pages/Admin/PaperYear/YearList';
import UploadPaper from './Pages/Admin/UploadPaper/UploadPaper';
  import UploadpaperList from './Pages/Admin/UploadPaper/UploadpaperList';
// import Sidebars from './Components/Sidebar';
import UserDashboard from './Pages/Users/Dashboard';
import AboutUs from './Components/OtherPage/About';
import TermsCondition from './Components/OtherPage/TermsCondition';
import Privacy from './Components/OtherPage/Privacy';
import ContactUs from './Components/OtherPage/ContactUs';
import { getBannerImage } from './Api/BannerImage';
import EditQuestion from './Pages/Admin/UploadQuestion/EditQuestion';
import Profile from './Pages/Admin/Profile';
import Account from './Pages/Admin/Account';
import Operator from './Components/LogInPage/Operator';

const MyContext = createContext();


const App = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categorydata, setCategorydata] = useState([]);
  const [dataBanner, setDataBanner] = useState([]);

  // ***************LogOut**********************
  // const handleLogout = () => {
  //   localStorage.clear();
  //   // localStorage.removeItem('token');
  //   navigate('/Admin');
  // };
  const handleLogout = () => {
    const user = JSON.parse(localStorage.getItem('user')); // Get the stored user data

    // Check the user type and navigate accordingly
    if (user?.type === 'Admin') {
        localStorage.clear(); 
        navigate('/Admin'); 
    } else {
        localStorage.clear(); 
        navigate('/Operator'); 
    }
};

  const UserLogout = () => {
    localStorage.clear();
    // localStorage.removeItem('token');
    navigate('/');
  };

  // const server = 'http://localhost:8000';
  const server = 'https://api.clearexams.co.in';
  const API_BASE_URL = server;
  

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await getcCategories();
      setCategorydata(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch data');
      setLoading(false);
    }
  };
  // ************Get Banner Image*************
  useEffect(() => {
    fetchBanner();
  }, []);

  const fetchBanner = async () => {
    try {
      const response = await getBannerImage();
      setDataBanner(response.data);
      console.log("-------------->vk", response.data);
      
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch data');
      setLoading(false);
    }
  };




  const userData = localStorage.getItem('user');
  const user = JSON.parse(userData);
  const type = user?.type || { name: 'default' };


  return (
    <MyContext.Provider value={{ categorydata, setCategorydata, type, handleLogout, UserLogout,
      API_BASE_URL, dataBanner
     }}>

      <div style={{ width: '100%' }}>
        <ConditionalNavbar />
        <div style={{ display: 'flex' }}>
          <ConditionalUserSidebar />
          {/* <ConditionalSidebar /> */}
          <ConditionalSidebarAdmin />
          <div style={{ position: 'relative', width: '100%' }}>
         

            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/Users" element={<UserSidebar />} /> */}
              <Route path="/AutoSlide" element={<AutoSlide />} />
              <Route path="/Componemt1" element={<Componemt1 />} />
              <Route path="/Footer" element={<Footer />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/PreviousYearPaper" element={<PreviousYearPaper />} />
              <Route path="/BlockProps" element={<BlockProps />} />
              <Route path="/Login" element={<UserLogin />} />
              <Route path="/AboutUs" element={<AboutUs />} />
              <Route path="/TermsCondition" element={<TermsCondition />} />
              <Route path="/Privacy" element={<Privacy />} />
              <Route path="/ContactUs" element={<ContactUs />} />
              <Route path="/Operator" element={<Operator />} />


              <Route path="/User/TestSeries" element={<TestSeries />} />
              <Route path="/Instructions" element={<Instructions />} />
              <Route path="/TestSection" element={<TestSection />} />
              <Route path="/User/test" element={<TestSeriesList />} />
              <Route path="/User/prepaper" element={<CategoryContainer />} />
              <Route path="/User/prepaper/exampaper" element={<ExamplePaper />} />
              <Route path="/User/prepaper/exampaper/:id" element={<ExamplePaper />} />
              <Route path="/User" element={<UserDashboard />} />

              {/* Admin */}
              <Route path="/Admin" element={<LogIn />} />
              <Route path="/SignUp" element={<SignUp />} />
              <Route path="/Admin/ExamineMaster/:id" element={<ExamineMaster />} />
              <Route path="/Admin/ExamineMaster" element={<ExamineMaster />} />
              <Route path="/Admin/ExaminerList" element={<ExaminerList />} />
              <Route path="/Admin/CategoryMaster/:id" element={<CategoryMaster />} />
              <Route path="/Admin/CategoryMaster" element={<CategoryMaster />} />
              <Route path="/Admin/Dashboard" element={<Dashboard />} />
              <Route path="/Admin/QuestionMaster" element={<QuestionMaster />} />
              <Route path="/Admin/ApprovalMaster" element={<ApprovalMaster />} />
              <Route path="/Admin/Question2" element={<Question2 />} />
              <Route path="/Admin/*" element={<NotFound />} />
              <Route path="/Admin/CategoryList" element={<CategoryList />} />
              <Route path="/Admin/SubCategoryMaster/:id" element={<SubCategoryMaster />} />
              <Route path="/Admin/SubCategoryMaster" element={<SubCategoryMaster />} />
              <Route path="/Admin/SubCategoryList" element={<SubCategoryList />} />
              <Route path="/Admin/ChildCategory/:id" element={<ChildCategory />} />
              <Route path="/Admin/ChildCategory" element={<ChildCategory />} />
              <Route path="/Admin/ChildCategoryList" element={<ChildCategoryList />} />
              <Route path="/Admin/SubjectMaster/:id" element={<SubjectMaster />} />
              <Route path="/Admin/SubjectMaster" element={<SubjectMaster />} />
              <Route path="/Admin/SubjectMasterList" element={<SubjectMasterList />} />
              <Route path="/Admin/QuestionMaster2" element={<QuestionMaster2 />} />
              <Route path="/Admin/QuestionMaster2/:id" element={<QuestionMaster2 />} />
              <Route path="/Admin/QuestionList" element={<QuestionList />} />
              <Route path="/Admin/FinalQuestionList" element={<FinalQuestionList />} />
              <Route path="/Admin/UsersList" element={<UsersList />} />
              <Route path="/Admin/Users/:id" element={<Users />} />
              <Route path="/Admin/Users" element={<Users />} />
              <Route path="/Admin/PendingList" element={<PendingList />} />
              <Route path="/Admin/SubChildCategory" element={<SubChildCategory />} />
              <Route path="/Admin/SubChildCategory/:id" element={<SubChildCategory />} />
              <Route path="/Admin/SubChildCategoryList" element={<SubChildCategoryList />} />
              <Route path="/Admin/OperatorAuth" element={<OperatorAuth />} />
              <Route path="/Admin/Banner" element={<Banners />} />
              <Route path="/Admin/Banner/:id" element={<Banners />} />
              <Route path="/Admin/BannerList" element={<BannersList />} />
              <Route path="/Admin/UploadPaper" element={<UploadPaper />} />
              <Route path="/Admin/UploadPaper/:id" element={<UploadPaper />} />
              <Route path="/Admin/CreateYear" element={<CreateYear />} />
              <Route path="/Admin/CreateYear/:id" element={<CreateYear />} />
              <Route path="/Admin/YearList" element={<YearList />} />
              <Route path="/Admin/UploadpaperList" element={<UploadpaperList />} />
              <Route path="/Admin/EditQuestion/:id" element={<EditQuestion />} />
              <Route path="/Admin/EditQuestion" element={<EditQuestion />} />
              <Route path="/Admin/Profile" element={<Profile />} />
              <Route path="/Admin/Account" element={<Account />} />

            </Routes>
          </div>
        </div>
        <ConditionalFooter />
      </div>
    </MyContext.Provider>
  );
};

// const ConditionalNavbar = () => {
//   const location = useLocation();
//   const shouldHideNavbar = location.pathname.startsWith( '/TestSection');
//   const shouldHideNavbar2 = location.pathname.startsWith('/login');
//   const shouldHideNavbar3 = location.pathname.startsWith('/User');
//   return shouldHideNavbar || !shouldHideNavbar2 || !shouldHideNavbar3 && <NavBar />;
// };


// const ConditionalSidebarAdmin = () => {
//   const location = useLocation();
//   const shouldShowAdminSidebar = location.pathname.startsWith('/Admin');
//   const shouldHideNavbar3 = location.pathname.startsWith('/');
//   return shouldShowAdminSidebar || !shouldHideNavbar3 ? <SidebarAdmin /> : null;
// };

// const ConditionalUserSidebar = () => {
//   const location = useLocation();
//   const shouldShowAdminSidebar1 = location.pathname.startsWith('/User') ;
//   const shouldShowAdminSidebar = location.pathname.startsWith('/', '/Instructions');
//   const Instructions22 = location.pathname.startsWith('/Instructions');
//   return shouldShowAdminSidebar1 || !shouldShowAdminSidebar  ? <><UserSidebar /> </> : null;
// };

// const ConditionalFooter = () => {
//   const location = useLocation();
//   const shouldHideFooter = location.pathname.startsWith('/Admin');
//   const shouldHideFooter2 = location.pathname.startsWith('/login');
//   const shouldHideFooter3 = location.pathname.startsWith('/User');
//   return !shouldHideFooter || !shouldHideFooter2 || !shouldHideFooter3 && <Footer />;
// };

const ConditionalNavbar = () => {
  const location = useLocation();
  const shouldHideNavbar = location.pathname.startsWith( '/TestSection');
  const shouldShowAdminNavbar = location.pathname.startsWith('/Admin');
  const shouldHideNavbar3 = location.pathname.startsWith('/User');
  const shouldHideNavbar4 = location.pathname.startsWith('/Login');
  const shouldHideNavbar5 = location.pathname.startsWith('/Instructions');
  const shouldHideNavbar6 = location.pathname.startsWith('/Operator');
  return !(shouldHideNavbar ||shouldShowAdminNavbar || shouldHideNavbar4 || shouldHideNavbar3 ||
    shouldHideNavbar5 || shouldHideNavbar6
   ) && <NavBar />;
};

// const ConditionalSidebar = () => {
// const location = useLocation();
// const shouldShowSidebar = !location.pathname.startsWith('/Admin');

// return shouldShowSidebar ? <Sidebars /> : null;
// };

const ConditionalSidebarAdmin = () => {
  const location = useLocation();
  const shouldShowAdminSidebar = location.pathname.startsWith('/Admin');
  const shouldHideNavbar3 = location.pathname.startsWith('/');
  return shouldShowAdminSidebar || !shouldHideNavbar3 ? <SidebarAdmin /> : null;
};

const ConditionalUserSidebar = () => {
  const location = useLocation();
  
  const shouldHideUserSidebar =
    location.pathname.startsWith('/User') ||
    location.pathname.startsWith('/user') ||
    location.pathname === '/' ||
    // location.pathname.startsWith('/Instructions') ||
    location.pathname.startsWith('/User/Profile');
    // location.pathname.startsWith('/User/TestSection');

  return shouldHideUserSidebar && <UserSidebar />;
};


const ConditionalFooter = () => {
  const location = useLocation();
  const shouldHideFooter = location.pathname.startsWith('/Admin');
  const shouldHideFooter3 = location.pathname.startsWith('/User');
  const shouldHideFooter2 = location.pathname.startsWith('/Login');
  const shouldHideFooter4 = location.pathname.startsWith('/Instructions');
  const shouldHideFooter5 = location.pathname.startsWith('/TestSection');
  const shouldHideFooter6 = location.pathname.startsWith('/Operator');
  return !(shouldHideFooter || shouldHideFooter3 || shouldHideFooter2 ||
    shouldHideFooter4 || shouldHideFooter5 || shouldHideFooter6
   ) && <Footer />;
};

export default App;

export { MyContext };

import api, {getHeaders} from './Server'


export const getQuestion = async () => {
    try {
        const response = await api.get('/exam-questions',{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching Exam Questions:', error);
        throw error;
    }
};

export const createQuestion = async (data) => {
    try {
        const response = await api.post('/exam-questions', data,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error creating Exam Questions:', error);
        throw error;
    }
};


export const ApproveQuestion = async (subjects) => {
    try {
        const formData = new FormData();
        formData.append('status', subjects.status);
        const response = await api.post(`/approve-record/${subjects.id}`, formData, {
            headers: getHeaders(),
        });
        return response.data;
    } catch (error) {
        console.error('Error creating subjects:', error);
        throw error;
    }
};

export const getByIdQuestion = async (id) => {
    try {
        const response = await api.get(`/exam-questions/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching Exam Questions ${id}:`, error);
        throw error;
    }
};

export const updateQuestion = async (id, data) => {
    try {
        const response = await api.put(`/exam-questions/${id}`, data,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error updating Exam Questions ${id}:`, error);
        throw error;
    }
};

export const deleteQuestion = async (id) => {
    try {
        const response = await api.delete(`/exam-questions/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error deleting Exam Questions ${id}:`, error);
        throw error;
    }
};


export default api;

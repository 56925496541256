import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { createAdmin, updateAdmin, getByIdAdmin } from "./../../../Api/Admin";
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";
import InsertSuccessAlert from "../../../Components/Alert/Create";
import UpdateAlert from "../../../Components/Alert/Update";
import { Button } from "@mui/material";

const Dashboard = () => {
    const { type, handleLogout } = useContext(MyContext)
    const [updatedData, setUpdatedData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [errors, setErrors] = useState({});
    const { id } = useParams();
    const nextPage = "/Admin/UserList";
    const message = "User ";
    const navigate = useNavigate();
    const [inputValues, setInputValues] = useState({
        name: "",
        password: "",
        email: "",
        mobile: "",
        mobileSecond: "",
        address: "",
        type: "",
    });

    useEffect(() => {
        const fetchCategoryById = async () => {
            // setloading(true);
            var data = await getByIdAdmin(id);
            if (data) {
                console.log(data);
                setUpdatedData(data.data)
                // setloading(false);
                setInputValues(data.data)
            }
        }
        fetchCategoryById()
    }, [id])

    const handleDataStore = (e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateInputs = () => {
        const newErrors = {};

        if (!inputValues.name) {
            newErrors.name = 'Name is required';
        }
        if (!inputValues.email) {
            newErrors.email = 'Email is required';
        }
        if (!inputValues.mobile) {
            newErrors.mobile = 'Mobile number is required';
        }
        if (!inputValues.type) {
            newErrors.type = 'User type is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const SaveBtn = async (e) => {
        e.preventDefault();
        debugger
        if (Object.keys(updatedData).length !== 0) {
            // If there's existing data, update the user
            try {
                const result = await updateAdmin(inputValues.id, inputValues); // Pass the ID and updated values
                console.log('Updating data:', result);
                setUpdateSuccess(true);
                navigate('/Admin/usersList'); // Navigate after successful update
            } catch (error) {
                console.error('Error updating user:', error);
                setError('Internal Server Error: Please try again later');
            }
        } else {

            if (!validateInputs()) {
                return; // Stop execution if validation fails
            }

            const formData = new FormData();
            formData.append('name', inputValues.name);
            formData.append('password', inputValues.password);
            formData.append('email', inputValues.email);
            formData.append('mobile', inputValues.mobile);
            formData.append('mobileSecond', inputValues.mobileSecond);
            formData.append('address', inputValues.address);
            formData.append('type', inputValues.type);

            try {
                const isSuccess = await createAdmin(formData);
                console.log('Submitting data:', isSuccess);
                setLoading(true);

                if (isSuccess) {
                    setInputValues({
                        name: "",
                        password: "",
                        email: "",
                        mobile: "",
                        mobileSecond: "",
                        address: "",
                        type: "",
                    });
                    setErrors({});
                    setLoading(false); // Stop the loading animation
                    navigate('/Admin/usersList'); 
                } else {
                    setError('Failed to add user');
                    setSuccess(null);
                }
            } catch (error) {
                console.error('Error adding user:', error);
                setError('Internal Server Error: Please try again later');
                setSuccess(null);
            }
        }
    };

    return (
        <>
            <div className="main-container-examine-btn-section">
                <Header type={type}  handleLogout={handleLogout} />
            </div>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className="main-header-examine-m">
                        Users Master
                    </div>
                    
                    <div className="input-text-box-main">
                    <div className="input-box-examine">
                            <select
                                name="type"
                                value={inputValues.type}
                                onChange={handleDataStore}
                                className="input-examine-master"
                                required
                            >
                                <option value="" disabled>Select User</option>
                                <option value="operator">Operator</option>
                                <option value="approval">Approval</option>
                            </select>
                            <label className="input-label-master-examine-fixed">User Type</label>
                            {errors.type && <span className="error-text">{errors.type}</span>}
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="name"
                                value={inputValues.name}
                                onChange={handleDataStore}
                                className="input-examine-master"
                                required
                            />
                            <label className="input-label-master-examine">Name</label>
                            {errors.name && <span className="error-text">{errors.name}</span>}
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="password"
                                value={inputValues.password}
                                onChange={handleDataStore}
                                className="input-examine-master"
                                required
                            />
                            <label className="input-label-master-examine">Password</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="email"
                                value={inputValues.email}
                                onChange={handleDataStore}
                                className="input-examine-master"
                                required
                            />
                            <label className="input-label-master-examine">Email</label>
                            {errors.email && <span className="error-text">{errors.email}</span>}
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="mobile"
                                value={inputValues.mobile}
                                onChange={handleDataStore}
                                className="input-examine-master"
                                required
                            />
                            <label className="input-label-master-examine">Mob No</label>
                            {errors.mobile && <span className="error-text">{errors.mobile}</span>}
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="mobileSecond"
                                value={inputValues.mobileSecond}
                                onChange={handleDataStore}
                                className="input-examine-master"
                                required
                            />
                            <label className="input-label-master-examine">WhatsApp No</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="address"
                                value={inputValues.address}
                                onChange={handleDataStore}
                                className="input-examine-master"
                                required
                            />
                            <label className="input-label-master-examine">Address</label>
                        </div>
                    </div>
                    <div style={{ paddingRight: "10px", display: "flex", justifyContent: "center" }}>
                        <Button onClick={SaveBtn} variant="contained">Submit</Button>
                    </div>
                </div>
                {loading && (<InsertSuccessAlert />)}
                {error && <div className="error-text">{error}</div>}
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {success && <p style={{ color: 'green' }}>{success}</p>}
                {loading && (< InsertSuccessAlert />)}
                {updateSuccess && <UpdateAlert message={message} nextPage={nextPage} />}
            </div>
        </>
    );
};

export default Dashboard;

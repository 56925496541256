import React, { useState } from 'react';
import { FaUserCircle, FaCog, FaSignOutAlt, FaChevronDown } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const UsersHeader = ({UserLogout}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    console.log('Dropdown toggle triggered'); // Add this line
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <nav className="after-login-navbar">
         {console.log(dropdownOpen)}
      <div className="navbar-container">
        <div className="navbar-logo">
          <NavLink to="/"></NavLink> {/* Add your logo here */}
        </div>
        <div className="navbar-profile" onClick={toggleDropdown}>
          <FaUserCircle className="profile-icon" />
          <FaChevronDown className="dropdown-icon" />
          {dropdownOpen && (
            <div className="dropdown-menu">
              <NavLink to="/settings" className="dropdown-item">
                <FaCog className="dropdown-icon" /> Settings
              </NavLink>
              <div className="dropdown-item" onClick={UserLogout}>
                <FaSignOutAlt className="dropdown-icon" /> Logout
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default UsersHeader;

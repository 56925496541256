import React, { useContext } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import img1 from "./../Assets/img/image1.jpg"
import { MyContext } from '../AppRoutes';

function CarouselBox() {
  const { dataBanner } = useContext(MyContext)

    const spanStyle = {
        padding: '20px',
        background: '#efefef',
        color: '#000000'
      }
      
      const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '400px'
      }
      const slideImages = [
        {
          url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
          caption: 'Slide 1'
        },
        {
          url: 'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80',
          caption: 'Slide 2'
        },
        {
          url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
          caption: 'Slide 3'
        },
      ];
      
    // const ApiBaseUrl = "http://localhost:8000";
    const ApiBaseUrl = "https://api.clearexams.co.in";

    return (
        <div className="slide-container">
        <Slide>
        {dataBanner && dataBanner
              .filter((index) => index.type === "0")
              .sort((a, b) => a.priority - b.priority)
              .map((index) => (
            <div key={index}>
                <div>

                <img className='image-carosul-x1'
                //  src={index.uploadImg}
                src={`${ApiBaseUrl}/storage/${index.uploadImg}` || 'default-image.jpg'}
                //  style={{ ...divStyle, }} 
                 alt="" />
                </div>
              
            </div>
          ))} 
        </Slide>
      </div> 
        );
    }

    export default CarouselBox;
import api, {getHeaders} from "./Server"

export const getPreviousYearPaper = async () => {
    try {
        const response = await api.get('/previousyearpapers',{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching previousyearpapers:', error);
        throw error;
    }
};

export const getByChildCategoryId = async (child_category_id) => {
    try {
        const response = await api.get(`/getByChildCategoryId/${child_category_id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching previousyearpapers:', error);
        throw error;
    }
};

export const createPreviousYearPaper = async (data) => {
    try {
        const response = await api.post('/previousyearpapers', data, {
           headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error creating PreviousYearPaper', error);
        throw error;
    }
};

export const getByIdPreviousYearPaper = async (id) => {
    try {
        const response = await api.get(`/previousyearpapers/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching Previous Year Paper ${id}:`, error);
        throw error;
    }
};

export const updatePreviousYearPaper = async (id, data) => {
    try {
        const response = await api.put(`/previousyearpapers/${id}`, data,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error updating Previous Year Paper ${id}:`, error);
        throw error;
    }
};

export const deletePreviousYearPaper = async (id) => {
    try {
        const response = await api.delete(`/previousyearpapers/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error deleting Examiner ${id}:`, error);
        throw error;
    }
};

export default api;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

/**
 * UpdateAlert component.
 *
 * Displays a success alert when an item is updated successfully,
 * then redirects to the next page after 5 seconds.
 *
 * @param {Object} props - The component props.
 * @param {string} props.message - The message to be displayed in the alert.
 * @param {string} props.nextPage - The path to navigate to after displaying the alert.
 * @returns {null} The component does not render anything.
 */
const UpdateAlert = ({ message, nextPage }) => {
  const [counter, setCounter] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    // Display the success alert when the component is mounted
    const alert = swal({
      title: "Updated!",
      text: `${message} has been updated successfully!`,
      icon: "success",
      buttons: false,
      timer: 1000, // Alert will close automatically after 5 seconds
    });

    const intervalId = setInterval(() => {
      setCounter(prevCounter => prevCounter - 1);
    }, 1000);

    const timeoutId = setTimeout(() => {
      navigate(nextPage);
    }, 1000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [message, nextPage, navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <p>Redirecting in {counter} seconds...</p>
    </div>
  );
};

export default UpdateAlert;


import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./../../../Assets/img/logo.jpeg"
import {
    RiHome4Line,
    RiTeamLine,
    RiCalendar2Line,
    RiFolder2Line,
    RiUserFollowLine,
    RiPlantLine,
    RiStackLine,
    RiUserUnfollowLine
} from "react-icons/ri";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi/";
import {
    Sidebar,
    SubMenu,
    Menu,
    MenuItem
} from "react-pro-sidebar";
function SidebarAdmin() {
    //const { collapseSidebar } = useProSidebar();
    const [collapsed, setCollapsed] = useState(false);

    const [toggled, setToggled] = useState(false);

    const handleCollapsedChange = () => {
        setCollapsed(!collapsed);
    };
    const handleToggleSidebar = (value) => {
        setToggled(value);
    };
    const userData = localStorage.getItem('user');
    const user = JSON.parse(userData);

    const type = user?.type || 'default';


    return (
        <div>
            <Sidebar
                className={`app ${toggled ? "toggled" : ""}`}
                style={{ height: "100vh", width: collapsed ? "50px" : "300px", transition: "width 0.3s" }}
                collapsed={collapsed}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}
            >
                <main>
                    <Menu>
                        {collapsed ? (
                            <MenuItem
                                icon={<FiChevronsRight />}
                                onClick={handleCollapsedChange}
                            ></MenuItem>
                        ) : (
                            <MenuItem
                                className="main-menu-sidebar"
                                suffix={<FiChevronsLeft />}
                                onClick={handleCollapsedChange}
                            >
                                <div
                                    style={{
                                        padding: "9px",
                                        fontWeight: "bold",
                                        fontSize: 14,
                                        letterSpacing: "1px"
                                    }}
                                >
                                    <img src={logo} className="logo-admin-main" alt="" />
                                </div>
                            </MenuItem>
                        )}
                        <hr />
                    </Menu>

                    <Menu>
                        <Link to="/Admin/Dashboard" style={{ textDecoration: "none" }}>
                            <MenuItem icon={<RiHome4Line />} style={{ color: "#4e4e4e" }}>
                                <div to="/Admin/Dashboard" className="text-deco-xl" style={{ color: "#4e4e4e" }}>
                                    Dashboard
                                </div>
                            </MenuItem>
                        </Link>
                        {type === 'Admin' && (
                            <SubMenu defaultClose label={"Examiner Master"} icon={<RiTeamLine />}>
                                <Link to="Admin/ExamineMaster" style={{ textDecoration: "none" }}>
                                    <MenuItem style={{ textDecoration: "none" }} icon={<RiUserFollowLine />}>
                                        <span className="text-deco-xl" >Create Exam  </span></MenuItem>
                                </Link>
                                <Link to="Admin/ExaminerList" style={{ textDecoration: "none" }}>
                                    <MenuItem icon={<RiUserUnfollowLine />}>
                                        <span className="text-deco-xl" >Exam List </span></MenuItem>
                                </Link>

                            </SubMenu>
                        )}
                        {type === 'Admin' && (
                            <SubMenu defaultClose label={"Subject Master"} icon={<RiTeamLine />}>

                                <Link to="Admin/SubjectMaster" style={{ textDecoration: "none" }}>
                                    <MenuItem style={{ textDecoration: "none" }} icon={<RiUserFollowLine />}>
                                        <span className="text-deco-xl" >Create Subject  </span></MenuItem>
                                </Link>
                                <Link to="Admin/SubjectMasterList" style={{ textDecoration: "none" }}>
                                    <MenuItem style={{ textDecoration: "none" }} icon={<RiUserFollowLine />}>
                                        <span className="text-deco-xl" >Subject List  </span></MenuItem>
                                </Link>
                            </SubMenu>
                        )}
                        {type === 'Admin' && (
                            <SubMenu defaultClose label={"User Master"} icon={<RiTeamLine />}>
                                <Link to="/Admin/Users" style={{ textDecoration: "none" }}>
                                    <MenuItem style={{ textDecoration: "none" }} icon={<RiUserFollowLine />}>
                                        <span className="text-deco-xl" >User  </span></MenuItem>
                                </Link>
                                <Link to="/Admin/UsersList" style={{ textDecoration: "none" }}>
                                    <MenuItem style={{ textDecoration: "none" }} icon={<RiUserFollowLine />}>
                                        <span className="text-deco-xl" >User List </span></MenuItem>
                                </Link>
                            </SubMenu>
                        )}
                        {type === 'Admin' && (
                            <SubMenu defaultClose label={"All Exam Question"} icon={<RiTeamLine />}>
                                <Link to="/Admin/FinalQuestionList" style={{ textDecoration: "none" }}>
                                    <MenuItem style={{ textDecoration: "none" }} icon={<RiUserFollowLine />}>
                                        <span className="text-deco-xl" >All Question List  </span></MenuItem>
                                </Link>
                                <Link to="/Admin/FinalQuestionList" style={{ textDecoration: "none" }}>
                                    <MenuItem style={{ textDecoration: "none" }} icon={<RiUserFollowLine />}>
                                        <span className="text-deco-xl" >YearWise Papers List  </span></MenuItem>
                                </Link>
                            </SubMenu>
                        )}
                        {type === 'Admin' && (
                            <SubMenu defaultClose label={"Main Master"} icon={<RiFolder2Line />}>
                                <Link to="Admin/CategoryMaster">
                                    <MenuItem icon={<RiStackLine />}>
                                        <span className="text-deco-xl" >Category </span></MenuItem>
                                </Link>
                                <Link to="Admin/CategoryList">
                                    <MenuItem icon={<RiPlantLine />}>
                                        <span className="text-deco-xl" > Category List</span></MenuItem>
                                </Link>
                                <Link to="Admin/SubCategoryMaster">
                                    <MenuItem icon={<RiPlantLine />}>
                                        <span className="text-deco-xl" >Sub Category</span></MenuItem>
                                </Link>
                                <Link to="Admin/SubCategoryList">
                                    <MenuItem icon={<RiPlantLine />}>
                                        <span className="text-deco-xl" >Sub Category List</span></MenuItem>
                                </Link>
                                <Link to="Admin/ChildCategory">
                                    <MenuItem icon={<RiPlantLine />}>
                                        <span className="text-deco-xl" >Child Category</span></MenuItem>
                                </Link>
                                <Link to="Admin/ChildCategoryList">
                                    <MenuItem icon={<RiPlantLine />}>
                                        <span className="text-deco-xl" >Child Category List</span></MenuItem>
                                </Link>
                                <Link to="Admin/SubChildCategory">
                                    <MenuItem icon={<RiPlantLine />}>
                                        <span className="text-deco-xl" >Sub Child Category</span></MenuItem>
                                </Link>
                                <Link to="Admin/SubChildCategoryList">
                                    <MenuItem icon={<RiPlantLine />}>
                                        <span className="text-deco-xl" >Sub Child Category List</span></MenuItem>
                                </Link>
                                <Link to="Admin/Banner">
                                    <MenuItem icon={<RiStackLine />}>
                                        <span className="text-deco-xl" >Banners </span></MenuItem>
                                </Link>
                                <Link to="Admin/BannerList">
                                    <MenuItem icon={<RiStackLine />}>
                                        <span className="text-deco-xl" >Banner List </span></MenuItem>
                                </Link>
                                <Link to="Admin/BannerList">
                                    <MenuItem icon={<RiStackLine />}>
                                        <span className="text-deco-xl" >Upcoming Exam </span></MenuItem>
                                </Link>
                            </SubMenu>
                        )}
                        <SubMenu defaultClose label={"Question Master"} icon={<RiFolder2Line />}>
                            <Link to="Admin/QuestionMaster2">
                                <MenuItem icon={<RiStackLine />}>
                                    <span className="text-deco-xl" >Question Master</span></MenuItem>
                            </Link>
                            <Link to="Admin/PendingList">
                                <MenuItem icon={<RiPlantLine />}>
                                    <span className="text-deco-xl" > Pending List</span></MenuItem>
                            </Link>
                            <Link to="Admin/QuestionList">
                                <MenuItem icon={<RiPlantLine />}>
                                    <span className="text-deco-xl" > Question List</span></MenuItem>
                            </Link>
                            {/* <Link to="Admin/QuestionMaster2">
                                <MenuItem icon={<RiPlantLine />}>
                                    <span className="text-deco-xl" > Question </span></MenuItem>
                            </Link> */}
                        </SubMenu>
                        {type === 'approval' && (
                            <SubMenu defaultClose label={"Approval Master"} icon={<RiFolder2Line />}>
                                <Link to="Admin/ApprovalMaster">
                                    <MenuItem icon={<RiStackLine />}>
                                        <span className="text-deco-xl" >Approva Master</span></MenuItem>
                                </Link>
                                <Link to="Admin/ApprovalMaster">
                                    <MenuItem icon={<RiPlantLine />}>
                                        <span className="text-deco-xl" > Approval Master List</span></MenuItem>
                                </Link>
                            </SubMenu>
                        )}
                        {type === 'Admin' && (
                        <SubMenu defaultClose label={"Upload Paper "} icon={<RiFolder2Line />}>
                            <Link to="Admin/UploadPaper">
                                <MenuItem icon={<RiStackLine />}>
                                    <span className="text-deco-xl" >Upload Paper</span></MenuItem>
                            </Link>
                            <Link to="Admin/UploadpaperList">
                                <MenuItem icon={<RiPlantLine />}>
                                    <span className="text-deco-xl" > Paper List</span></MenuItem>
                            </Link>
                            <Link to="Admin/CreateYear">
                                <MenuItem icon={<RiPlantLine />}>
                                    <span className="text-deco-xl" > Create Paper Year</span></MenuItem>
                            </Link>
                        </SubMenu>
                        )}

                        {type === 'Admin' && (
                            <SubMenu defaultClose label={"Operator Authentication"} icon={<RiFolder2Line />}>
                                <Link to="Admin/OperatorAuth">
                                    <MenuItem icon={<RiStackLine />}>
                                        <span className="text-deco-xl" >Operator Auth</span></MenuItem>
                                </Link>
                            </SubMenu>
                        )}
                        {type === 'Admin' && (
                            <SubMenu defaultClose label={"Banner Master"} icon={<RiFolder2Line />}>
                                <Link to="Admin/Banner">
                                    <MenuItem icon={<RiStackLine />}>
                                        <span className="text-deco-xl" >Banners </span></MenuItem>
                                </Link>
                                <Link to="Admin/BannerList">
                                    <MenuItem icon={<RiStackLine />}>
                                        <span className="text-deco-xl" >Banner List </span></MenuItem>
                                </Link>
                            </SubMenu>
                        )}
                        <SubMenu defaultClose label={"Assessment Master"} icon={<RiFolder2Line />}>
                            <Link to="Admin/Banner">
                                <MenuItem icon={<RiStackLine />}>
                                    <span className="text-deco-xl" >Assessment </span></MenuItem>
                            </Link>
                            <Link to="Admin/Banner">
                                <MenuItem icon={<RiStackLine />}>
                                    <span className="text-deco-xl" >Assessment List </span></MenuItem>
                            </Link>
                        </SubMenu>

                    </Menu>
                </main>
            </Sidebar>
        </div>
    );
}
export default SidebarAdmin;
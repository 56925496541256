import React, { useState } from 'react';
import { FaBars, FaRegChartBar, FaHome, FaQuestionCircle } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { GiSkills } from 'react-icons/gi';
import { FaNoteSticky } from 'react-icons/fa6';
import { HiOutlineDesktopComputer } from 'react-icons/hi';
import { TiDocumentText } from 'react-icons/ti';
import { RiPassValidLine, RiPassPendingLine } from 'react-icons/ri';
import { BsTicketPerforatedFill } from 'react-icons/bs';
import { PiExamFill } from 'react-icons/pi';
import { TbInfoTriangleFilled } from 'react-icons/tb';
import { FaFileCircleQuestion } from 'react-icons/fa6';
import { GiHeadbandKnot } from 'react-icons/gi';
import { IoBookmark } from 'react-icons/io5';
import { BsFillBookmarkCheckFill } from 'react-icons/bs';
import { GiTeacher } from 'react-icons/gi';
import { VscReferences } from 'react-icons/vsc';
import { FaChalkboardUser } from 'react-icons/fa6';
import { FaRedo } from 'react-icons/fa';

const UserSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const menuItem = [
    {
      path: '/User',
      name: 'Home',
      icon: <FaHome />,
    },
    {
      title: 'TESTS',
    },
    {
      path: '/User/test',
      name: 'Test Series',
      icon: <FaNoteSticky />,
    },
    // {
    //   path: '/User/live-quiz',
    //   name: 'Live Test & Quizzes',
    //   icon: <HiOutlineDesktopComputer />,
    // },
    {
      path: '/User/prepaper',
      name: 'Previous Year Papers',
      icon: <TiDocumentText />,
    },
    // {
    //   path: '/User/practice',
    //   name: 'Practice',
    //   icon: <FaRedo />, // Ensure this icon is available
    // },
    // {
    //   path: '/User/free-quiz',
    //   name: 'Free Quizzes',
    //   icon: <FaQuestionCircle />,
    // },
    {
      path: '/User/attemp-test',
      name: 'Attempted Tests',
      icon: <BsTicketPerforatedFill />,
    },
    {
      path: '/User/pass',
      name: 'Pass',
      icon: <RiPassValidLine />,
    },
    {
      path: '/User/passpro',
      name: 'Pass Pro',
      icon: <RiPassPendingLine />,
    },
    {
      path: '/User/exam',
      name: 'Exams',
      icon: <PiExamFill />,
    },
    {
      path: '/User/saved-question',
      name: 'Saved Questions',
      icon: <IoBookmark />,
    },
    {
      path: '/User/reported-question',
      name: 'Reported Questions',
      icon: <TbInfoTriangleFilled />,
    },
    {
      path: '/User/doubts',
      name: 'Doubts',
      icon: <FaFileCircleQuestion />,
    },
    {
      path: '/User/gk-current',
      name: 'GK & Current Affairs',
      icon: <GiHeadbandKnot />,
    },
    {
      path: '/User/activate-product',
      name: 'Activate Products',
      icon: <BsFillBookmarkCheckFill />,
    },
  ];

  return (
    <>
  

      <div style={{ width: isOpen ? '310px' : '50px'}} className="sidebar">
        <div className="top-section">
          <h1 style={{ display: isOpen ? 'block' : 'none' }} className="logo">
            BluechipExams
          </h1>
          <div style={{ marginLeft: isOpen ? '50px' : '0px' }} className="bars">
            <FaBars onClick={toggle} />
          </div>
        </div>
        <div className="menu-items">
          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className="link"
              activeClassName="active"
              style={{padding: isOpen?   '10px 15px': '8px 8px'}}
            >
              <div className="icon">{item.icon}</div>
              <div
                style={{ display: isOpen ? 'block' : 'none' }}
                className="link-text"
              >
                {item.name}
              </div>
              {item.title && (
                <div className="link-title" style={{ display: isOpen ? 'block' : 'none' }}>
                  {item.title}
                </div>
              )}
            </NavLink>
          ))}
        </div>
      </div>
     
    </>
  );
};

export default UserSidebar;

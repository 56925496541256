import React, { useState, useEffect } from "react";
import $ from 'jquery'; // Ensure jQuery is installed via npm/yarn
import Img3 from "./../Assets/logos/3m.svg"

const categories = ["LKG 1", "LKG 2", "LKG 3", "10th", "12th", "MATEMATICS", "Category 1",
  "Category 2",
  "Category 3",
  "Category 4",
  "Category 5",
  "Category 6",
  "Category 7",
  "Category 8",
  "Category 9",
  "Category 10"];

const AutoSlide = () => {

  const elements = Array.from({ length: 10 });

  return (
    <>
      <div className="logos-master-container">
      {categories.map((category, index) => (
        <div className="logos-slide" key={index}>
          <div className="cards-main-container">
            <div className="card blue">
              {/* <img src={Img3} alt={`Logo ${index + 1}`} /> */}
              <p className="second-text">{category}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
    </>
  );
};

export default AutoSlide;

import "./../../Assets/css/UserLogin.css";
import LoginPNG from "./../../Assets/img/loginpage.svg";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { createAdmin, updateAdmin, getByIdAdmin } from "./../../Api/Admin";
import { MyContext } from "../../AppRoutes";
import api from "./../../Api/Server";

function UserLogin() {
    const { type, handleLogout } = useContext(MyContext);
    const [updatedData, setUpdatedData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [errors, setErrors] = useState({});
    const [login, setLogin] = useState([]);
    const [passwordVisible, setPasswordVisible] = useState(false); // Password visibility toggle
    const { id } = useParams();
    const navigate = useNavigate();
    const [inputValues, setInputValues] = useState({
        name: "",
        password: "",
        email: "",
        mobile: "",
        type: "user",
    });
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/User/');
        }
    },[])

    // Handle form input changes
    const handleDataStore = (e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Validate form inputs
    const validateInputs = () => {
        const newErrors = {};

        if (!inputValues.name) {
            newErrors.name = 'Name is required';
        }
        if (!inputValues.email) {
            newErrors.email = 'Email is required';
        }
        if (!inputValues.mobile) {
            newErrors.mobile = 'Mobile number is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    // Navigate back
    const handleBack = () => {
        navigate(-1);
    };

    // Handle form submission
    const SaveBtn = async (e) => {
        e.preventDefault();

        if (Object.keys(updatedData).length !== 0) {
            // Update existing user
            try {
                const result = await updateAdmin(inputValues.id, inputValues); // Pass the ID and updated values
                console.log('Updating data:', result);
                setUpdateSuccess(true);
                navigate('/User'); // Navigate after successful update
            } catch (error) {
                console.error('Error updating user:', error);
                setError('Internal Server Error: Please try again later');
            }
        } else {
            // Validate inputs before submission
            if (!validateInputs()) {
                return; // Stop execution if validation fails
            }

            const formData = new FormData();
            formData.append('name', inputValues.name);
            formData.append('password', inputValues.password);
            formData.append('email', inputValues.email);
            formData.append('mobile', inputValues.mobile);
            formData.append('type', inputValues.type);

            try {
                const isSuccess = await createAdmin(formData);
                console.log('Submitting data:', isSuccess);
                setLoading(true);

                if (isSuccess) {
                    try {
                        // Automatically log in the user after successful registration
                        const response = await api.post('/login', {
                            email: inputValues.email,
                            password: inputValues.password
                        });
                        console.log('Login response:', response.data);

                        const AdminLoginId = JSON.stringify(response.data.user);
                        setLogin(AdminLoginId);

                        localStorage.setItem('token', response.data.token); // Save token
                        localStorage.setItem('user', AdminLoginId); // Save adminId

                        navigate('/Admin/Dashboard'); // Navigate to dashboard
                    } catch (err) {
                        console.error('Error logging in after signup:', err);
                        setError(err.response?.data?.message || 'An error occurred during login');
                    }

                    setErrors({});
                    setLoading(false); // Stop the loading animation
                } else {
                    setError('Failed to add user');
                    setSuccess(null);
                }
            } catch (error) {
                console.error('Error adding user:', error);
                setError('Internal Server Error: Please try again later');
                setSuccess(null);
            }
        }
    };

    return (
        <div className="LoginupperContainer">
            <div className="loginPageContainer" style={{ position: "relative" }}>
                <span className="crossicon" onClick={handleBack}> {/* Navigate back on click */}
                    <i className="fa-solid fa-xmark"></i>
                </span>
                <div className="imageContainer">
                    <img className="imagePic" src={LoginPNG} alt="Login Illustration" />
                </div>
                <div className="loginContainer">
                    <h2>Sign Up</h2>
                    <form className="form">
                        <div className="formGroup">
                            <label htmlFor="name">Full Name</label>
                            <input
                                type="text"
                                name="name"
                                placeholder="Enter Full Name"
                                value={inputValues.name}
                                onChange={handleDataStore}
                            />
                            {errors.name && <p style={{ color: "red" }} className="error-message">{errors.name}</p>}
                        </div>
                        <div className="formGroup">
                            <label htmlFor="email">Email Address</label>
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                value={inputValues.email}
                                onChange={handleDataStore}
                            />
                            {errors.email && <p style={{ color: "red" }} className="error-message">{errors.email}</p>}
                        </div>
                        <div className="formGroup">
                            <label htmlFor="mobile">Mobile No</label>
                            <input
                                type="text"
                                name="mobile"
                                placeholder="Enter Mobile Number"
                                value={inputValues.mobile}
                                onChange={handleDataStore}
                            />
                            {errors.mobile && <p style={{ color: "red" }} className="error-message">{errors.mobile}</p>}
                        </div>
                        <div className="formGroup">
                            <label htmlFor="password">Password</label>
                            <div className="passwordContainer">
                                <input
                                    type={passwordVisible ? 'text' : 'password'}
                                    name="password"
                                    placeholder="Enter your password"
                                    value={inputValues.password}
                                    onChange={handleDataStore}
                                />
                                <i
                                    className={`fa ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'} passwordIcon`}
                                    onClick={togglePasswordVisibility}
                                ></i>
                            </div>
                        </div>
                        <div className="buttonContainer">
                            <button type="submit" onClick={SaveBtn} className="loginButton">
                                {Object.keys(updatedData).length !== 0 ? 'Update' : 'Sign Up'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default UserLogin;

import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const MyEditor = ({ value, onChange }) => {
  return (
    <div style={{  padding: '10px', borderRadius: '8px' }}>
      <CKEditor
        editor={ClassicEditor}
        data={value} // Pass the controlled value here
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data); // Call the parent's onChange handler
        }}
      />
    </div>
  );
};

export default MyEditor;

import api, {getHeaders} from "./Server"

export const getPaperYear = async () => {
    try {
        const response = await api.get('/paperyears',{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching paperyears:', error);
        throw error;
    }
};

export const createPaperYear = async (data) => {
    try {
        const response = await api.post('/paperyears', data, {
           headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error creating PaperYear', error);
        throw error;
    }
};

export const getByIdPaperYear = async (id) => {
    try {
        const response = await api.get(`/paperyears/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching Previous Year Paper ${id}:`, error);
        throw error;
    }
};

export const updatePaperYear = async (id, data) => {
    try {
        const response = await api.put(`/paperyears/${id}`, data,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error updating Previous Year Paper ${id}:`, error);
        throw error;
    }
};

export const deletePaperYear = async (id) => {
    try {
        const response = await api.delete(`/paperyears/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error deleting Examiner ${id}:`, error);
        throw error;
    }
};

export default api;

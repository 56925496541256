import axios from "axios";
import { getHeaders } from "./Api/header"

// const local = 'http://localhost:8000/api';
const local = 'https://api.clearexams.co.in/api';
const api = axios.create({
    baseURL:  local, 
    // baseURL: server ? server : local, 
});

export const getcCategories = async () => {
    try {
        const response = await api.get('/categories',{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw error;
    }
};

export const createCategories = async (data) => {
    try {
        const response = await api.post('/categories', data,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error creating category:', error);
        throw error;
    }
};

export const getByIdcCategories = async (id) => {
    try {
        const response = await api.get(`/categories/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching category ${id}:`, error);
        throw error;
    }
};

export const updateCategories = async (id, data) => {
    try {
        const response = await api.put(`/categories/${id}`, data,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error updating category ${id}:`, error);
        throw error;
    }
};

export const deleteCategories = async (id) => {
    try {
        const response = await api.delete(`/categories/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error deleting category ${id}:`, error);
        throw error;
    }
};

export default api;

import React, { useState } from 'react';

const ImageUpload = ({ onImageUpload }) => {
    const [selectedImages, setSelectedImages] = useState([]);

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const newImages = files.map(file => ({
            file: file,
            url: URL.createObjectURL(file),
        }));
        const allImages = [...selectedImages, ...newImages];
        setSelectedImages(allImages);
        onImageUpload(allImages.map(image => image.file));
    };

    const handleDeleteImage = (url) => {
        const filteredImages = selectedImages.filter(image => image.url !== url);
        setSelectedImages(filteredImages);
        onImageUpload(filteredImages.map(image => image.file));
    };

    return (
        <div style={styles.container}>
            {selectedImages.map((image, index) => (
                <div key={index} style={styles.imageContainer}>
                    <img 
                        src={image.url} 
                        alt={`Selected ${index + 1}`} 
                        style={styles.image} 
                    />
                    <button 
                        style={styles.deleteButton} 
                        onClick={() => handleDeleteImage(image.url)}
                    >
                        <i className="fa-solid fa-times"></i>
                    </button>
                </div>
            ))}
            <div style={styles.uploader}>
                <input 
                    type="file" 
                    accept="image/*" 
                    name='category_image'
                 
                    multiple
                    onChange={handleImageChange} 
                    style={styles.input} 
                />
                <div style={styles.placeholder}>
                    <i className="fa-solid fa-plus" style={styles.plusIcon}></i>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        alignItems: 'center',
        maxWidth: '100%',
    },
    uploader: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        border: '2px dotted #ccc',
        borderRadius: '8px',
        minWidth: '80px',
        minHeight: '80px',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
    },
    input: {
        opacity: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        cursor: 'pointer',
    },
    placeholder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
    plusIcon: {
        fontSize: '24px',
        color: '#ccc',
    },
    imageContainer: {
        position: 'relative',
        width: '80px',
        height: '80px',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '4px',
    },
    deleteButton: {
        position: 'absolute',
        top: '0',
        right: '0',
        background: 'rgba(255, 255, 255, 0.8)',
        border: 'none',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translate(50%, -50%)',
    },
};

export default ImageUpload;

import api, { getHeaders } from './Server';


export const getSubChildCategory = async () => {
    try {
        const response = await api.get('/subchildcategory',{
            headers: getHeaders()
        });
        console.log(response.data)
        return response.data;
    } catch (error) {
        console.error('Error fetching Sub Child Categories:', error);
        throw error;
    }
};

export const createSubChildCategory = async (data) => {
    try {
        const response = await api.post('/subchildcategory', data,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error creating Sub Child Categories:', error);
        throw error;
    }
};

export const getByIdSubChildCategory = async (id) => {
    try {
        const response = await api.get(`/subchildcategory/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching Sub Child Categories ${id}:`, error);
        throw error;
    }
};

export const updateSubChildCategory = async (id, data) => {
    try {
        const response = await api.put(`/subchildcategory/${id}`, data);
        return response.data;
    } catch (error) {
        console.error(`Error updating Sub Child Categories ${id}:`, error);
        throw error;
    }
};

export const deleteSubChildCategory = async (id) => {
    try {
        const response = await api.delete(`/subchildcategory/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting Sub Child Categories ${id}:`, error);
        throw error;
    }
};

export const getSubChildCategoryIdBaseData = async (subChildcategoryId) => {
    try {
        const response = await api.get(`/subchildcategory/getById/${subChildcategoryId}`);
        if (response.data.data.length > 0) {
            console.log(response.data.data);
            return response.data.data;
            
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching Child Categories:', error);
        throw error;
    }
};


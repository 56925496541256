import React, { useState } from 'react';
import "../Components/NavBar.css";
import print_img12 from "../../src/Assets/img/logo.jpeg"
import { Link, useNavigate } from "react-router-dom";
import logo from "./../Assets/img/logo.jpeg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

function NavBar() {

    const [open1, setOpen1] = useState(false);

    const sidemenu1 = () => {
        setOpen1(!open1)
        setOpen2(false)
        setOpen3(false)
        setOpen4(false)
        setOpen5(false)
        setOpen6(false)


    }

    const [open2, setOpen2] = useState(false);

    const sidemenu2 = () => {
        setOpen1(false)
        setOpen2(!open2)
        setOpen3(false)
        setOpen4(false)
        setOpen5(false)
        setOpen6(false)



    }

    const [open3, setOpen3] = useState(false);

    const sidemenu3 = () => {
        setOpen1(false)
        setOpen2(false)
        setOpen3(!open3)
        setOpen4(false)
        setOpen5(false)
        setOpen6(false)


    }

    const [open4, setOpen4] = useState(false);

    const sidemenu4 = () => {
        setOpen1(false)
        setOpen2(false)
        setOpen3(false)
        setOpen4(!open4)
        setOpen5(false)
        setOpen6(false)


    }


    const [open5, setOpen5] = useState(false);

    const sidemenu5 = () => {
        setOpen1(false)
        setOpen2(false)
        setOpen3(false)
        setOpen4(false)
        setOpen5(!open5)
        setOpen6(false)
    }
    const [open6, setOpen6] = useState(false);

    const sidemenu6 = () => {
        setOpen1(false)
        setOpen2(false)
        setOpen3(false)
        setOpen4(false)
        setOpen5(false)
        setOpen6(!open6)
    }
    const sidebar = () => {
        document.getElementById("sidebar").style.left = "0px"
    }

    const sidebarclose = () => {
        document.getElementById("sidebar").style.left = "-380px"
    }

    const settingbar = () => {
        console.log("settingbar")
        document.getElementById("settingbar").style.right = "0px"
    }

    const settingbarclose = () => {
        console.log("settingbarclose")
        document.getElementById("settingbar").style.right = "-100%"
    }

    const navigate = useNavigate();

    const handleLogin = () => {
        // Perform any login logic here
        navigate('/LogIn');
    };
    const handleSignUp = () => {
        // Perform any login logic here
        navigate('/SignUp');
    };

    return (

        <div>

            <div id="" className="wrapper">

                {/* ************Seeting Bar ************** */}
                <div id="settingbar" className=" settingbar">

                </div>
            </div>

            {/* *****************NavBar *************** */}
            <div className="navbar st-menu">
                <img className='navbar-logo-icon' src={logo} alt="" />
                <div style={{ justifyContent: "end", }} className="logo-box">
                    <i onClick={settingbar} style={{ paddingTop: "1px", color: "rgb(91 91 91)", marginLeft: "10px", marginRight: "10PX", fontSize: "18px" }} className="fa-solid fa-gear home_icon4"></i>
                    <i onClick={handleLogin} style={{ paddingTop: "1px", color: "rgb(91 91 91)", marginLeft: "10px", marginRight: "10PX", fontSize: "18px" }} className="fa-solid fa-user home_icon4"></i>
                </div>
                <div className="logo-box1">
                    <i onClick={sidebar} className="fa-solid fa-bars home_icon3"></i>
                    <p className="home_icon2">BLUECHIP</p>
                </div>
                <div className='navbar-center-main'>

                    <div className="nav-btn">
                        <div className="nav-links">
                            <ul>
                                <li className="nav-link" >
                                    <Link to="/" >Home</Link>
                                </li>
                                <li className="nav-link" >
                                    <a href="#">Abour  <i className="fas fa-caret-down"></i></a>
                                    <div className="dropdown">
                                        <ul>
                                            <li className="dropdown-link">
                                                <Link className='dropdown-link' to="/AboutUs" href="#" style={{ textDecoration: "none", color: "white" }}>
                                                <a href="#">About Us</a>
                                                </Link>
                                            </li>
                                            <li className="dropdown-link">
                                                <Link className='dropdown-link' to="/ContactUs" style={{ textDecoration: "none", color: "white" }}>
                                                <a href="#">Contact Us</a>
                                                </Link>
                                            </li>
                                            <div className="arrow"></div>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-link" >
                                    <a >Category<i className="fas fa-caret-down"></i></a>
                                    <div className="dropdown">
                                        <ul>

                                            <li className="dropdown-link">
                                                <a href='#'>Nursery<i className="fas fa-caret-down"></i></a>
                                                <div className="dropdown second">
                                                    <ul>
                                                        <li className="dropdown-link">
                                                            <a href='#' to="/" >LKG 1</a>
                                                        </li>
                                                        <li className="dropdown-link">
                                                            <a href='#' to="/">LKG 2</a>
                                                        </li>
                                                        <li className="dropdown-link">
                                                            <a href='#'>LKG 3</a>
                                                        </li>
                                                        <li className="dropdown-link">
                                                            <a href='#' >More<i className="fas fa-caret-down"></i></a>
                                                            <div className="dropdown second">
                                                                <ul>
                                                                    <li className="dropdown-link">
                                                                        <a href='#' >Link 1</a>
                                                                    </li>
                                                                    <li className="dropdown-link">
                                                                        <a href='#' >Link 2</a>
                                                                    </li>
                                                                    <li className="dropdown-link">
                                                                        <a href='#' >Link 3</a>
                                                                    </li>
                                                                    <div className="arrow"></div>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <div className="arrow"></div>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="dropdown-link">
                                                <a href='' >School<i className="fas fa-caret-down"></i></a>
                                                <div className="dropdown second">
                                                    <ul>
                                                        <li className="dropdown-link">
                                                            <a href='#' to="/" >10th</a>
                                                        </li>
                                                        <li className="dropdown-link">
                                                            <a href='#' to="/">12th</a>
                                                        </li>
                                                        <div className="arrow"></div>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="dropdown-link">
                                                <a href='' >Graduation<i className="fas fa-caret-down"></i></a>
                                                <div className="dropdown second">
                                                    <ul>
                                                        <li className="dropdown-link">
                                                            <a href='#'>University 1</a>
                                                        </li>
                                                        <li className="dropdown-link">
                                                            <a href='#' to="/">University 2</a>
                                                        </li>
                                                        <li className="dropdown-link">
                                                            <a href='#' to="/">University 3</a>
                                                        </li>

                                                        <div className="arrow"></div>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="dropdown-link">
                                                <a href='' >P G<i className="fas fa-caret-down"></i></a>
                                                <div className="dropdown second">
                                                    <ul>
                                                        <li className="dropdown-link">
                                                            <a href='#'>Link 1</a>
                                                        </li>
                                                        <li className="dropdown-link">
                                                            <a href='#' to="/">Link 2</a>
                                                        </li>
                                                        <li className="dropdown-link">
                                                            <a href='#' to="/">Link 3</a>
                                                        </li>
                                                        <li className="dropdown-link">
                                                            <a href='#' >More<i className="fas fa-caret-down"></i></a>
                                                            <div className="dropdown second">
                                                                <ul>
                                                                    <li className="dropdown-link">
                                                                        <a href='#' className='product-link-xl' to="/">Link 1</a>
                                                                    </li>
                                                                    <li className="dropdown-link">
                                                                        <a href='#' to="/">Link 2</a>
                                                                    </li>
                                                                    <li className="dropdown-link">
                                                                        <a href='#' to="/">Link 3</a>
                                                                    </li>
                                                                    <div className="arrow"></div>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <div className="arrow"></div>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="dropdown-link">
                                                <a href='' >Competitive<i className="fas fa-caret-down"></i></a>
                                                <div className="dropdown second">
                                                    <ul>
                                                        <li className="dropdown-link">
                                                            <a href='#' >( Central + States )<i className="fas fa-caret-down"></i></a>
                                                            <div className="dropdown second">
                                                                <ul>
                                                                    <li className="dropdown-link">
                                                                        <a href="#">CG </a>
                                                                    </li>
                                                                    <li className="dropdown-link">
                                                                        <a href="#">MP</a>
                                                                    </li>
                                                                    <li className="dropdown-link">
                                                                        <a href="#">UP</a>
                                                                    </li>
                                                                    <div className="arrow"></div>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li className="dropdown-link">
                                                            <a href='#' to="/">Vyapam</a>
                                                        </li>
                                                        <li className="dropdown-link">
                                                            <a href="#">Railway</a>
                                                        </li>
                                                        <li className="dropdown-link">
                                                            <a href="#">Banking</a>
                                                        </li>
                                                        <div className="arrow"></div>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="dropdown-link">
                                                <a href="#">Link 4</a>
                                            </li>
                                            <div className="arrow"></div>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-link" >
                                    <a href="#">Plans<i className="fas fa-caret-down"></i></a>
                                    <div className="dropdown">
                                        <ul>
                                            <li className="dropdown-link">
                                                <a href="#">Normal plan</a>
                                            </li>
                                            <li className="dropdown-link">
                                                <a href="#">Plus Plan +</a>
                                            </li>


                                            <div className="arrow"></div>
                                        </ul>
                                    </div>
                                </li>
                                {/* <li className="nav-link" >
                                    <Link to="/" href="#">Skill Development</Link>
                                </li> */}
                                <li className="nav-link" >
                                    <a href="#">More <i className="fas fa-caret-down"></i></a>
                                    <div className="dropdown">
                                        <ul>
                                            <li className="dropdown-link">
                                                <a href="#">FORUM</a>
                                            </li>
                                            <li className="dropdown-link">
                                                <a href="#">All Exams - Quizze</a>
                                            </li>
                                            <li className="dropdown-link">
                                                <a href="#">Current Affairs</a>
                                            </li>
                                            <li className="dropdown-link">
                                                <a href="#">KBC Test </a>
                                            </li>
                                            <li className="dropdown-link">
                                                <a href="#">ONLINE- OFFLINE TUTIONS </a>
                                            </li>


                                            <div className="arrow"></div>
                                        </ul>
                                    </div>
                                </li>
                               
                            </ul>
                        </div>
                    </div>

                </div>
                <div className='btn-main-header-container'>
                    <div className='searchbar-main-container'>
                        <div className="searchbar">
                            <div className="searchbar-wrapper">
                                <div className="searchbar-left">
                                    <div className="search-icon-wrapper">
                                        <span className="search-icon searchbar-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                </div>

                                <div className="searchbar-center">
                                    <div className="searchbar-input-spacer"></div>
                                    <input type="text" className="searchbar-input" name="q" title="Search" role="combobox" placeholder="Search..." />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=' log-in-page-master'  >
                        <button onClick={handleLogin} className='btn-login-container'>LogIn</button>
                        <button onClick={handleSignUp} className='btn-signup-container'>Sign Up</button>
                    </div>
                </div>

                {/* *******************{Mobile Responsive Bar}***************************** */}

                <div className='backbtn' >
                    <div id="" className="wrapper">
                        <div id="sidebar" className="sidebar">
                            <div className="media_icon">
                                <div className="img_icon">
                                    <img className="img_icon2" src={print_img12} alt="" />
                                </div>
                                <div className="business_name">
                                    <div className="business_icon">
                                        <h1 className="h1_icon">Bluechip</h1>
                                    </div>
                                    <div className="client_icon">
                                        <p style={{ margin: "0" }} >Bluechipcares@gmail.com</p>
                                    </div>
                                    <div className="category_icon">
                                        <p style={{ margin: "0" }}>Change Category  <i className="fa-solid fa-angle-down"></i></p>
                                    </div>
                                </div>

                                <p className="back_button" onClick={sidebarclose} style={{ color: "black", textAlign: "end", padding: "8PX" }}><i className="fa-solid fa-xmark"></i></p>
                            </div>

                            <div className="nav-links">
                                <div onClick={sidemenu1} className="bar_icon">
                                    <div className="icon-link">
                                        <div className="sidebar_icon">
                                            <i className="fa-solid fa-store icon_size"></i>

                                            <div className="sidebar_main">Home</div>
                                            <i className="fa-solid fa-angle-down"></i>
                                        </div>
                                    </div>
                                </div>

                                {open1 && (
                                    <div className="sub-category">
                                        <div to="/" className="bar_icon">
                                            <div className="icon-link">
                                                <div className="sidebar_icon">
                                                    <i className="fa-solid fa-store icon_size"></i>

                                                    <p className="sidebar_main">Modify Company</p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="bar_icon">
                                            <div className="icon-link">
                                                <div className="sidebar_icon">
                                                    <i className="fa-solid fa-store icon_size"></i>

                                                    <p className="sidebar_main">Close Company</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                                }

                                <div onClick={sidemenu2} className="bar_icon">
                                    <div className="icon-link">
                                        <div className="sidebar_icon">
                                            <i className="fa-solid fa-rectangle-list icon_size"></i>
                                            <div className="sidebar_main">Category</div>
                                            <i className="fa-solid fa-angle-down"></i>
                                        </div>
                                    </div>
                                </div>

                                {open2 && (
                                    <div className="sub-category">
                                        <div to="/" className="bar_icon">
                                            <div className="icon-link">
                                                <div className="sidebar_icon">
                                                    <i className="fa-solid fa-store icon_size"></i>

                                                    <p className="sidebar_main">Nursery</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bar_icon">
                                            <div className="icon-link">
                                                <div className="sidebar_icon">
                                                    <i className="fa-solid fa-store icon_size"></i>

                                                    <p className="sidebar_main">School</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="bar_icon">
                                            <div className="icon-link">
                                                <div className="sidebar_icon">
                                                    <i className="fa-solid fa-store icon_size"></i>

                                                    <p className="sidebar_main">Compotitive Exam</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bar_icon">
                                            <div className="icon-link">
                                                <div className="sidebar_icon">
                                                    <i className="fa-solid fa-store icon_size"></i>

                                                    <p className="sidebar_main">Graduation</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bar_icon">
                                            <div className="icon-link">
                                                <div className="sidebar_icon">
                                                    <i className="fa-solid fa-store icon_size"></i>
                                                    <p className="sidebar_main">Post Graduation</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                )
                                }

                                <div onClick={sidemenu4} className="bar_icon">
                                    <div className="icon-link">
                                        <div className="sidebar_icon">
                                            <i className="fa-solid fa-business-time icon_size"></i>
                                            <div className="sidebar_main">Plan</div>
                                            <i className="fa-solid fa-angle-down"></i>
                                        </div>
                                    </div>
                                </div>

                                {open4 && (
                                    <div className="sub-category">
                                        <div to="/" className="bar_icon">
                                            <div className="icon-link">
                                                <div className="sidebar_icon">
                                                    <i className="fa-solid fa-store icon_size"></i>
                                                    <p className="sidebar_main">Normal Plan</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bar_icon">
                                            <div className="icon-link">
                                                <div className="sidebar_icon">
                                                    <i className="fa-solid fa-store icon_size"></i>
                                                    <p className="sidebar_main">Plus Plan +</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }

                                <div className="bar_icon">
                                    <div className="icon-link">
                                        <div className="sidebar_icon">
                                            <i className="fa-solid fa-business-time icon_size"></i>
                                            <div className="sidebar_main">Skill Development</div>
                                            <i className="fa-solid fa-angle-down"></i>
                                        </div>
                                    </div>
                                </div>

                                <div onClick={sidemenu3} className="bar_icon">
                                    <div className="icon-link">
                                        <div className="sidebar_icon">
                                            <i className="fa-solid fa-receipt icon_size"></i>
                                            <div className="sidebar_main">More</div>
                                            <i className="fa-solid fa-angle-down"></i>
                                        </div>
                                    </div>
                                </div>

                                {open3 && (
                                    <div className="sub-category">
                                        <div to="/" className="bar_icon">
                                            <div className="icon-link">
                                                <div className="sidebar_icon">
                                                    <i className="fa-solid fa-store icon_size"></i>
                                                    <p className="sidebar_main">Forum</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bar_icon">
                                            <div className="icon-link">
                                                <div className="sidebar_icon">
                                                    <i className="fa-solid fa-store icon_size"></i>
                                                    <p className="sidebar_main">All Exams Quizz</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bar_icon">
                                            <div className="icon-link">
                                                <div className="sidebar_icon">
                                                    <i className="fa-solid fa-store icon_size"></i>
                                                    <p className="sidebar_main">Current Afairs</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bar_icon">
                                            <div className="icon-link">
                                                <div className="sidebar_icon">
                                                    <i className="fa-solid fa-store icon_size"></i>

                                                    <p className="sidebar_main">KBC Test</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </div >


    )

}

export default NavBar;
import React, { useState, useEffect, useContext } from "react";
import ImageUpload from "./../../../Pages/ImageUpload";
import { getByIdAdmin, GetAdmin } from "./../../../Api/Admin"
import Checkbox from 'rc-checkbox';
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";
import { getSubject} from "./../../../Api/Subject"



function OperatorAuth() {
    const { type, handleLogout } = useContext(MyContext)
    const [data, setData] = useState([]);
    const [subjectData, setSubjectData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedSubjects, setSelectedSubjects] = useState([]);

    const handleCheckboxChange = (subjectId) => {
        setSelectedSubjects((prevSelected) =>
            prevSelected.includes(subjectId)
                ? prevSelected.filter((id) => id !== subjectId)
                : [...prevSelected, subjectId]
        );
    };
 
    useEffect(() => {
        fetchCategories();
    }, []);
    const fetchCategories = async () => {
        try {
            const response = await GetAdmin();
            const SubjectFatch = await getSubject();
            const filteredData = response.data.filter(item => item.type === "operator");
            setLoading(true);
            setData(filteredData);
            setSubjectData(SubjectFatch.data)
            console.log("---------get------------>", SubjectFatch.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
        setLoading(false);
    };


    return (
        <>
            <div className="main-container-examine-btn-section">
                < Header
                    type={type}
                    showBtn={true} handleLogout={handleLogout} />
            </div>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className=" main-header-examine-m">
                    Operator Master
                    </div>
                    <div className="input-text-box-main">
                        <div className="input-box-examine">
                            <select className="input-examine-master" required>
                                <option  >--Select Operator--</option>
                                {data.map((item, index) => (
                                    <option key={index} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                            <label className="input-label-master-examine-fixed2">Operator</label>
                        </div>
                    </div>
                    <div className=" main-header-examine-m">
                    Subject  Authentication
                    </div>
                    <div className="input-text-box-main">
                        <div className="input-box-examine">
                            <div className="subject-select-container">

                            </div>

                            {subjectData.map((subject) => (
                                <div key={subject.id} style={{ display: "flex" }} className="">
                                    <div className="checkbox-wrapper-3">
                                        <input className="checkbox-auth" type="checkbox"
                                            checked={selectedSubjects.includes(subject.id)}
                                            onChange={() => handleCheckboxChange(subject.id)}
                                            style={{ width: "20px", height: "20px", marginRight: "10px" }}

                                        />

                                        <label for="cbx-3" className="toggle"><span></span></label>
                                    </div>
                                    <div>
                                        <span>{subject.subject_name}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OperatorAuth;

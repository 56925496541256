import React, { useState, useEffect, useContext } from "react";
import { getExaminer, createExaminer, getByIdExaminer, updateExaminer } from "../../../Api/Examiner";
import { getcCategories } from "../../../api";
import { getcSubCategory, getSubCategoryIdBaseData } from "../../../Api/SubCategory";
import { getChildCategory, getChildCategoryIdBaseData } from "../../../Api/ChildCategory";
import { useParams } from "react-router-dom";
import InsertSuccessAlert from "../../../Components/Alert/Create";
import UpdateAlert from "../../../Components/Alert/Update";
import { useNavigate } from "react-router-dom";
import 'multiselect-combo-box/multiselect-combo-box.js';
import { getSubject } from "./../../../Api/Subject"
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";
import { getSubChildCategoryIdBaseData } from "../../../Api/SubChildCategory";
import ImageUpload from "../../ImageUpload";
import 'multiselect-combo-box/multiselect-combo-box.js';
import { Button } from "@mui/material";


const ExamineMaster = () => {
    const { type, handleLogout } = useContext(MyContext)
    const navigate = useNavigate();
    const [getCategory, setCategory] = useState([]);
    const [getSubCategoryData, setSubCategoryData] = useState([]);
    const [getChildCategoryData, setChildCategoryData] = useState([]);
    const [getSubChildCategoryData, setSubChildCategoryData] = useState([]);
    const [subData, setSubData] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [insertSuccess, setInsertSuccess] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updatedData, setUpdatedData] = useState({})
    const [errors, setErrors] = useState({});
    const { id } = useParams();
    const message = "Examine ";
    const nextPage = "/Admin/ExaminerList";
    const [selectedItems, setSelectedItems] = useState([]); 
    const [inputValues, setInputValues] = useState({
        category_id: "",
        sub_category_id: "",
        child_category_id: "",
        SubChild_Category_id: "",
        exam_type: "",
        exam_duration: "",
        total_marks: "",
        marks_negative: "",
        marks_count: "",
        exam_name: "",
        exam_price: "",
        exam_description: "",
        exam_image: null,
        status: "",
        priority: "",
        subject_id: selectedItems.id,
       
    });

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/Admin');
        }
    }, [])

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        fetchCategories2();
        customElements.whenDefined('multiselect-combo-box').then(() => {
            const comboBox = document.querySelector('#objects');
            comboBox.addEventListener('change', handleComboBoxChange);
        });

        return () => {
            // Cleanup event listener when component unmounts
            const comboBox = document.querySelector('#objects');
            if (comboBox) {
                comboBox.removeEventListener('change', handleComboBoxChange);
            }
        };
    }, []);
    
    const fetchCategories2 = async () => {
        try {
            setLoading(true);
            const response = await getSubject(); // Assuming getSubject() returns the subject list
            const subjects = response.data.map(subject => ({
                id: subject.id,
                name: subject.subject_name
            }));
            
            // Once subjects are fetched, populate the combo box
            customElements.whenDefined('multiselect-combo-box').then(() => {
                const comboBox = document.querySelector('#objects');
                comboBox.items = subjects;
            });
    
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };
    

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await getcCategories();
            const categories = response.data.filter(cat => (cat.status === "0"))
            setCategory(categories);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch categories');
            setLoading(false);
        }

    };
    useEffect(() => {
        if (inputValues.category_id) {
            fetchSubCategories(inputValues.category_id);
            setSubCategoryData([]);
            setChildCategoryData([]);
            setSubChildCategoryData([]);
        }
    }, [inputValues.category_id]);

    //    **************Category******************

    const fetchSubCategories = async (categoryId) => {
        try {
            const category = await getSubCategoryIdBaseData(categoryId);
            setLoading(true);
            setSubCategoryData(category);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };
    useEffect(() => {
        if (inputValues.sub_category_id) {
            fetchChildCategories(inputValues.sub_category_id);
            setChildCategoryData([]);
            setSubChildCategoryData([]);
        }
    }, [inputValues.sub_category_id]);

    // ************Sub Category******************

    const fetchChildCategories = async (subCategoryId) => {
        try {
            const subcategory = await getChildCategoryIdBaseData(subCategoryId);
            setLoading(true);
            setChildCategoryData(subcategory)
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };
    useEffect(() => {
        if (inputValues.child_category_id) {
            fetchSubChildCategories(inputValues.child_category_id);
        }
    }, [inputValues.child_category_id]);

    // **********Child Category*************

    const fetchSubChildCategories = async (subCategoryId) => {
        try {
            const subcategory = await getSubChildCategoryIdBaseData(subCategoryId);
            setLoading(true);
            setChildCategoryData(subcategory)
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };
    useEffect(() => {
        console.log("---------getsub-------->", getSubChildCategoryData);

        if (inputValues.SubChild_Category_id) {
            setSubChildCategoryData(inputValues.SubChild_Category_id);

            // setChildCategoryData([]);
            // setSubChildCategoryData([]);
        }
    }, [inputValues.child_category_id]);

    const handleCategoryChange = async (event) => {
        const selectedCategoryId = event.target.value;
        setInputValues({
            ...inputValues,
            category_id: selectedCategoryId,
            sub_category_id: '',
            child_category_id: '',
            SubChild_Category_id: '',
        });
    };
    const handleSubCategoryChange = async (event) => {
        const selectedSubCategoryId = event.target.value;
        setInputValues({
            ...inputValues,
            sub_category_id: selectedSubCategoryId,
            child_category_id: '',
            SubChild_Category_id: '',
        });
    };
    const handleCategoryChange3 = async (event) => {
        const selectedSubCategoryId = event.target.value;
        setInputValues({
            ...inputValues,
            child_category_id: selectedSubCategoryId,
            SubChild_Category_id: '',
        });
    };
    const handleCategoryChange4 = async (event) => {
        const selectedSubChildCategoryId = event.target.value;
        setInputValues({
            ...inputValues,
            SubChild_Category_id: selectedSubChildCategoryId,
        });
    };
    const handleDataStore = (e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
       
    };

    const handleComboBoxChange = (event) => {
        const selectedItems = event.target.selectedItems; // This will contain the selected items
        setSelectedItems(selectedItems); // Save selected items to state
        console.log('Selected items:', selectedItems);
    };

    // ****************Image**********************
    // const handleImageChange = (e) => {
    //     setInputValues(prevState => ({
    //         ...prevState,
    //         exam_image: e.target.files[0] // Set the selected file
    //     }));
    // };

    const handleImageChange = (images) => {
        if (images.length === 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                exam_image: 'Please upload at least one image.'
            }));
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                exam_image: null
            }));
        }
        setInputValues(prevState => ({
            ...prevState,
            exam_image: images[0]
        }));
    };

    const SaveBtn = async (e) => {
        e.preventDefault();  // Prevent form submission
        if (Object.keys(updatedData).length !== 0) {
            // Update the existing record
            try {
                const result = await updateExaminer(inputValues.id, inputValues);
                console.log('Updating data:', result);
                // setSuccess('Category updated successfully');
                setUpdateSuccess(true)
            } catch (error) {
                console.error('Error updating category:', error);
                setError('Internal Server Error: Please try again later');
            }
        } else {
            const formData = new FormData();
            formData.append('category_id', inputValues.category_id);
            formData.append('sub_category_id', inputValues.sub_category_id);
            formData.append('child_category_id', inputValues.child_category_id);
            formData.append('exam_type', inputValues.exam_type);
            formData.append('exam_duration', inputValues.exam_duration);
            formData.append('total_marks', inputValues.total_marks);
            formData.append('marks_negative', inputValues.marks_negative);
            formData.append('marks_count', inputValues.marks_count);
            formData.append('exam_name', inputValues.exam_name);
            formData.append('exam_description', inputValues.exam_description);
            formData.append('status', inputValues.status);
            formData.append('priority', inputValues.priority);
            formData.append('subject_id', inputValues.subject_id);

            if (inputValues.exam_image) {
                formData.append('exam_image', inputValues.exam_image);
            }

            try {
                const isSuccess = await createExaminer(formData);
                if (isSuccess) {
                    console.log('Submitting data:', isSuccess);
                    // setSuccess('Category added successfully');
                    setInsertSuccess(true);
                    setInputValues({
                        category_id: "",
                        sub_category_id: "",
                        child_category_id: "",
                        exam_type: "",
                        exam_duration: "",
                        total_marks: "",
                        marks_negative: "",
                        marks_count: "",
                        exam_name: "",
                        exam_description: "",
                        exam_image: null,
                        status: "",
                        priority: "",
                        subject_id: "",
                    });
                    setError(null);
                    window.location.reload();
                } else {
                    setError('Failed to add category');
                    setSuccess(null);
                }
            } catch (error) {
                console.error('Error adding category:', error);
                setError('Internal Server Error: Please try again later');
                setSuccess(null);
            }
        }
    };

    useEffect(() => {
        const fetchCategoryById = async () => {
            // setloading(true);
            var data = await getByIdExaminer(id);
            var subjectdata = await getSubject()
            if (data) {
                console.log(data);
                setUpdatedData(data.data)
                setSubData(subjectdata.data)
                // setloading(false);
                setInputValues(data.data)
            }
        }
        fetchCategoryById()
    }, [id])




    return (
        <>
            <div className="main-container-examine-btn-section">
                < Header type={type}   handleLogout={handleLogout} />
            </div>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className="main-header-examine-m">Examine Master</div>
                    <div className="input-text-box-main">
                        <div className="input-box-examine">
                            <select
                                name="category_id"
                                className="input-examine-master"
                                value={inputValues.category_id}
                                required
                                onChange={handleCategoryChange}
                            >
                                <option value="" disabled>--Select Category--</option>
                                {getCategory.map(category => (
                                    <option key={category.id} value={category.id}>{category.category_name}</option>
                                ))}
                            </select>
                            <label className="input-label-master-examine-fixed2">Category</label>
                        </div>

                        <div className="input-box-examine">
                            <select
                                name="sub_category_id"
                                className="input-examine-master"
                                value={inputValues.sub_category_id}
                                required
                                onChange={(event) => handleSubCategoryChange(event)}
                            >
                                <option value="">--Select Subcategory--</option>
                                {getSubCategoryData.length > 0 ? (
                                    getSubCategoryData.map(subCategory => (
                                        <option key={subCategory.id} value={subCategory.id}>
                                            {subCategory.sub_category_name}
                                        </option>
                                    ))
                                ) : (
                                    <option value="" disabled>No subcategories available</option>
                                )}
                            </select>
                            <label className="input-label-master-examine-fixed2">Sub Category</label>
                        </div>

                        <div className="input-box-examine">
                            <select
                                name="child_category_id"
                                className="input-examine-master"
                                value={inputValues.child_category_id}
                                required
                                onChange={(event) => handleCategoryChange3(event)}
                            >
                                <option value="" disabled>--Select Child Category--</option>
                                {getChildCategoryData.length > 0 ? (
                                    getChildCategoryData.map(childCategory => (
                                        <option key={childCategory.id} value={childCategory.id}>
                                            {childCategory.child_name}
                                        </option>
                                    ))
                                ) : (
                                    <option value="" disabled>No child categories available</option>
                                )}
                            </select>
                            <label className="input-label-master-examine-fixed2">Child Category</label>
                        </div>
                        <div className="input-box-examine">
                <select
                    name="sub_child_category_id"
                    className="input-examine-master"
                    value={inputValues.sub_child_category_id}
                    required
                    onChange={handleCategoryChange4}
                >
                    <option value="" disabled>--Select Sub Child Category--</option>
                    {getSubChildCategoryData.length > 0 ? (
                        getSubChildCategoryData.map(subChildCategory => (
                            <option key={subChildCategory.id} value={subChildCategory.id}>
                                {subChildCategory.name}
                            </option>
                        ))
                    ) : (
                        <option value="" disabled>No Sub Child Categories available</option>
                    )}
                </select>
                <label className="input-label-master-examine-fixed2">Sub Child Category</label>
            </div>

                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="exam_name"
                                value={inputValues.exam_name}
                                className="input-examine-master"
                                onChange={handleDataStore}
                                required
                            />
                            <label className="input-label-master-examine">Exam Name</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="exam_type"
                                value={inputValues.exam_type}
                                className="input-examine-master"
                                onChange={handleDataStore}
                                required
                            />
                            <label className="input-label-master-examine">Exam Type</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="exam_duration"
                                value={inputValues.exam_duration}
                                className="input-examine-master"
                                onChange={handleDataStore}
                                required
                            />
                            <label className="input-label-master-examine">Exam Duration in Minute</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="exam_price"
                                value={inputValues.exam_price}
                                className="input-examine-master"
                                onChange={handleDataStore}
                                required
                            />
                            <label className="input-label-master-examine"> Price</label>
                        </div>
                        <div className="input-box-examine">
                            <textarea
                                className="input-examine-master"
                                name="exam_description"
                                value={inputValues.exam_description}
                                onChange={handleDataStore}
                                required
                            ></textarea>
                            <label className="input-label-master-examine-fixed">Exam Description </label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                className="input-examine-master"
                                name="total_marks"
                                value={inputValues.total_marks}
                                onChange={handleDataStore}
                                required
                            />
                            <label className="input-label-master-examine">Total Marks</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                className="input-examine-master"
                                name="marks_negative"
                                value={inputValues.marks_negative}
                                onChange={handleDataStore}
                                required
                            />
                            <label className="input-label-master-examine">Negative Marks</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                className="input-examine-master"
                                name="marks_count"
                                value={inputValues.marks_count}
                                onChange={handleDataStore}
                                required
                            />
                            <label className="input-label-master-examine">Question Marks</label>
                        </div>
                        <div className="input-box-examine">
                            <select
                                name="status"
                                className="input-examine-master"
                                value={inputValues.status}
                                onChange={handleDataStore}
                                required
                            >
                                <option value="">Select Status</option>
                                <option value="0">Active</option>
                                <option value="1">Inactive</option>
                            </select>
                            <label className="input-label-master-examine-fixed">Status</label>
                        </div>

                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="priority"
                                value={inputValues.priority}
                                className="input-examine-master"
                                onChange={handleDataStore}
                                required
                            />

                            <label className="input-label-master-examine">Priority</label>
                        </div>
                        <div className="input-box-examine">
                            <div className="combomultiselecterv ">
                                <multiselect-combo-box
                                    
                                    id="objects"
                                    label="objects"
                                    item-id-path="id"
                                    item-value-path="id"
                                    item-label-path="name"
                                    className="input-examine-master"
                                    placeholder="Exam Type"
                                >
                                </multiselect-combo-box>

                            </div>
                        </div>

                        <div className="input-box-examine">
                            <div className="uploadfilebuttcntr">
                                <ImageUpload onImageUpload={handleImageChange} />
                                <label className="input-label-master-examine-fixed">Image Upload</label>

                            </div>
                        </div>

                    </div>
                    <div style={{ paddingRight: "10px", display: "flex", justifyContent: "center" }}>
                        <Button onClick={SaveBtn} variant="contained">Submit</Button>
                    </div>

                </div>
                {insertSuccess && (< InsertSuccessAlert />)}
                {updateSuccess && (< UpdateAlert message={message} nextPage={nextPage} />)}
            </div>
        </>
    );
};

export default ExamineMaster;

import React, { useContext, useEffect, useState } from "react";
import Img1 from "./../Assets/img/images.jpg";
import img2 from "./../Assets/img/school.png";
import img3 from "./../Assets/img/graduation.jpg";
import img4 from "./../Assets/img/pg.jpg";
import img5 from "./../Assets/img/exam.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { getcCategories } from '../api';
import { MyContext } from "../AppRoutes";
import { getBannerImage } from "../Api/BannerImage";



const categories = ["LKG 1", "LKG 2", "LKG 3", "10th", "12th", "MATHEMATICS", "PHYSICS", "CHEMISTRY"];

function BlockProps() {
  const { categorydata, setCategorydata, dataBanner } = useContext(MyContext)

  const navigate = useNavigate();
  const handleButtonClick = () => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/User');
    } else {
      navigate('/Login');
    }
  };
  


  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);



  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await getcCategories();
      setData(response.data);
      console.log("-------------->vk", categorydata);

      setLoading(false);
    } catch (err) {
      setError('Failed to fetch data');
      setLoading(false);
    }
  };

  // const ApiBaseUrl = "http://localhost:8000"
  const ApiBaseUrl = "https://api.clearexams.co.in"


  return (
    <div className="container-category-xxl">
      <div className="catetory-first-xl">
        <div className="category-main-header">
          <h2 className="category-main-h2">More Category</h2>
        </div>
        <div className="input-category-x">
          {categories.map((category, index) => (
            <div className="value-category-left" key={index}>
              <FontAwesomeIcon icon={faChevronRight} className="icon-category-left" />
              {category}
            </div>
          ))}
        </div>
      </div>

      <div className="catetory-second-xl">
        <div className="category-main-header">
          <h2 className="category-main-h2">Popular Category</h2>
        </div>
        <div className="top-container">
          <div className="container-block">


            {categorydata && categorydata
              .filter((CatData) => CatData.status === "0")
              .sort((a, b) => a.priority - b.priority)
              .map((CatData) => (
                <div key={CatData.id} className="card-block-xl" onClick={handleButtonClick}>
                  <div className="card-details">
                    <img className="block-img-x" src={`${ApiBaseUrl}${CatData.category_image}`} alt={CatData.alt} />
                    <p className="text-body">{CatData.category_name}</p>
                  </div>
                  <button onClick={handleButtonClick} className="card-button">{CatData.category_name}</button>
                </div>
              ))
            }

          </div>
        </div>
      </div>

      <div className="catetory-third-xl">
        <div className="category-main-header">
          <h2 className="category-main-h2">More Option</h2>
        </div>
        <div className="container-category-right-main">
          <div className="container-category-right-down">
            {dataBanner && dataBanner
              .filter((CatData) => CatData.type === "1")
              .sort((a, b) => a.priority - b.priority)
              .map((CatData) => (
                <div key={CatData.priority} className="card-block-right-side-container">
                  {/* Use dynamic image source */}
                  <img
                    className="image-category-right"
                    src={`${ApiBaseUrl}/storage/${CatData.uploadImg}` || 'default-image.jpg'}
                    alt="Category"
                  />
                  <a href={CatData.linkurl} target="_blank" rel="noopener noreferrer" >
                    <p className="text-body-category-right">{CatData.title || 'No Description'}</p>
                  </a>
                </div>
              ))}
          </div>
          <div>
            <img className="image-category-right2" src={Img1} alt="Image 1" />
            <p className="text-body-category-right2">Nursery</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockProps;

import React, { useState, useEffect, useContext } from "react";
import { getcSubCategory } from "../../../Api/SubCategory";
import {  createChildCategory, getByIdChildCategory, updateChildCategory } from "../../../Api/ChildCategory";
import {  useParams } from "react-router-dom";
import InsertSuccessAlert from "../../../Components/Alert/Create";
import UpdateAlert from "../../../Components/Alert/Update";
import { useNavigate } from "react-router-dom";
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";
import { Button } from "@mui/material";

const ChildCategory = () => {
    const {type,  handleLogout } = useContext(MyContext)
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [ errors, setErrors] = useState({});
    const [success, setSuccess] = useState(null);
    const [insertSuccess, setInsertSuccess] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updatedData, setUpdatedData] = useState({})
    const { id } = useParams();
    const message = "Child Category ";
    const nextPage = "/Admin/ChildCategoryList"; 


    const [inputValues, setInputValues] = useState({
        sub_category_id: "", // Add a field for the parent category ID
        child_name: "",
        status: "",
        priority: "",
    });
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/Admin');
        }
    },[])

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await getcSubCategory();
                setData(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch categories');
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchCategoryById =async () => {
            // setloading(true);
            var data = await getByIdChildCategory(id);
            if(data){
                console.log(data);
                setUpdatedData(data.data)
                // setloading(false);
                setInputValues(data.data)
            }
        }
        fetchCategoryById()
    }, [id])

    const handleDataStore = (e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateInputs = () => {
        const newErrors = {};
    
        if (!inputValues.sub_category_id) {
            newErrors.sub_category_id = 'Sub Category name is required';
        }
        if (!inputValues.child_name) {
            newErrors.child_name = 'Child Category name is required';
        }
      
        if (!inputValues.status) {
            newErrors.status = 'Status is required';
        }
        setErrors(newErrors);
    
        return Object.keys(newErrors).length === 0;
    };

    const SaveBtn = async (e) => {
        e.preventDefault();
        if (Object.keys(updatedData).length !== 0) {
            // Update the existing record
            const updatedData = await updateChildCategory(inputValues.id, inputValues);
            console.log('Updating data:', updatedData);
            // setSuccess('Category updated successfully');
            setUpdateSuccess(true)
         
        } else {
            if (!validateInputs()) {
                return; // Stop execution if validation fails
            }
        const formData = new FormData();
        formData.append('sub_category_id', inputValues.sub_category_id);
        formData.append('child_name', inputValues.child_name);
        formData.append('status', inputValues.status);
        formData.append('priority', inputValues.priority);

        try {
            const isSuccess = await createChildCategory(formData);
            console.log('Submitting data:', isSuccess);
            // setSuccess('Sub Category added successfully');
            setInsertSuccess(true)

            if (isSuccess) {
                // Clear input values
                setInputValues({
                    sub_category_id: "",
                    child_name: "",
                    status: "",
                    priority: "",
                });
                setError(null);
                // Refresh the page after successful save
                window.location.reload();
            } else {
                setError('Failed to add category');
                setSuccess(null);
            }
        } catch (error) {
            console.error('Error adding category:', error);
            setError('Internal Server Error: Please try again later');
            setSuccess(null);
        }
    }
    };


    return (
        <>
         <div className="main-container-examine-btn-section">
                < Header type={type}  handleLogout={handleLogout} />
               
            </div>
           
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className="main-header-examine-m">
                        Child Category Master
                    </div>
                    <div className="input-text-box-main">

                        <div className="input-box-examine">
                            <select
                                name="sub_category_id" // Add this field
                                className="input-examine-master"
                                value={inputValues.sub_category_id}
                                onChange={handleDataStore}
                                required
                            >
                                <option value="" disabled>--Select Sub Category--</option>
                                {data.map((category) => (
                                    <option key={category.id} value={category.id}>{category.sub_category_name}</option>
                                ))}
                            </select>
                            {errors.sub_category_id && <span className="error">{errors.sub_category_id}</span>}
                            <label className="input-label-master-examine-fixed2">Parent Category</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                name="child_name"
                                type="text"
                                className="input-examine-master"
                                value={inputValues.child_name}
                                onChange={handleDataStore}
                                required
                            />
                             {errors.child_name && <span className="error">{errors.child_name}</span>}
                            <label className="input-label-master-examine">Child Name</label>
                        </div>
                        <div className="input-box-examine">
                            <select
                                name="status"
                                className="input-examine-master"
                                value={inputValues.status}
                                onChange={handleDataStore}
                                required
                            >
                                <option value="">Select Status</option>
                                <option value="0">Active</option>
                                <option value="1">Inactive</option>
                            </select>
                            {errors.status && <span className="error">{errors.status}</span>}
                            <label className="input-label-master-examine-fixed">Status</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="priority"
                                className="input-examine-master"
                                value={inputValues.priority}
                                onChange={handleDataStore}
                                required
                            />
                            <label className="input-label-master-examine">Priority</label>
                        </div>

                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {success && <p style={{ color: 'green' }}>{success}</p>}
                        {insertSuccess && <InsertSuccessAlert />}
                        {updateSuccess && <UpdateAlert message={message} nextPage={nextPage} />}

                    </div>
                    <div style={{ paddingRight: "10px", display: "flex", justifyContent: "center" }}>
                        <Button onClick={SaveBtn} variant="contained">Submit</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChildCategory;

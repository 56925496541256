import api, {getHeaders} from "./Server"

export const getExaminer = async () => {
    try {
        const response = await api.get('/exams',{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching exams:', error);
        throw error;
    }
};

export const createExaminer = async (data) => {
    try {
        const response = await api.post('/exams', data, {
           headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error creating Examiner', error);
        throw error;
    }
};

export const getByIdExaminer = async (id) => {
    try {
        const response = await api.get(`/exams/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching Examiner ${id}:`, error);
        throw error;
    }
};

export const updateExaminer = async (id, data) => {
    try {
        const response = await api.put(`/exams/${id}`, data,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error updating Examiner ${id}:`, error);
        throw error;
    }
};

export const deleteExaminer = async (id) => {
    try {
        const response = await api.delete(`/exams/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error deleting Examiner ${id}:`, error);
        throw error;
    }
};

export default api;

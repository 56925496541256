// src/components/ReviewCard.js
import React, { useState } from 'react';
import img1 from './../Assets/img/man.png'

const ReviewCard = ({ review }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className={`review-card ${hover ? 'hover' : ''}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="image-container">
        <img src={img1} alt={review.name} />
      </div>
      <div className="review-content">
        <h3>{review.name}</h3>
        <p>{review.comment}</p>
      </div>
    </div>
  );
};



const reviews = [
  {
    name: 'Ashveen Kumar',
    comment: 'Amazing service, highly recommended!',
    image: 'https://via.placeholder.com/100',
  },
  {
    name: 'Krishna Kumar',
    comment: 'I had a great experience!',
    image: 'https://via.placeholder.com/100',
  },
  {
    name: 'Kamal Rajput',
    comment: 'I had a great experience!',
    image: 'https://via.placeholder.com/100',
  },
  {
    name: 'Kamal',
    comment: 'I had a great experience!',
    image: 'https://via.placeholder.com/100',
  },
  
  // Add more reviews as needed
];

const ReviewPage = () => {
  return (
    <div className="review-page">
      <h1>Customer Reviews</h1>
      <div className="reviews-container">
        {reviews.map((review, index) => (
          <ReviewCard key={index} review={review} />
        ))}
      </div>
    </div>
  );
};

export default ReviewPage;

import React from 'react'
import './../../Assets/css/About.css';
const About = () => {
  return (
    <> 
        <div className="about-container">
            <h1 className='about-header'>About Us</h1>
            <div className="about-para-bar">
                 <p className='about-para'>
                    Welcome to BLUECHIP JOBS PRIVATE LIMITED, formerly known as Bluechip Jobs, a premier consulting firm with a rich history dating back to 2006. Committed to the principles of excellence, we specialize in crafting perfect profiles and delivering innovative solutions in benchmark practices, trends, policies, and strategies within the realm of Human Resources.
                 </p>
                <p className='about-para'>
                  <b>Our Commitment to Quality:</b> We take pride in our ISO 9001:2015 certification, showcasing our dedication to maintaining the highest standards in our operations. Additionally, our registration with MSME solidifies our standing as a reliable and agile player in the industry.
                </p>
                <p className='about-para'>
                  <b>360 Degree Manpower Solutions:</b> At BLUECHIP JOBS PRIVATE LIMITED, we offer comprehensive manpower solutions encompassing Recruitment, Outsourcing, BPO services, Freelancers, and Survey work, catering to organizations of all sizes across Pan India. From frontline positions to top management roles, we leverage our extensive executive team, robust infrastructure, and over 18+ years of experience to meet the diverse needs of our clients from different industries in Pan India.
                </p>
                <p className='about-para'>
                  <b>National Presence, Local Expertise:</b> With a physical presence in the heart of Chhattisgarh, our office in Raipur serves as a strategic hub for delivering personalized and effective solutions. Our Pan India footprint extends across various industries, providing us with valuable insights and a proven track record in the recruitment of manpower at all levels.
                </p>
                <p className='about-para'>
                  <b>Manpower Recruitment & Outsourcing Excellence:</b> Drawing from our 18+ years of industry experience, we excel in providing end-to-end Manpower Recruitment and outsourcing services. Our commitment to delivering prompt and effective solutions positions us as a trusted partner capable of meeting diverse client requirements within turnaround times. Only company from Central India empanelled with more than 50 companies. The companies empanelled are all either MNCs or companies with a Pan India presence.
                </p>
                <p className='about-para'>
                  <b>Venturing into E-Commerce Services:</b> In December 2021, we embraced the future by diversifying into the e-commerce service sector with a new entity BLUECHIP CARES PVT LTD. Our platform boasts 300+ categories of multiple services, uniquely powered by Bidding Technology tailored for GIG workers and freelancers. This pioneering initiative has positioned us as a trailblazer in the Indian market.
                </p>
                <p className='about-para'>
                  BLUECHIP CARES PVT LTD, an integral part of our group, adds another layer to our commitment to providing exceptional services and making a positive impact in the sectors we operate in.
                </p>
                <p className='about-para'>
                  <b>Recognized Excellence:</b> Our new venture Bluechip Cares is proud to be recognized as one of the Top 120 Startup companies of India in the NSA 2022. This accolade reflects our commitment to innovation, growth, and excellence in our endeavour’s.
                </p>
                <p className='about-para'>
                  At BLUECHIP JOBS PRIVATE LIMITED, we stand as a beacon of reliability, innovation, and client-centric service. Our journey of over 18+ years is a testament to our unwavering commitment to excellence in every aspect of our business. Join us as we continue to redefine standards and make meaningful contributions to the industries we serve.
                </p>
           </div>
        </div>
        
        
    </>
  )
}

export default About

import Homepage from "../src/Pages/HomePage";
import React, { createContext } from "react";

const GlobleInfo = createContext();

const App = () => {
  let user = null;

  // Retrieve and parse user data from localStorage
  const userData = localStorage.getItem('user');
  if (userData) {
    try {
      user = JSON.parse(userData);
    } catch (error) {
      console.error('Error parsing user data from localStorage:', error);
      user = null; // Handle the error by setting user to null
    }
  }

  return (
    <GlobleInfo.Provider value={{ user }}>
      <Homepage />
    </GlobleInfo.Provider>
  );
}

export default App;

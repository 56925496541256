import React, { useState, useEffect, useContext } from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ComputerIcon from '@mui/icons-material/Computer';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import SubjectIcon from '@mui/icons-material/Subject';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Header from './Props/Header';
import { MyContext } from '../../AppRoutes';
import { GetAdmin } from '../../Api/Admin';
import { getSubject } from '../../Api/Subject';
import { getQuestion } from '../../Api/ExamQuestion';


const Dashboard = () => {
    const { type, handleLogout } = useContext(MyContext);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const userData = localStorage.getItem('user');
    const user = JSON.parse(userData);
    const [data, setData] = useState({
        operatorData: [],
        approved: [],
        userAdmin: [],
        subjectId: [],
        QuestionId: [],
        PendingData: [],
        ApprovalData: []
    });

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            setLoading(true);
            const response = await GetAdmin();
            const subjectData = await getSubject();
            const questionData = await getQuestion();

            const operatorData = response.data.filter(item => item.type === "operator");
            const approved = response.data.filter(item => item.type === 'approval');
            const userAdmin = response.data.filter(item => item.type === 'user');
            const subjectId = subjectData.data.filter(item => item.status === '0');
            const QuestionId = questionData.data.filter(item => item.status === '0');
            const PendingData = questionData.data.filter(item => item.usersId == user.id && item.status === "0");
            const ApprovalData = questionData.data.filter(item => item.usersId == user.id && item.status === "1");

            setData({
                operatorData,
                approved,
                userAdmin,
                subjectId,
                QuestionId,
                PendingData,
                ApprovalData
            });

            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };

    // Original items array
    const items = [
        {
            name: 'Users',
            value: data.userAdmin.length,
            icon: <PersonIcon sx={{ fontSize: 50, color: '#FFB300' }} />,
            bgColor: '#FFF3E0'
        },
        {
            name: 'Operator List',
            value: data.operatorData.length,
            icon: <ComputerIcon sx={{ fontSize: 50, color: '#2E7D32' }} />,
            bgColor: '#E8F5E9'
        },
        {
            name: 'Approval List',
            value: data.approved.length,
            icon: <LibraryAddCheckIcon sx={{ fontSize: 50, color: '#7695FF' }} />,
            bgColor: '#cedeff'
        },
        {
            name: 'Subject List',
            value: data.subjectId.length,
            icon: <SubjectIcon sx={{ fontSize: 50, color: '#0288D1' }} />,
            bgColor: '#E1F5FE'
        },
        {
            name: 'Question List',
            value: data.QuestionId.length,
            icon: <ChatBubbleIcon sx={{ fontSize: 50, color: '#D81B60' }} />,
            bgColor: '#FCE4EC'
        },
        {
            name: 'Pending Questions',
            value: data.PendingData.length,
            icon: <ChatBubbleIcon sx={{ fontSize: 50, color: '#D81B60' }} />,
            bgColor: '#FCE4EC'
        },
        {
            name: 'Approval Questions',
            value: data.ApprovalData.length,
            icon: <ChatBubbleIcon sx={{ fontSize: 50, color: '#2E7D32' }} />,
            bgColor: '#E8F5E9'
        }
    ];

    // Filter the items based on the type
    const filteredItems = items.filter(item => {
        if (type === 'operator' || type === 'approval') {
            return item.name === 'Approval Questions' || item.name === 'Pending Questions';
        } else if (type === 'admin') {
            return false; // Hide admin type items
        }
        return true; 
        
    });
    // const save = (data) => {
    //     console.log(data);
    // };

    // const myTheme = createTheme({
    //     // Set up your custom MUI theme here
    // });

    return (
        <>
            <div className="main-container-examine-btn-section">
                <Header type={type} handleLogout={handleLogout} />
            </div>
            <div className="main-header-examine-m">
                Dashboard
                {/* <Adminf/> */}
                {/* <ThemeProvider theme={myTheme}>
                    <MUIRichTextEditor
                        label="Type something here..."
                        onSave={save}
                        inlineToolbar={true}
                    />
                </ThemeProvider>, */}
            </div>
            <Grid className="custom-gri" container spacing={4} sx={{ padding: 3 }}>
                {filteredItems.map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card sx={{ height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: item.bgColor }}>
                            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 2 }}>
                                    {item.icon}
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                        {item.value}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {item.name}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default Dashboard;

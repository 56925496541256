import React, { useState, useEffect, useContext } from "react";
import { createQuestion, getByIdQuestion, updateQuestion } from "../../../Api/ExamQuestion";
import { getSubject } from "../../../Api/Subject";
import { useParams } from "react-router-dom";
import InsertSuccessAlert from "../../../Components/Alert/Create";
import UpdateAlert from "../../../Components/Alert/Update";
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";
import { getPaperYear } from "../../../Api/PaperYears";
import Button from '@mui/material/Button';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getcCategories } from "../../../api";
import { getSubCategoryIdBaseData } from "../../../Api/SubCategory";
import { getChildCategoryIdBaseData } from "../../../Api/ChildCategory";
import { getSubChildCategoryIdBaseData } from "../../../Api/SubChildCategory";

const QuestionMaster = () => {
    const { type, handleLogout } = useContext(MyContext)
    const [category, setCategory] = useState('');
    const [years, setYears] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(null);
    const [updatedData, setUpdatedData] = useState({});
    const { id } = useParams();
    const [insertSuccess, setInsertSuccess] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [paperYears, setPaperYears] = useState([]);

    const [getCategory2, setCategory2] = useState([]);
    const [getSubCategoryData, setSubCategoryData] = useState([]);
    const [getChildCategoryData, setChildCategoryData] = useState([]);
    const [getSubChildCategoryData, setSubChildCategoryData] = useState([]);

    const [questions, setQuestions] = useState([
        { id: 1, language: 'English', question: '', options: ['', '', '', ''], answer: '', answersDescri: '', source: '', status: '0' },
    ]);
    useEffect(() => {
        const fetchUser = () => {
            const userData = localStorage.getItem('user');
            if (userData) {
                const user = JSON.parse(userData);
                setAdminLoginId(user);
            }
        };

        fetchUser(); // Call the function to actually set the state
    }, []);
    const [adminLoginId, setAdminLoginId] = useState('');
    const [inputValues, setInputValues] = useState({
        exam_questions: [{
            category_id: "selectedCategoryId",
            sub_category_id: "selectedSubCategoryId",
            child_category_id: "selectedChildCategoryId",
            SubChild_Category_id: "selectedSubChildCategoryId",
            subject_id: "",
            year_id: "",
            question_name: "",
            objective_a: "",
            objective_b: "",
            objective_c: "",
            objective_d: "",
            answer: "",
            answer_description: "",
            source: "",
            status: "0",
            adminLoginId: 'adminLoginId',
        }]
    });

    const handleInputChange = (index, event, field) => {
        const newQuestions = [...questions];
        if (field === 'question') {
            newQuestions[index].question = event.target.value;
        } else if (field.startsWith('option')) {
            const optionIndex = parseInt(field.slice(-1), 10);
            newQuestions[index].options[optionIndex] = event.target.value;
        } else if (field === 'answer') {
            newQuestions[index].answer = event.target.value;
        } else if (field === 'answersDescri') {
            newQuestions[index].answersDescri = event.target.value;
        } else if (field === 'source') {
            newQuestions[index].source = event.target.value;
        } else if (field === 'status') {
            newQuestions[index].status = event.target.value;
        }
        setQuestions(newQuestions);

        const newInputValues = { ...inputValues };
        newInputValues.exam_questions[index] = {
            ...newInputValues.exam_questions[index],
            question_name: newQuestions[index].question,
            objective_a: newQuestions[index].options[0],
            objective_b: newQuestions[index].options[1],
            objective_c: newQuestions[index].options[2],
            objective_d: newQuestions[index].options[3],
            answer: newQuestions[index].answer,
            answer_description: newQuestions[index].answersDescri,
            source: newQuestions[index].source,
            status: newQuestions[index].status,
        };
        setInputValues(newInputValues);
    };

    const addQuestion = () => {
        const newId = questions.length + 1;
        const newQuestion = { id: newId, language: 'English', question: '', options: ['', '', '', ''], answer: '', answersDescri: '', source: '', status: '0' };
        setQuestions([...questions, newQuestion]);
        setInputValues({
            exam_questions: [
                ...inputValues.exam_questions,
                {
                    subject_id: category ? category : '',
                    year_id: years ? years : '',
                    question_name: "",
                    objective_a: "",
                    objective_b: "",
                    objective_c: "",
                    objective_d: "",
                    answer: "",
                    answer_description: "",
                    source: "",
                    status: "0",
                }
            ]
        });
    };

    const handleCategoryChange = (event) => {
        const selectedCategory = event.target.value;
        setCategory(selectedCategory);
        const updatedQuestions = inputValues.exam_questions.map(question => ({
            ...question,
            subject_id: selectedCategory
        }));
        setInputValues({ exam_questions: updatedQuestions });
    };
    const handleYearChange = (event) => {
        const selectedCategory = event.target.value;
        setYears(selectedCategory);
        const updatedQuestions = inputValues.exam_questions.map(question => ({
            ...question,
            year_id: selectedCategory
        }));
        setInputValues({ exam_questions: updatedQuestions });
    };
    useEffect(() => {
        const fetchCategoryById = async () => {
            var subjectdata = await getPaperYear()
            if (subjectdata) {
                console.log("--------------------->", subjectdata);
                setPaperYears(subjectdata.data)
                // setInputValues(subjectdata.data)
            }
        }
        fetchCategoryById()
    }, [id])

    const handleOptionChange = (index, optIndex, e) => {
        const newQuestions = [...questions];
        newQuestions[index].options[optIndex] = e.target.value;
        setQuestions(newQuestions);
    };

    const handleAnswerChange = (index, e) => {
        const newQuestions = [...questions];
        newQuestions[index].answer = e.target.value;
        setQuestions(newQuestions);
    };

    useEffect(() => {
        fetchSubject();
    }, []);

    const fetchSubject = async () => {
        try {
            setLoading(true);
            const response = await getSubject();
            setSubjects(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };

    const SaveBtn = async (e) => {
        e.preventDefault();
        debugger
        if (Object.keys(updatedData).length !== 0) {
            try {
                const result = await updateQuestion(inputValues.id, { ...inputValues, adminLoginId });
                console.log('Updating data:', result);
                setUpdateSuccess(true);
            } catch (error) {
                console.error('Error updating category:', error);
                setError('Internal Server Error: Please try again later');
            }
        } else {
            const formData = new FormData();

            inputValues.exam_questions.forEach((question, index) => {
                formData.append(`exam_questions[${index}][category_id]`, question.category_id);
                formData.append(`exam_questions[${index}][sub_category_id]`, question.sub_category_id);
                formData.append(`exam_questions[${index}][child_category_id]`, question.child_category_id);
                formData.append(`exam_questions[${index}][SubChild_Category_id]`, question.SubChild_Category_id);

                formData.append(`exam_questions[${index}][subject_id]`, question.subject_id);
                formData.append(`exam_questions[${index}][year_id]`, question.year_id);
                formData.append(`exam_questions[${index}][question_name]`, question.question_name);
                formData.append(`exam_questions[${index}][objective_a]`, question.objective_a);
                formData.append(`exam_questions[${index}][objective_b]`, question.objective_b);
                formData.append(`exam_questions[${index}][objective_c]`, question.objective_c);
                formData.append(`exam_questions[${index}][objective_d]`, question.objective_d);
                formData.append(`exam_questions[${index}][answer]`, question.answer);
                formData.append(`exam_questions[${index}][answer_description]`, question.answer_description);
                formData.append(`exam_questions[${index}][source]`, question.source);
                formData.append(`exam_questions[${index}][status]`, question.status);
                formData.append(`exam_questions[${index}][adminLoginId]`, question.adminLoginId);

            });

            try {
                const isSuccess = await createQuestion(formData);
                console.log('Submitting data:', isSuccess);
                setInsertSuccess(true);

                if (isSuccess) {
                    setInputValues({
                        exam_questions: [{
                            category_id: "",
                            sub_category_id: "",
                            child_category_id: "",
                            SubChild_Category_id: "",
                            subject_id: "",
                            year_id: "",
                            question_name: "",
                            objective_a: "",
                            objective_b: "",
                            objective_c: "",
                            objective_d: "",
                            answer: "",
                            answer_description: "",
                            source: "",
                            status: "0",
                            adminLoginId: 'adminLoginId',
                        }]
                    });
                    setError(null);
                    window.location.reload();
                } else {
                    setError('Failed to add category');
                    setSuccess(null);
                }
            } catch (error) {
                console.error('Error adding category:', error);
                setError('Internal Server Error: Please try again later');
                setSuccess('Question Already Added');
                setSuccess(null);
            }
        }
    };

    useEffect(() => {
        const fetchCategoryById = async () => {
            if (id) { // Check if 'id' is available, meaning it's an edit operation
                try {
                    const data = await getByIdQuestion(id);
                    if (data && data.data && data.data.exam_questions) {
                        console.log(data.data);

                        setUpdatedData(data.data);

                        // Map the fetched data to match the structure of 'questions' state
                        const mappedQuestions = data.data.exam_questions.map((question, index) => ({
                            id: index + 1,
                            language: 'English', // Assuming language is always 'English'
                            question: question.question_name,
                            options: [
                                question.objective_a,
                                question.objective_b,
                                question.objective_c,
                                question.objective_d
                            ],
                            answer: question.answer,
                            answersDescri: question.answer_description,
                            source: question.source,
                            status: question.status
                        }));

                        setQuestions(mappedQuestions);
                        setInputValues(prevState => ({
                            ...prevState,
                            exam_questions: data.data.exam_questions
                        }));
                    }
                } catch (error) {
                    console.error("Error fetching question by ID:", error);
                    setError('Failed to fetch question data');
                }
            }
        };

        fetchCategoryById();
    }, [id]);

    const handleRichEditorChange = (index, value) => {
        const newQuestions = [...questions];
        newQuestions[index].question = value;
        setQuestions(newQuestions);

        const newInputValues = { ...inputValues };
        newInputValues.exam_questions[index].question_name = value;
        setInputValues(newInputValues);
    };
    const handleRichEditorChange2 = (index, value) => {
        const newQuestions = [...questions];
        newQuestions[index].answersDescri = value;
        setQuestions(newQuestions);

        const newInputValues = { ...inputValues };
        newInputValues.exam_questions[index].answer_description = value;
        setInputValues(newInputValues);
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await getcCategories();
            const categories = response.data.filter(cat => (cat.status === "0"))
            setCategory2(categories);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch categories');
            setLoading(false);
        }

    };
    useEffect(() => {
        if (inputValues.category_id) {
            fetchSubCategories(inputValues.category_id);
            setSubCategoryData([]);
            setChildCategoryData([]);
            setSubChildCategoryData([]);
        }
    }, [inputValues.category_id]);

    //    **************Category******************

    const fetchSubCategories = async (categoryId) => {
        try {
            const category = await getSubCategoryIdBaseData(categoryId);
            setLoading(true);
            setSubCategoryData(category);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };
    useEffect(() => {
        if (inputValues.sub_category_id) {
            fetchChildCategories(inputValues.sub_category_id);
            setChildCategoryData([]);
            setSubChildCategoryData([]);
        }
    }, [inputValues.sub_category_id]);

    // ************Sub Category******************

    const fetchChildCategories = async (subCategoryId) => {
        try {
            const subcategory = await getChildCategoryIdBaseData(subCategoryId);
            setLoading(true);
            setChildCategoryData(subcategory)
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };
    useEffect(() => {
        if (inputValues.child_category_id) {
            fetchSubChildCategories(inputValues.child_category_id);
            // setSubChildCategoryData([]);
        }
    }, [inputValues.child_category_id]);

    // **********Child Category*************
    const fetchSubChildCategories = async (childCategoryId) => {
        try {
            console.log('childCategoryId', childCategoryId);

            const subChildCategoryData = await getSubChildCategoryIdBaseData(childCategoryId);  // Fetch sub-child category data from API
            setLoading(true);
            setSubChildCategoryData(subChildCategoryData);  // Update sub-child category data in state
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch sub-child categories');
            setLoading(false);
        }
    };

    useEffect(() => {
        if (inputValues.child_category_id) {
            fetchSubChildCategories(inputValues.child_category_id);  // Fetch sub-child categories
            // setSubChildCategoryData([]);  // Reset sub-child categories when child category changes
        }
    }, [inputValues.child_category_id]);

    const handleCategoryChange2 = async (event) => {
        const selectedCategoryId = event.target.value;
        setInputValues({
            ...inputValues,
            category_id: selectedCategoryId,
            sub_category_id: '',
            child_category_id: '',
            SubChild_Category_id: '',
            exam_questions: [{
                ...inputValues.exam_questions[0],
                category_id: selectedCategoryId
            }]
        });
    };

    const handleSubCategoryChange = async (event) => {
        const selectedSubCategoryId = event.target.value;
        setInputValues({
            ...inputValues,
            sub_category_id: selectedSubCategoryId,
            child_category_id: '',
            SubChild_Category_id: '',
            exam_questions: [{
                ...inputValues.exam_questions[0],
                sub_category_id: selectedSubCategoryId
            }]
        });
    };

    const handleCategoryChange3 = async (event) => {
        const selectedChildCategoryId = event.target.value;
        setInputValues({
            ...inputValues,
            child_category_id: selectedChildCategoryId,
            SubChild_Category_id: '',
            exam_questions: [{
                ...inputValues.exam_questions[0],
                child_category_id: selectedChildCategoryId
            }]
        });
    };

    const handleCategoryChange4 = async (event) => {
        const selectedSubChildCategoryId = event.target.value;
        setInputValues({
            ...inputValues,
            SubChild_Category_id: selectedSubChildCategoryId,
            exam_questions: [{
                ...inputValues.exam_questions[0],
                SubChild_Category_id: selectedSubChildCategoryId
            }]
        });
    };


    return (
        <>
            <div className="main-container-examine-btn-section">
                <Header type={type} handleLogout={handleLogout} />
            </div>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className="main-header-examine-m">Question Master</div>
                    <div className="input-text-box-main">
                        <div className="input-box-examine">
                            <select
                                name="category_id"
                                className="input-examine-master"
                                value={inputValues.category_id}
                                required
                                onChange={handleCategoryChange2}
                            >
                                <option value="" disabled>--Select Category--</option>
                                {getCategory2.map(category => (
                                    <option key={category.id} value={category.id}>{category.category_name}</option>
                                ))}
                            </select>
                            <label className="input-label-master-examine-fixed2">Category</label>
                        </div>

                        <div className="input-box-examine">
                            <select
                                name="sub_category_id"
                                className="input-examine-master"
                                value={inputValues.sub_category_id}
                                required
                                onChange={(event) => handleSubCategoryChange(event)}
                            >
                                <option value="">--Select Subcategory--</option>
                                {getSubCategoryData.length > 0 ? (
                                    getSubCategoryData.map(subCategory => (
                                        <option key={subCategory.id} value={subCategory.id}>
                                            {subCategory.sub_category_name}
                                        </option>
                                    ))
                                ) : (
                                    <option value="" disabled>No subcategories available</option>
                                )}
                            </select>
                            <label className="input-label-master-examine-fixed2">Sub Category</label>
                        </div>
                        <div className="input-box-examine">
                            <select
                                name="child_category_id"
                                className="input-examine-master"
                                value={inputValues.child_category_id}
                                required

                                onChange={(event) => handleCategoryChange3(event)}
                            >
                                <option value="" disabled>--Select Child Category--</option>
                                {getChildCategoryData.length > 0 ? (
                                    getChildCategoryData.map(childCategory => (
                                        <option key={childCategory.id} value={childCategory.id}>
                                            {childCategory.child_name}
                                        </option>
                                    ))
                                ) : (
                                    <option value="" disabled>No child categories available</option>
                                )}
                            </select>
                            <label className="input-label-master-examine-fixed2">Child Category</label>
                        </div>
                        <select
                            name="SubChild_Category_id"
                            className="input-examine-master"
                            value={inputValues.SubChild_Category_id}
                            required
                            onChange={handleCategoryChange4}  // Handle changes in sub-child category
                        >
                            <option value="" disabled>--Select Sub Child Category--</option>
                            {getSubChildCategoryData.length > 0 ? (
                                getSubChildCategoryData.map(subChildCategory => (
                                    <option key={subChildCategory.id} value={subChildCategory.id}>
                                        {subChildCategory.name}
                                    </option>
                                ))
                            ) : (
                                <option value="" disabled>No Sub Child Categories available</option>
                            )}
                        </select>
                        <div className="input-box-examine">
                            <select
                                name="subject_id"
                                className="input-examine-master"
                                value={category}
                                onChange={(event) => handleCategoryChange(event)}
                                required
                            >
                                <option value="" disabled>--Select Subject--</option>
                                {subjects.map((subject) => (
                                    <option key={subject.id} value={subject.id}>{subject.subject_name}</option>
                                ))}
                            </select>
                            <label className="input-label-master-examine-fixed2">Subject</label>
                        </div>
                        <div className="input-box-examine">
                            <select
                                name="year_id"
                                className="input-examine-master"
                                value={years}
                                onChange={(event) => handleYearChange(event)}
                                required
                            >
                                <option value="" disabled>--Select Year--</option>
                                {paperYears.map((data) => (
                                    <option key={data.id} value={data.id}>{data.year}</option>
                                ))}
                            </select>
                            <label className="input-label-master-examine-fixed2">Paper Years</label>
                        </div>
                    </div>
                    <div>
                        {questions
                            .filter(question => question.language === 'English')
                            .map((question, index) => (
                                <div key={question.id}>
                                    <div className="main-header-examine-m">{question.id} - {question.language} Question</div>
                                    <div className="input-box-examine">

                                        <div className="input-box-examine2">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={question.question}
                                                onChange={(event, editor) => handleRichEditorChange(index, editor.getData())}
                                            />
                                            <label className="input-label-master-examine-fixed">English Question</label>
                                        </div>
                                        {/* <label className="input-label-master-examine">{question.language} Question</label> */}
                                    </div>
                                    <div className="input-text-box-main">
                                        {question.options.map((option, optIndex) => (
                                            <div key={optIndex} className="input-box-examine">
                                                <input
                                                    type="text"
                                                    className="input-examine-master"
                                                    value={option}
                                                    onChange={(e) => handleOptionChange(index, optIndex, e)}
                                                    required
                                                />
                                                <label className="input-label-master-examine">Option {String.fromCharCode(65 + optIndex)}</label>
                                            </div>
                                        ))}
                                        <div className="input-box-examine">
                                            <select
                                                className="input-examine-master"
                                                value={question.answer}
                                                onChange={(e) => handleAnswerChange(index, e)}
                                                required
                                            >
                                                <option value="">Select an answer</option>
                                                {question.options.map((option, optIndex) => (
                                                    <option key={optIndex} value={option}>
                                                        Option {String.fromCharCode(65 + optIndex)}: --{option}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className="input-label-master-examine-fixed">Answer</label>
                                        </div>
                                    </div>
                                    <div className="input-box-examine">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={question.answersDescri}
                                            onChange={(event, editor) => handleRichEditorChange2(index, editor.getData())}
                                        />
                                        <label className="input-label-master-examine-fixed">Answer Description</label>
                                    </div>
                                    <div className="input-box-examine">
                                        <textarea
                                            style={{ height: "60px" }}
                                            className="input-examine-master"
                                            value={question.source}
                                            onChange={(e) => handleInputChange(index, e, 'source')}
                                            required
                                        ></textarea>
                                        <label className="input-label-master-examine-fixed">Source</label>
                                    </div>
                                </div>
                            ))}
                        {/* <button className="question-add" onClick={addQuestion}>Add Question</button> */}
                    </div>
                    <div style={{ paddingRight: "10px", display: "flex", justifyContent: "center" }}>
                        <Button onClick={SaveBtn} variant="contained">Submit</Button>
                    </div>
                </div>
                {insertSuccess && (<InsertSuccessAlert />)}
                {updateSuccess && (<UpdateAlert />)}
            </div>
        </>
    );
};

export default QuestionMaster;

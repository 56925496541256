import UsersHeader from '../../Components/UsersHeader';
import { MyContext } from '../../AppRoutes';
import React, { useContext, useState } from 'react';

import { MdOutlineCardGiftcard, MdWorkOutline } from 'react-icons/md';
import { FaUserGraduate, FaProjectDiagram, FaChalkboardTeacher, FaQuestionCircle, FaGraduationCap, FaLightbulb, FaGlobe } from 'react-icons/fa';
// import '../../Assets/css/UserDashboard1.css';
// import TestSeriesList from '../../Components/TextSeriesList';
// import { Position } from 'ckeditor5';


const UserDashboard1 = () => {

    const { UserLogout, API_BASE_URL } = useContext(MyContext);

    const orders = [
        { name: 'Foldable Mini Drone', number: '85631', payment: 'Due', status: 'Pending' },
        { name: 'LARVENDER KFI02 Drone', number: '36378', payment: 'Refunded', status: 'Declined' },
        { name: 'Ruko F11 Pro Drone', number: '49347', payment: 'Due', status: 'Pending' },
        { name: 'GPS 4k Drone', number: '22821', payment: 'Paid', status: 'Delivered' },
    ];

    const programs = [
        {
            title: "Business Analyst Career Program (ChatGPT Included)",
            duration: "8 months",
            projects: "5+ Projects",
            designedFor: "Designed for Freshers",
        },
        {
            title: "Full Stack Development Career Program",
            duration: "9 months",
            projects: "5+ Projects",
            designedFor: "Designed for Freshers",
        },
        {
            title: "Full Stack Development Career Program (ChatGPT Included)",
            duration: "11 months",
            projects: "5+ Projects",
            designedFor: "Designed for Freshers",
        }
    ];


    const accessItems = [
        {
            title: "My Batches",
            description: "View list of batches in which you are enrolled",
            icon: "📖", // Use emojis for simplicity, you can replace with actual icons later
        },
        {
            title: "My History",
            description: "View your recent learning here",
            icon: "🕑",
        },
        {
            title: "My Doubts",
            description: "View the list of your asked doubts in the lectures",
            icon: "❓",
        },
    ];


    return (
        <>
        <div className='after-login-navbar'>
            <UsersHeader UserLogout={UserLogout} />
        </div>
            <div className='UserDashboardMain' >



                {/* adertisecolosel */}
                {/* adertisecolosel */}



                {/* Main Section */}

                <div className="DashboardMainsection">
                    <div className="DashboardMainlive">
                        <FaChalkboardTeacher size={48} />
                        <h3>Classes</h3>
                    </div>
                    <div className="DashboardMainlive">
                        <FaQuestionCircle size={48} />
                        <h3>Live Test & Quizzes</h3>
                    </div>
                    <div className="DashboardMainlive">
                        <FaGraduationCap size={48} />
                        <h3>Free Quizzes</h3>
                    </div>
                    <div className="DashboardMainlive">
                        <FaLightbulb size={48} />
                        <h3>Discussion</h3>
                    </div>

                </div>

                <div className="dashboards">

                    {/* Quick Acces*/}

                    <div className="dashboardcontainor">
                        <div className="quick-access-container">
                            <h2>Quick Access</h2>
                            <div className="quick-access-grid">
                                {accessItems.map((item, index) => (
                                    <div key={index} className="access-card">
                                        <div className="icon-circle">
                                            <span>{item.icon}</span>
                                        </div>
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Suggested test series*/}


                    <div className="dashboardcontainor">
                        <div className="header">
                            <h1>Suggested Test</h1>
                        </div>

                        <div className="sugestionCardWrapper" >
                            <div className="suggestionCard">
                                <h3>Mock Test 1</h3>
                                <p>Take the first mock test to assess your preparation.</p>
                                <button>Start Test</button>
                            </div>
                            <div className="suggestionCard">
                                <h3>Mock Test 2</h3>
                                <p>Test your skills with the second comprehensive mock test.</p>
                                <button>Start Test</button>
                            </div>
                            <div className="suggestionCard">
                                <h3>Mock Test 3</h3>
                                <p>Evaluate your knowledge with this challenging mock test.</p>
                                <button>Start Test</button>
                            </div>
                            <div className="suggestionCard">
                                <h3>Mock Test 4</h3>
                                <p>Analyze your progress with this advanced-level mock test.</p>
                                <button>Start Test</button>
                            </div>
                        </div>

                    </div>

                    {/* Previous Year Qes*/}
                    <div className="dashboardcontainor">
                        <div className="header">
                            <h1>Previous Year Papers</h1>
                        </div>

                        <div className="previouseqesCardWrapper">
                            <div className="previouseqesCard">
                                <h3>2023 Paper</h3>
                                <p>Click to download or view the paper.</p>
                                <button>Download</button>
                            </div>
                            <div className="previouseqesCard">
                                <h3>2022 Paper</h3>
                                <p>Click to download or view the paper.</p>
                                <button>Download</button>
                            </div>
                            <div className="previouseqesCard">
                                <h3>2021 Paper</h3>
                                <p>Click to download or view the paper.</p>
                                <button>Download</button>
                            </div>
                            <div className="previouseqesCard">
                                <h3>2020 Paper</h3>
                                <p>Click to download or view the paper.</p>
                                <button>Download</button>
                            </div>
                        </div>
                    </div>

                    {/* Header Section */}

                    <div className="dashboardcontainor">


                        <div className="header">
                            <h1>Skill Academy</h1>
                            <p>Learn Professional Skills & Become Job Ready</p>
                        </div>

                        {/* Top Icons Section */}
                        <div className="icon-section">
                            <div className="icon-card">
                                <MdOutlineCardGiftcard size={48} />
                                <h3>Internship Certificate</h3>
                            </div>
                            <div className="icon-card">
                                <MdWorkOutline size={48} />
                                <h3>Become Job Ready</h3>
                            </div>
                            <div className="icon-card">
                                <FaUserGraduate size={48} />
                                <h3>Designed for Freshers</h3>
                            </div>
                            <div className="icon-card">
                                <FaProjectDiagram size={48} />
                                <h3>Hands-on Real World Project</h3>
                            </div>
                        </div>
                    </div>

                    {/* Program Cards Section */}

                    <div className="dashboardcontainor programs-section">
                        <h2>Explore Programs</h2>
                        <div className="program-cards">
                            {programs.map((program, index) => (
                                <div className="program-card" key={index}>
                                    <div className="program-info">
                                        <h4>{program.title}</h4>
                                        <p>{program.duration}</p>
                                        <p>{program.projects}</p>
                                        <p>{program.designedFor}</p>
                                    </div>
                                    <div className="batch-info">
                                        <span>Batch closes in 3 days</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
};

export default UserDashboard1;

















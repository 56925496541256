import { useState } from "react";
import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Header({ type, handleLogout, SaveBtn, showBtn }) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenuItemClick = (setting) => {
        if (setting === 'Logout') {
            handleLogout(); // Call the logout function when "Logout" is clicked
        } else if (setting === 'Profile') {
            navigate("/Admin/profile");
        } else if (setting === 'Dashboard') {
            navigate("/Admin/Dashboard");
        } else if (setting === 'Account') {
            navigate("/Admin/Account");
        }
        handleCloseUserMenu(); // Close the menu after the click
    };

    return (
        <div className="main-container-examine-xl2">
            <div className="admin-type-name-show">
                <div>Hi ! {type}</div>
            </div>
            <div className="admin-btn-show">
                {showBtn && (
                    <div style={{paddingRight:"10px"}}>
                         <Button onClick={SaveBtn} variant="contained">Submit</Button>
                        {/* <button onClick={SaveBtn} className="submit-btn">Submit</button> */}
                    </div>
                )}
                {/* <div>
                    <button onClick={handleLogout} className="logout-btn">
                        Log out
                    </button>
                </div> */}
                <div>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={() => handleMenuItemClick(setting)}>
                                    <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </div>
            </div>
        </div>
    );
}

export default Header;

import React from 'react';
import swal from 'sweetalert';

const ApproveConfirmationModal = ({ onConfirm, onCancel }) => {
  const handleConfirm = async () => {
    const willApprove = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to approve this question?",
      icon: "info",
      buttons: true,
    });

    if (willApprove) {
      swal("Approved!", "The question has been approved!", "success");
      onConfirm();
    }
  };

  return (
    <div className="modal-approve">
      <div className="modal-content-approve">
        <h4>Confirm Approval</h4>
        <p>Are you sure you want to approve this question?</p>
        <button onClick={handleConfirm}>Yes, Approve</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default ApproveConfirmationModal;

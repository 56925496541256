import React from 'react';
import Test from './../Assets/img/TEST.png';

const PreviousYearPapers = () => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/pdf/SSC_CHSL_Memory_Based_Paper.pdf'; // Update with your PDF path
    link.download = 'SSC_CHSL_Memory_Based_Paper.pdf'; // Optional: specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const tests = [
    {
      id: 1,
      title: 'SSC CGL General Awareness Sectional Test 1',
      questions: 50,
      marks: 30,
      time: '10 Mins',
      isFree: 'Free',
    },
    {
      id: 2,
      title: 'SSC CGL General Awareness Sectional Test 2',
      questions: 50,
      marks: 30,
      time: '10 Mins',
      isFree: 'Free',
    },
    {
      id: 3,
      title: 'SSC CGL General Awareness Sectional Test 3',
      questions: 50,
      marks: 30,
      time: '10 Mins',
      isFree: 'Free',
    },
    {
      id: 4,
      title: 'SSC CGL General Awareness Sectional Test 4',
      questions: 50,
      marks: 30,
      time: '10 Mins',
      isFree: 'Free',
    },
    {
      id: 5,
      title: 'SSC CGL General Awareness Sectional Test 5',
      questions: 50,
      marks: 30,
      time: '10 Mins',
      isFree: 'Free',
    },
  ];

  const categories = [
    'LKG 1',
    'LKG 2',
    'LKG 3',
    '10th',
    '12th',
    'MATHEMATICS',
    'Category 1',
    'Category 2',
    'Category 3',
    'Category 4',
    'Category 5',
    'Category 6',
    'Category 7',
    'Category 8',
    'Category 9',
    'Category 10',
  ];

  return (
    <div>
      <div>
        <div className="view_mock">
          <h1 className="p-2">
            SSC CGL (Tier I & Tier II) Mock Test 2024 All Tests <span>(932)</span>
          </h1>
        </div>
        <div className="test-series-main-wrapper">
          <div className="test-series-main-wrapper-left">
            <div className="category-container">
              <div className="category-header">Your Category</div>
              {categories.map((category, index) => (
                <div className="category-item" key={index}>
                  {category}
                </div>
              ))}
            </div>
          </div>
          <div className="test-series-main-wrapper-right">
            {tests.map((test, index) => (
              <div className="view_sectional" key={index}>
                <div>
                  <img className="test-img-container" src={Test} alt="" />
                </div>
                <div className="test-s-xl view_ga">
                  <div className="test-category-item-container">
                    <span className="view_free">{test.isFree} </span>
                    <span className="test-series-exam-title">SSC CGL</span>
                  </div>
                  <div className="test-series-main-title-xl">{test.title}</div>
                  <div className="icon-top-main-title-xl">
                    <div className="icon-main-title-xl">
                      <i className="fa-regular fa-circle-question icon-ts1"></i>
                      {test.questions} Questions
                    </div>
                    <div className="icon-main-title-xl">
                      <i className="fa-solid fa-sheet-plastic icon-ts1"></i>
                      {test.marks} Marks
                    </div>
                    <div className="icon-main-title-xl">
                      <i className="fa-regular fa-clock icon-ts1"></i>
                      {test.time}
                    </div>
                  </div>
                </div>
                <div className="col-2 view_btne btn-main-top1">
                  <button className="btn-test-series-primery" onClick={handleDownload}>
                    Download Now
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousYearPapers;

import React, { useContext } from "react";
import { Link } from 'react-router-dom';

// Import assets
import Examlogo from "./../Assets/logos/mrbeast.svg";
import UsersHeader from "./UsersHeader";
import { MyContext } from "../AppRoutes";

// Combined component
const TestSeriesList = () => {
  const { UserLogout} = useContext(MyContext)
  const testSeriesData = [
    {
      logo: Examlogo,
      title: "SSC CGL (Tier I & Tier II) Mock Test 2024",
      totalTests: 1173,
      freeTests: 16,
      languages: "English, Hindi",
      features: ["36 Mission CGL", "144 PYP Toughest Qs CT"],
      userCount: "1255.1k"
    },
    {
      logo: Examlogo,
      title: "SSC MTS and Havaldar Mock Test 2024",
      totalTests: 706,
      freeTests: 11,
      languages: "English, Hindi",
      features: ["20 Most Expected Practice Set", "11 Dial 100 Full Test Series"],
      userCount: "376.9k"
    },
    {
      logo: Examlogo,
      title: "SSC MTS and Havaldar Mock Test 2024",
      totalTests: 706,
      freeTests: 11,
      languages: "English, Hindi",
      features: ["20 Most Expected Practice Set", "11 Dial 100 Full Test Series"],
      userCount: "376.9k"
    },
    {
      logo: Examlogo,
      title: "SSC MTS and Havaldar Mock Test 2024",
      totalTests: 706,
      freeTests: 11,
      languages: "English, Hindi",
      features: ["20 Most Expected Practice Set", "11 Dial 100 Full Test Series"],
      userCount: "376.9k"
    },
  ];

  return (
    <>
      <UsersHeader UserLogout={UserLogout} />
      <div className="test-series-container" style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }} className="test-series-list">
          {testSeriesData.map((testSeries, index) => (
            <div key={index} className="test-series-card">
              <div className="card-header">
                <img src={testSeries.logo} alt="Test Series Logo" className="test-logo" />
                <span className="user-count">
                  <i className="lightning-icon"></i> {testSeries.userCount} Users
                </span>
              </div>
              <div className="card-body">
                <h3 className="test-title">{testSeries.title}</h3>
                <p className="test-info">{testSeries.totalTests} Total Tests | {testSeries.freeTests} Free Tests</p>
                <p className="test-languages">{testSeries.languages}</p>
                <ul className="test-features">
                  {testSeries.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
              <div className="card-footer">
                <Link to="/User/Testseries">
                  <button className="view-series-btn">View Test Series</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TestSeriesList;

import React, { useState, useEffect, useContext } from "react";
import Logo from "../Assets/img/logo.jpeg"
import AndroidStore from "../Assets/img/playstore.svg";
import AppleStore from "../Assets/img/appstore.svg";
import { MyContext } from "../AppRoutes";
import { Link } from "react-router-dom";


const Footer = () => {
    const { categorydata } = useContext(MyContext)

    return (
        <>
            <div className="">
                <div className="footer-main-container">
                    <div className="footer-logo-main-xl">
                        <div><img className="footer-logo-set" src={Logo} alt="" /></div>
                        <div className="footer-company-name-container"><span>Bluechip Cares Pvt Ltd</span></div>
                        <div className="footer-company-address-container-xl"><span>Near By Telibadha Raipur</span></div>
                        <div className="footer-company-address-container-xl"><span>support@bluechipcares.co.in</span></div>
                        <div className="footer-company-address-container-xl"><span> +91 789-454-7854</span></div>
                    </div>

                    <div className="footer-logo-main-xl">
                        <div className="footer-company-all-head-container" > <span>Company</span></div>
                        <div className="footer-company-address-container-xl">
                            <i className="fa-solid fa-angle-right footer-right-arrow"></i>
                            <Link to="/AboutUs" style={{ textDecoration: "none", color: "#b4afaf" }} >
                                <span className="mh2">About Us</span>
                            </Link>
                        </div>
                        <div className="footer-company-address-container-xl">
                            <i className="fa-solid fa-angle-right footer-right-arrow"></i>
                            <Link to="/ContactUs" style={{ textDecoration: "none", color: "#b4afaf" }} >
                                <span className="mh2">Contact Us</span>
                            </Link>
                        </div>
                        {/* <div className="footer-company-address-container-xl">
                            <i className="fa-solid fa-angle-right footer-right-arrow"></i><span className="mh2">Media</span></div>
                        <div className="footer-company-address-container-xl">
                            <i className="fa-solid fa-angle-right footer-right-arrow"></i>
                            <span className="mh2">Sitemap</span>
                        </div> */}
                        <div className="footer-company-address-container-xl">
                            <i className="fa-solid fa-angle-right footer-right-arrow"></i>
                            <span className="mh2">User Policy</span>
                        </div>
                        <div className="footer-company-address-container-xl">
                            <i className="fa-solid fa-angle-right footer-right-arrow"></i>
                            <Link to="/Privacy" style={{ textDecoration: "none", color: "#b4afaf" }}>
                                <span className="mh2">Privacy</span>
                            </Link>
                        </div>
                        <div className="footer-company-address-container-xl">
                            <i className="fa-solid fa-angle-right footer-right-arrow"></i>
                            <Link to="/TermsCondition" style={{ textDecoration: "none", color: "#b4afaf" }}>
                                <span className="mh2">Terms</span>
                            </Link>
                        </div>

                    </div>
                    <div className="footer-logo-main-xl" >
                        <div className="footer-company-all-head-container"><span>Category</span></div>
                        {categorydata && categorydata
                            .filter((CatData) => CatData.status === "0")
                            .sort((a, b) => a.priority - b.priority)
                            .map((CatData) => (
                                <div className="footer-company-address-container-xl" key={CatData.id}>
                                    <i className="fa-solid fa-angle-right footer-right-arrow"></i>
                                    <span className="mh2">{CatData.category_name}</span>
                                </div>
                            ))
                        }
                    </div>

                    <div className="footer-logo-main-xl">
                        <div className="footer-company-all-head-container"><span>More App</span></div>
                        <div className="footer-company-address-container-xl"><img src={AndroidStore} alt="" /></div>
                        <div className="footer-company-address-container-xl"><img src={AppleStore} alt="" /></div>
                        <div className="footer-company-address-container-xl"><span>Follow us on</span></div>
                        <div className="footer-company-address-container-xl">
                            <div className="social-icons">
                                <a className="social-media-xl" href="#" title="facebook">
                                    <i className="fa fa-facebook-square" aria-hidden="true"></i>
                                </a>
                                <a className="social-media-xl" href="#" title="twitter">
                                    <i className="fa fa-twitter-square" aria-hidden="true"></i>
                                </a>
                                <a className="social-media-xl" href="#" title="instagram">
                                    <i className="fa fa-instagram" aria-hidden="true"></i>
                                </a>
                                <a className="social-media-xl" href="#" title="youtube">
                                    <i className="fa fa-youtube-square" aria-hidden="true"></i>
                                </a>
                                <a className="social-media-xl" href="#" title="linkedin">
                                    <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                                </a>

                            </div>
                        </div>

                    </div>

                </div >
                <div className="footer-copyright-pannel-xl">
                    <div>Copyright © 2024 Bluechip Cares Pvt. Ltd.: All rights reserved</div>
                </div>
            </div>
        </>
    );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './../Assets/css/Category.css';
import UsersHeader from '../Components/UsersHeader';
import { getcCategories } from '../api';

const CategoryContainer = () => {
    const [activeCategory, setActiveCategory] = useState(null);
    const [hoveredSubCategory, setHoveredSubCategory] = useState(null);
    const [selectedChildCategory, setSelectedChildCategory] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await getcCategories();
            setData(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };

    const handleCategoryClick = (categoryName) => {
        setActiveCategory(categoryName === activeCategory ? null : categoryName);
    };

    const handleSubCategoryClick = (link) => {
        navigate(link);
    };

    const handleChildCategoryClick = (id) => {
        navigate(`/User/prepaper/exampaper/${id}`);
    };

    return (
        <>
            <UsersHeader />
            <div className="container1">
                <div className="sidebar1">
                    <ul>
                        {data.map((category) => (
                            <React.Fragment key={category.category_name}>
                                {category.subcategories.map((subcategory) => (
                                    <li
                                        key={subcategory.sub_category_name}
                                        onClick={() => handleCategoryClick(subcategory.sub_category_name)}
                                        onMouseEnter={() => setHoveredSubCategory(subcategory.sub_category_name)}
                                        // onMouseLeave={() => setHoveredSubCategory(null)}
                                        className={`subcategory-item ${activeCategory === subcategory.sub_category_name ? 'active' : ''}`}
                                    >
                                        {subcategory.sub_category_name}
                                        {(hoveredSubCategory === subcategory.sub_category_name || activeCategory === subcategory.sub_category_name) && (
                                            <div className="subCategoryGrid">
                                                {/* {subcategory.child_categories.map((childCategory) => (
                                                    <div
                                                        key={childCategory.child_category_name}
                                                        className="childCategoryCard"
                                                        onClick={() => handleChildCategoryClick(childCategory)}
                                                    >
                                                        <div className="childCategoryTitle">{childCategory.child_name}</div>
                                                        <div className="childCategoryPapers">{childCategory.papers} Papers</div>
                                                    </div>
                                                ))} */}
                                            </div>
                                        )}
                                    </li>
                                ))}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>

                <div className="content paper-overflow-container">
                {data.map((category) => (
                            <React.Fragment key={category.category_name}>
                                {category.subcategories.map((subcategory) => (
                                    <div
                                        key={subcategory.sub_category_name}
                                    >
                                        {(hoveredSubCategory === subcategory.sub_category_name || activeCategory === subcategory.sub_category_name) && (
                                            <div className="subCategoryGrid">
                                                {subcategory.child_categories.map((childCategory) => (
                                                    <div
                                                        key={childCategory.child_category_name}
                                                        className="subCategoryCard"
                                                        onClick={() => handleChildCategoryClick(childCategory.id)}
                                                    >
                                                        <div className="childCategoryTitle">{childCategory.child_name}</div>
                                                        <div className="childCategoryPapers">{childCategory.papers} Papers</div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </React.Fragment>
                        ))}
                </div>
            </div>
        </>
    );
};

export default CategoryContainer;

import React, { useState, useEffect } from "react";
import ExamLogo from "./../Assets/img/ExamLogo.webp"
import Image1 from "./../Assets/img/explore-pass__illust.svg"




const Componemt1 = () => {

    return (
        <>
        <div>
            <div className="container-main-top-title-xl2">

                <h2 className="title-container-h2"> Explore Premium Version</h2>
            </div>
            <div className="container-top-bar-xl">
                <div className="left-side-img-bar">
                   
                        <img className="premi-img-x" src={Image1} alt="" />
                    
                </div>
                <div className="right-side-img-bar">
                    <div className="top-header-title-comp">
                        <span>Enroll in Test Series for 670+ exams with</span>
                    </div>
                    <div className="top-main-title-heading-xxl">
                        <h3>Bluechip Exam Portal</h3>
                    </div>
                    <div className="mock-title-descr-bar">
                        <span>
                            Get unlimited access to the most relevant Mock Tests, on India's Structured Online Test series platform
                        </span>
                    </div>
                    <div className="test-series-top-bar-xl">
                        <div className="test-series-wrep"> <i className="fa-solid fa-trophy icon-x1"></i> <span className="mock-series-span-xl">All India Rank</span> </div>
                        <div className="test-series-wrep"> <i className="fa-solid fa-clipboard-list icon-x2"></i> <span className="mock-series-span-xl">Latest Exam Patterns </span></div>
                        <div className="test-series-wrep"> <i className="fa-solid fa-chart-simple icon-x3"></i> <span className="mock-series-span-xl">Performance Analysis</span></div>
                        <div className="test-series-wrep"> <i className="fa-solid fa-laptop-code icon-x4"></i> <span className="mock-series-span-xl">Mock Tests</span></div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Componemt1;

import React from 'react';
import { MdEmail } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import work from './../../Assets/images/working-time.png';
import holiday from './../../Assets/images/holiday.png';
import office from './../../Assets/images/regional.png';
import corporate from './../../Assets/images/cop.png';
import reginal from './../../Assets/images/office.png';
import branch from './../../Assets/images/branch.png';
import mainImage from './../../Assets/images/contact-bar.jpg';

const ContactUs = () => {
    return (
        <>
            <div className="contact-main">
                <h1>Contact Us</h1>
            </div>
            <div className="contact-main-image">
                <img src={mainImage} alt="header-image" className='contact-header-image'/>
            </div>

            <div className="contact-container">
                <div className="contact-content">
                    <img src={work} className="contact-gallery" alt="work" />
                    <h5 className='contact-header-title'>Working Hours</h5>
                    <div className="contact-paragraph">
                        <p className='contact-para'> Monday to Friday – 9:30 AM till 6.00 PM</p>
                        <p className='contact-para'> Saturday – 9:30 AM till 2.00 PM</p>
                    </div>
                </div>
                <div className="contact-content">
                    <img src={holiday} className="contact-gallery"alt="holiday"  />
                    <h5 className='contact-header-title'>Holidays</h5>
                    <div className="contact-paragraph">
                        <p  className='contact-para'> Sunday & National Holidays</p>
                    </div>
                </div>
                <div className="contact-content">
                    <img src={office}className="contact-gallery" alt="office"/>
                    <h5 className='contact-header-title'>Registered Office</h5>
                    <div className="contact-paragraph">
                        <p  className='contact-para'>Laxmi Nagar, Pacpedinaka, Behind Golden Marble, Raipur, Chhattisgarh, India.</p>
                        <p  className='contact-para'><span to="mailto:someone@example.com"><MdEmail className='contact-email-black'/> customercare@bluechipcares.com</span></p>
                    </div>
                    <div className="contact-paragraph">
                        <p  className='contact-para'><BsTelephoneFill/> +91-9826113501</p>
                        <p className='contact-para'><IoLogoWhatsapp className='contact-whatsapp-green'/> +91-6269330154</p>
                    </div>
                </div>
                <div className="contact-content">
                    <img src={corporate} className="contact-gallery" alt="corporate" />
                    <h5 className='contact-header-title'>Corporate Office</h5>
                    <div className="contact-paragraph">
                        <p  className='contact-para'>Laxmi Nagar, Pacpedinaka, Behind Golden Marble, Raipur, Chhattisgarh, India.</p>
                    </div>
                </div>
                <div className="contact-content">
                    <img src={reginal} className="contact-gallery" alt="reginal"  />
                    <h5 className='contact-header-title'>Regional Office</h5>
                    <div className="contact-paragraph">
                        <p  className='contact-para'>Bluechip Cares, Mangal City, Vijay Nagar Square, Indore, India.</p>
                        <p  className='contact-para'><span to="mailto:someone@example.com"><MdEmail className='contact-email-black' /> customercare@bluechipcares.com</span></p>
                    </div>
                    <div className="contact-paragraph">
                        <p className='contact-para'><BsTelephoneFill /> +91-9826113501</p>
                        <p className='contact-para'><IoLogoWhatsapp className='contact-whatsapp-green'/> +91-6269330154</p>
                    </div>
                </div>
                <div className="contact-content">
                    <img src={branch}className="contact-gallery" alt="branch" />
                    <h5 className='contact-header-title'>Branch Offices</h5>
                    <div className="contact-paragraph">
                        <p  className='contact-para'>Atlantis Corporate Park, 508, 5th floor, Opposite Airtel office, Behind Hotel Kingsway,Ring road
                            No - 1 , Telibandha, Raipur, Chhattisgarh, India.</p>
                        <p  className='contact-para'><span to="mailto:someone@example.com"><MdEmail className='contact-email-black'/> customercare@bluechipcares.com</span></p>
                    </div>
                    <div className="contact-paragraph">
                        <p  className='contact-para'><BsTelephoneFill/> +91-9826113501</p>
                        <p  className='contact-para'><IoLogoWhatsapp className='contact-whatsapp-green'/> +91-6269330154</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactUs;

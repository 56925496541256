import { Carousel } from "react-responsive-carousel";
import BlockProps from "../../Components/BlockProps";
import CarouselBox from "../../Components/CarouselBox";
import AutoSlide from "../../Components/AutoSlide";
import Componemt1 from "../../Components/Componemt1";
import Footer from "../Footer";
import ReviewPage from "../../Components/ReviewPage";



function Home() {
  return (
    <div>
      <CarouselBox />
      < AutoSlide />
      < BlockProps />
      < Componemt1 />
      < ReviewPage/>
    </div>

  )
}

export default Home;

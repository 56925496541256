import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./../../Assets/css/UserLogin.css";
import LoginPNG from "./../../Assets/img/loginpage.svg";
// import GoogleLogo from "./../../Assets/logos/googlelogo.png"; // Unused import, consider removing
import api from "./../../Api/Server";

function UserLogin() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [login, setLogin] = useState([]);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const response = await api.post('/login', {
                username: email,
                password: password
            });

            const userData = response.data.user;
            console.log(response.data);
            console.log('adminId:', userData);

            if (userData.type === 'user') {
                const AdminLoginId = JSON.stringify(userData);
                setLogin(AdminLoginId);

                localStorage.setItem('token', response.data.token); // Save token
                localStorage.setItem('user', AdminLoginId); // Save user data

                navigate('/User'); // Navigate to the operator dashboard
            } else {
                setError("Access denied. Only operators can log in.");
            }
           
        } catch (err) {
            setError(err.response?.data?.message || 'An error occurred');
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/User/');
        }
    }, [navigate]);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className="LoginupperContainer">
            <div className="loginPageContainer" style={{ position: "relative" }} >
                <span className="crossicon" onClick={handleBack}>
                    <i className="fa-solid fa-xmark"></i>
                </span>
                <div className="imageContainer">
                    <img className="imagePic" src={LoginPNG} alt="Login Illustration" />
                </div>
                <div className="loginContainer">
                    <h2>Login</h2>
                    <form className="form">
                        <div className="formGroup">
                            <label htmlFor="email">Email Address</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                            />
                        </div>
                        <div className="formGroup">
                            <label htmlFor="password">Password</label>
                            <div className="passwordContainer">
                                <input
                                    type={passwordVisible ? 'text' : 'password'}
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter your password"
                                />
                                <i
                                    className={`fa ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'} passwordIcon`}
                                    onClick={togglePasswordVisibility}
                                ></i>
                                {error && <p style={{ color: "red" }} className="error-message">{error}</p>}
                            </div>
                        </div>
                        <div className="rememberForgetCont">
                            <div className="rememberClick">
                                <input type="checkbox" id="rememberMe" />
                                <label htmlFor="rememberMe">Remember me</label>
                            </div>
                            <div className="forgetPass">Forgot password?</div>
                        </div>
                        <div className="buttonContainer">
                            <button type="button" onClick={handleLogin} className="loginButton">
                                Login
                            </button>
                            {/* 
                            <div className="loginWithContainer">
                                <button type="button" className="socialButton googleButton">
                                    <img src={GoogleLogo} alt="Google" />Continue with Google
                                </button>
                            </div> 
                            */}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default UserLogin;

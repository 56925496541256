import api, { getHeaders } from './Server';

export const getBannerImage = async () => {
    try {
        const response = await api.get('/bannerimage',{
            headers: getHeaders()
        });
        console.log(response.data)
        return response.data;
    } catch (error) {
        console.error('Error fetching banner image:', error);
        throw error;
    }
};

export const createBannerImage = async (data) => {
    try {
        const response = await api.post('/bannerimage', data,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error creating banner image:', error);
        throw error;
    }
};

export const getByIdBannerImage = async (id) => {
    try {
        const response = await api.get(`/bannerimage/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching banner image ${id}:`, error);
        throw error;
    }
};

export const updateBannerImage = async (id, data) => {
    try {
        const response = await api.put(`/bannerimage/${id}`, data);
        return response.data;
    } catch (error) {
        console.error(`Error updating banner image ${id}:`, error);
        throw error;
    }
};

export const deleteBannerImage = async (id) => {
    try {
        const response = await api.delete(`/bannerimage/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting banner image ${id}:`, error);
        throw error;
    }
};


// export default api;

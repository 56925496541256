import api, {getHeaders} from "./Server"

export const getcSubCategory = async () => {
    try {
        const response = await api.get('/subcategories',{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching Sub Categories:', error);
        throw error;
    }
};

export const createSubCategory = async (data) => {
    try {
        const response = await api.post('/subcategories', data,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error creating Sub Categories:', error);
        throw error;
    }
};

export const getByIdSubCategory = async (id) => {
    try {
        const response = await api.get(`/subcategories/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching Sub Categories ${id}:`, error);
        throw error;
    }
};

export const updateSubCategory = async (id, data) => {
    try {
        const response = await api.put(`/subcategories/${id}`, data,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error updating Sub Categories ${id}:`, error);
        throw error;
    }
};

export const deleteSubCategory = async (id) => {
    try {
        const response = await api.delete(`/subcategories/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error deleting Sub Categories ${id}:`, error);
        throw error;
    }
};
export const getSubCategoryIdBaseData = async (categoryId) => {
    try {
        const response = await api.get(`/subcategories/getById/${categoryId}`,{
            headers: getHeaders()
        });
        if (response.data.data.length > 0) {
            return response.data.data;
        }else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching subcategories:', error);
        throw error;
    }
};
export default api;

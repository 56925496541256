import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import api from "./../../Api/Server";

function LogIn() {
    
    // const {AdminLoginId, setAdminLoginId} = useContext(MyContext)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [login, setLogin] = useState([]);


    const [showModal, setShowModal] = useState(false);
    const [verifypassword, setVerifypassword] = useState(false);
    const [resetpassword, setResetpassword] = useState(false);

    const handleLogin = async () => {
        try {
            const response = await api.post('/login', {
                username: email,
                password: password
            });
            const userData = response.data.user;
            console.log(response.data);
            console.log('adminId:', userData);


            // const AdminLoginId = JSON.stringify(response.data.user);
            // setLogin(AdminLoginId)
            
            // localStorage.setItem('token', response.data.token); // Save token
            // localStorage.setItem('user', AdminLoginId); // Save adminId
            // navigate('/Admin/Dashboard');
            if (userData.type === 'Admin') {
                const AdminLoginId = JSON.stringify(userData);
                setLogin(AdminLoginId);

                localStorage.setItem('token', response.data.token); // Save token
                localStorage.setItem('user', AdminLoginId); // Save user data

                navigate('/Admin/Dashboard'); // Navigate to the operator dashboard
            } else {
                setError("Access denied. ");
            }
        } catch (err) {
            setError(err.response?.data?.message || 'An error occurred');
        }
    };
    
    const handleOpen = () => {
        setVerifypassword(true);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const navigate = useNavigate();


    const handleclose = () => {
        setVerifypassword(false);
    }

    const handlereset = () => {
        setResetpassword(true);
    }
    const handleresetHide = () => {
        setResetpassword(false);
    }

    const handleBack = () => {
        navigate(-1);
    };

    const inputRefs = useRef([]);

    const handleChange = (e, index) => {
        const { value } = e.target;

        if (value.length === 1 && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !e.target.value && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/Admin/');
        }
    },[])


    return (
        <>
            <div className="modal-overlay">
                <div style={{ position: "relative" }} >
                    <span className="font-icon-verify" onClick={handleBack}><i className="fa-solid fa-xmark"></i></span>
                    <div className="form-container">
                        <p className="title">Welcome back</p>
                        <div className="form1">
                            <input type="email"
                                className="input"
                                placeholder="Email/Mobile"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} />
                            <input type="password"
                                className="input"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} />
                            {error && <span style={{color:"red"}} className="error-message">{error}</span>}
                            <span className="page-link" onClick={handlereset}>
                                <span className="page-link-label">Forgot Password?</span>
                            </span>
                            <button onClick={handleLogin} className="form-btn">Log in</button>

                        </div>
                        <p className="sign-up-label">
                            Don't have an account?<Link to="/SignUp" className="sign-up-link">Sign up</Link>
                        </p>
                        <div className="buttons-container">

                            <div className="google-login-button">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" version="1.1" x="0px" y="0px" className="google-icon" viewBox="0 0 48 48" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
	c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24
	c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path>
                                    <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657
	C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path>
                                    <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36
	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path>
                                    <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571
	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
                                </svg>
                                <span>Log in with Google</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                </div>
            </div>

            {/* *******************Reset Password************************* */}
            {resetpassword && (
                <div className="modal-overlay">
                    <div style={{ position: "relative" }}>
                        <span className="font-icon-verify" onClick={handleresetHide}><i className="fa-solid fa-xmark"></i></span>
                        <div className="form-container">
                            <p className="title">Reset Password</p>
                            <div className="form">
                                <input type="email" className="input" placeholder="Email/Mobile" />
                                <input type="password" className="input" placeholder="Password" />


                                <button onClick={handleOpen} className="form-btn">Confirm</button>

                            </div>
                            <p className="sign-up-label">

                            </p>

                        </div>



                    </div>
                    <div className="modal-footer">
                    </div>
                </div>
            )}

            {/* *******************{Verify Password}********************* */}
            {verifypassword && (
                <div className="modal-overlay">
                    <div style={{ position: "relative" }} >
                        <form className="form-verify-input">
                            <span className="font-icon-verify" onClick={handleclose}><i className="fa-solid fa-xmark"></i></span>


                            <div className="info">
                                <span className="title">Verify OTP</span>
                                <p className="description">Enter the two-factor authentication code </p>
                            </div>
                            <div className="input-fields">
                                {Array(4).fill().map((_, index) => (
                                    <input
                                        key={index}
                                        type="tel"

                                        ref={el => inputRefs.current[index] = el}
                                        onChange={(e) => handleChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                    />
                                ))}
                            </div>

                            <div className="action-btns">
                                <Link className="verify-btn-xl" style={{ textDecoration: "none" }} to="/">
                                    <span className="" >Verify</span>
                                </Link>
                                <span onClick={handleclose} className="clear-btn-xl" >Clear</span>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                    </div>
                </div>
            )}



        </>

    );
}

export default LogIn;

import React from 'react';
import swal from 'sweetalert';

const DeleteConfirmationModal = ({ onConfirm, onCancel }) => {
  const handleConfirm = async () => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this file?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      swal("Deleted!", "Your file has been deleted!", "success");
      onConfirm();
    }
  };

  return (
    <div className="modal-delete">
      <div className="modal-content-delete">
        <h4>Confirm Delete</h4>
        <p>Are you sure you want to delete this item?</p>
        <button onClick={handleConfirm}>Yes, Delete</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;

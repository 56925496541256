import React, { useState, useEffect, useContext } from "react";
import ImageUpload from "./../../../Pages/ImageUpload";
import { createQuestion, getByIdQuestion, updateQuestion } from "../../../Api/ExamQuestion";
import { getSubject } from "../../../Api/Subject";
import { useParams } from "react-router-dom";
import InsertSuccessAlert from "../../../Components/Alert/Create";
import UpdateAlert from "../../../Components/Alert/Update";
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";
import {GetOperatorPermision} from "./../../../Api/OperatorPermision"

const QuestionMaster = () => {
    const { type ,handleLogout } =  useContext(MyContext)
    const [category, setCategory] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(null);
    const [updatedData, setUpdatedData] = useState({});
    const { id } = useParams();
    const [insertSuccess, setInsertSuccess] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    
    const [questions, setQuestions] = useState([
        { id: 1, language: 'English', question: '', options: ['', '', '', ''], answer: '', answersDescri: '', source: '', status: '0' },
    ]);
    useEffect(() => {
        const fetchUser = () => {
            const userData = localStorage.getItem('user');
            if (userData) {
                const user = JSON.parse(userData);
                setAdminLoginId(user);
            }
        };
    
        fetchUser(); // Call the function to actually set the state
    }, []);
    const [adminLoginId, setAdminLoginId] = useState(''); 
    const [inputValues, setInputValues] = useState({
        exam_questions: [{
            subject_id: "",
            question_name: "",
            objective_a: "",
            objective_b: "",
            objective_c: "",
            objective_d: "",
            answer: "",
            answer_description: "",
            source: "",
            status: "0",
            adminLoginId: 'adminLoginId',
        }]
    });

    const handleInputChange = (index, event, field) => {
        const newQuestions = [...questions];
        if (field === 'question') {
            newQuestions[index].question = event.target.value;
        } else if (field.startsWith('option')) {
            const optionIndex = parseInt(field.slice(-1), 10);
            newQuestions[index].options[optionIndex] = event.target.value;
        } else if (field === 'answer') {
            newQuestions[index].answer = event.target.value;
        } else if (field === 'answersDescri') {
            newQuestions[index].answersDescri = event.target.value;
        } else if (field === 'source') {
            newQuestions[index].source = event.target.value;
        } else if (field === 'status') {
            newQuestions[index].status = event.target.value;
        }
        setQuestions(newQuestions);

        const newInputValues = { ...inputValues };
        newInputValues.exam_questions[index] = {
            ...newInputValues.exam_questions[index],
            question_name: newQuestions[index].question,
            objective_a: newQuestions[index].options[0],
            objective_b: newQuestions[index].options[1],
            objective_c: newQuestions[index].options[2],
            objective_d: newQuestions[index].options[3],
            answer: newQuestions[index].answer,
            answer_description: newQuestions[index].answersDescri,
            source: newQuestions[index].source,
            status: newQuestions[index].status,
        };
        setInputValues(newInputValues);
    };

    const addQuestion = () => {
        const newId = questions.length + 1;
        const newQuestion = { id: newId, language: 'English', question: '', options: ['', '', '', ''], answer: '', answersDescri: '', source: '', status: '0' };
        setQuestions([...questions, newQuestion]);
        setInputValues({
            exam_questions: [
                ...inputValues.exam_questions,
                {
                    subject_id: category ? category : '',
                    question_name: "",
                    objective_a: "",
                    objective_b: "",
                    objective_c: "",
                    objective_d: "",
                    answer: "",
                    answer_description: "",
                    source: "",
                    status: "0",
                }
            ]
        });
    };

    const handleCategoryChange = (event) => {
        const selectedCategory = event.target.value;
        setCategory(selectedCategory);
        const updatedQuestions = inputValues.exam_questions.map(question => ({
            ...question,
            subject_id: selectedCategory
        }));
        setInputValues({ exam_questions: updatedQuestions });
    };

    const handleOptionChange = (index, optIndex, e) => {
        const newQuestions = [...questions];
        newQuestions[index].options[optIndex] = e.target.value;
        setQuestions(newQuestions);
    };

    const handleAnswerChange = (index, e) => {
        const newQuestions = [...questions];
        newQuestions[index].answer = e.target.value;
        setQuestions(newQuestions);
    };

    useEffect(() => {
        fetchSubject();
    }, []);

    const fetchSubject = async () => {
        try {
            setLoading(true);
            const response = await getSubject();
            setSubjects(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };

    const SaveBtn = async (e) => {
        e.preventDefault(); 
        if (Object.keys(updatedData).length !== 0) {
            try {
                const result = await updateQuestion(inputValues.id, {...inputValues, adminLoginId });
                console.log('Updating data:', result);
                setUpdateSuccess(true);
            } catch (error) {
                console.error('Error updating category:', error);
                setError('Internal Server Error: Please try again later');
            }
        } else {
            const formData = new FormData();
            
            inputValues.exam_questions.forEach((question, index) => {
                formData.append(`exam_questions[${index}][subject_id]`, question.subject_id);
                formData.append(`exam_questions[${index}][question_name]`, question.question_name);
                formData.append(`exam_questions[${index}][objective_a]`, question.objective_a);
                formData.append(`exam_questions[${index}][objective_b]`, question.objective_b);
                formData.append(`exam_questions[${index}][objective_c]`, question.objective_c);
                formData.append(`exam_questions[${index}][objective_d]`, question.objective_d);
                formData.append(`exam_questions[${index}][answer]`, question.answer);
                formData.append(`exam_questions[${index}][answer_description]`, question.answer_description);
                formData.append(`exam_questions[${index}][source]`, question.source);
                formData.append(`exam_questions[${index}][status]`, question.status);
                formData.append(`exam_questions[${index}][adminLoginId]`, question.adminLoginId);

            });

            try {
                const isSuccess = await createQuestion(formData);
                console.log('Submitting data:', isSuccess);
                setInsertSuccess(true);

                if (isSuccess) {
                    setInputValues({
                        exam_questions: [{
                            subject_id: "",
                            question_name: "",
                            objective_a: "",
                            objective_b: "",
                            objective_c: "",
                            objective_d: "",
                            answer: "",
                            answer_description: "",
                            source: "",
                            status: "0",
                            adminLoginId: 'adminLoginId',
                        }]
                    });
                    setError(null);
                    window.location.reload();
                } else {
                    setError('Failed to add category');
                    setSuccess(null);
                }
            } catch (error) {
                console.error('Error adding category:', error);
                setError('Internal Server Error: Please try again later');
                setSuccess('Question Already Added');
                setSuccess(null);
            }
        }
    };

    useEffect(() => {
        const fetchCategoryById = async () => {
            if (id) { // Check if 'id' is available, meaning it's an edit operation
                try {
                    const data = await getByIdQuestion(id);
                    if (data && data.data && data.data.exam_questions) {
                        console.log(data.data);
                        
                        setUpdatedData(data.data);
    
                        // Map the fetched data to match the structure of 'questions' state
                        const mappedQuestions = data.data.exam_questions.map((question, index) => ({
                            id: index + 1,
                            language: 'English', // Assuming language is always 'English'
                            question: question.question_name,
                            options: [
                                question.objective_a,
                                question.objective_b,
                                question.objective_c,
                                question.objective_d
                            ],
                            answer: question.answer,
                            answersDescri: question.answer_description,
                            source: question.source,
                            status: question.status
                        }));
    
                        setQuestions(mappedQuestions);
                        setInputValues(prevState => ({
                            ...prevState,
                            exam_questions: data.data.exam_questions
                        }));
                    }
                } catch (error) {
                    console.error("Error fetching question by ID:", error);
                    setError('Failed to fetch question data');
                }
            }
        };
    
        fetchCategoryById();
    }, [id]);
    

    return (
        <>
            <div className="main-container-examine-btn-section">
                <Header SaveBtn={SaveBtn} type={type} showBtn={true} handleLogout={handleLogout} />
            </div>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className="main-header-examine-m">Question Master</div>
                    <div className="input-text-box-main">
                        <div className="input-box-examine">
                            <select
                                name="subject_id"
                                className="input-examine-master"
                                value={category}
                                onChange={(event) => handleCategoryChange(event)}
                                required
                            >
                                <option value="" disabled>--Select Subject--</option>
                                {subjects.map((subject) => (
                                    <option key={subject.id} value={subject.id}>{subject.subject_name}</option>
                                ))}
                            </select>
                            <label className="input-label-master-examine-fixed2">Subject</label>
                        </div>
                        {/* <div style={{ height: "50px" }} className="input-box-examine">
                            <ImageUpload />
                        </div> */}
                    </div>
                    <div>
                        {questions
                            .filter(question => question.language === 'English')
                            .map((question, index) => (
                                <div key={question.id}>
                                    <div className="main-header-examine-m">{question.id} - {question.language} Question</div>
                                    <div className="input-box-examine">
                                        <input
                                            type="text"
                                            name="question_name"
                                            className="input-examine-master"
                                            value={question.question}
                                            onChange={(e) => handleInputChange(index, e, 'question')}
                                            required
                                        />
                                        <label className="input-label-master-examine">{question.language} Question</label>
                                    </div>
                                    <div className="input-text-box-main">
                                        {question.options.map((option, optIndex) => (
                                            <div key={optIndex} className="input-box-examine">
                                                <input
                                                    type="text"
                                                    className="input-examine-master"
                                                    value={option}
                                                    onChange={(e) => handleOptionChange(index, optIndex, e)}
                                                    required
                                                />
                                                <label className="input-label-master-examine">Option {String.fromCharCode(65 + optIndex)}</label>
                                            </div>
                                        ))}
                                        <div className="input-box-examine">
                                            <select
                                                className="input-examine-master"
                                                value={question.answer}
                                                onChange={(e) => handleAnswerChange(index, e)}
                                                required
                                            >
                                                <option value="">Select an answer</option>
                                                {question.options.map((option, optIndex) => (
                                                    <option key={optIndex} value={option}>
                                                        Option {String.fromCharCode(65 + optIndex)}: --{option}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className="input-label-master-examine-fixed">Answer</label>
                                        </div>
                                    </div>
                                    <div className="input-box-examine">
                                        <textarea
                                        style={{height:"60px"}}
                                            className="input-examine-master"
                                            value={question.answersDescri}
                                            onChange={(e) => handleInputChange(index, e, 'answersDescri')}
                                            required
                                        ></textarea>
                                        <label className="input-label-master-examine-fixed">Answer Description</label>
                                    </div>
                                    <div className="input-box-examine">
                                        <textarea
                                        style={{height:"60px"}}
                                            className="input-examine-master"
                                            value={question.source}
                                            onChange={(e) => handleInputChange(index, e, 'source')}
                                            required
                                        ></textarea>
                                        <label className="input-label-master-examine-fixed">Source</label>
                                    </div>
                                </div>
                            ))}
                        <button className="question-add" onClick={addQuestion}>Add Question</button>
                    </div>
                </div>
                {insertSuccess && (<InsertSuccessAlert />)}
                {updateSuccess && (<UpdateAlert />)}
            </div>
        </>
    );
};

export default QuestionMaster;

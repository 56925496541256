import React, { useContext, useEffect, useState } from "react";
import { createBannerImage, getBannerImage, getByIdBannerImage, updateBannerImage } from "./../../../Api/BannerImage";
import { useParams } from 'react-router-dom';
import InsertSuccessAlert from "../../../Components/Alert/Create";
import UpdateAlert from "../../../Components/Alert/Update";
import ImageUpload from "./../../ImageUpload";
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";

const Banners = () => {
    const { type, handleLogout} = useContext(MyContext)
    const [updatedData, setUpdatedData] = useState({})
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [errors, setErrors] = useState({});
    const [images, setImages] = useState([]);
    const { id } = useParams();
    const message = "Category ";
    const nextPage = "/Admin/CategoryList";
    const [inputValues, setInputValues] = useState({
        title: "",
        linkurl: "",
        type: "",
        status: "",
        uploadImg: null,
    });



    useEffect(() => {
        const fetchCategoryById = async () => {
            // setloading(true);
            var data = await getByIdBannerImage(id);
            if (data) {
                console.log(data);
                setUpdatedData(data.data)
                // setloading(false);
                setInputValues(data.data)
            }
        }
        fetchCategoryById()
    }, [id])



    const handleDataStore = (e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));

    };


    const handleImageChange = (images) => {
        if (images.length === 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                uploadImg: 'Please upload at least one image.'
            }));
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                uploadImg: null
            }));
        }
        setInputValues(prevState => ({
                    ...prevState,
                    uploadImg: images[0]
                }));

    };

    const validateInputs = () => {
        const newErrors = {};

        if (!inputValues.title) {
            newErrors.title = 'title name is required';
        }
        if (!inputValues.type) {
            newErrors.type = 'type name select is required';
        }

        if (!inputValues.status) {
            newErrors.status = 'Status is required';
        }
        if (!inputValues.uploadImg) {
            newErrors.uploadImg = 'upload Image is required';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const SaveBtn = async (e) => {
        e.preventDefault();

        if (Object.keys(updatedData).length !== 0) {
            try {
                const result = await updateBannerImage(inputValues.id, inputValues);
                console.log('Updating data:', result);
                setUpdateSuccess(true);
            } catch (error) {
                console.error('Error updating category:', error);
                setError('Internal Server Error: Please try again later');
            }
        } else {
            if (!validateInputs()) {
                return; // Stop execution if validation fails
            }

            const formData = new FormData();
            formData.append('title', inputValues.title);
            formData.append('linkurl', inputValues.linkurl);
            formData.append('type', inputValues.type);
            formData.append('status', inputValues.status);

            if (inputValues.uploadImg) {
                formData.append('uploadImg', inputValues.uploadImg);
            }

            try {
                const isSuccess = await createBannerImage(formData);
                console.log('Submitting data:', isSuccess);
                setLoading(true);

                if (isSuccess) {
                    setInputValues({
                        title: "",
                        linkurl: "",
                        type: "",
                        status: "",
                        uploadImg: null,
                    });
                    setErrors({});

                    window.location.reload();
                } else {
                    setError('Failed to add category');
                }
            } catch (error) {
                console.error('Error adding category:', error);
                setError('Internal Server Error: Please try again later');
            }
        }
    };


    return (
        <>
            <div className="main-container-examine-btn-section">
                < Header type={type} SaveBtn={SaveBtn} showBtn={true} handleLogout={handleLogout} />
               
            </div>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className="main-header-examine-m">
                        Banner Master
                    </div>
                    <div className="input-text-box-main">

                        <div className="input-box-examine">
                            <input
                                name="title"
                                type="text"
                                className="input-examine-master"
                                value={inputValues.title}
                                onChange={handleDataStore}
                                required
                            />
                            {errors.title && <span className="error">{errors.title}</span>}
                            <label className="input-label-master-examine">Title Name</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="linkurl"
                                className="input-examine-master"
                                value={inputValues.linkurl}
                                onChange={handleDataStore}
                                required
                            />
                            {errors.linkurl && <span className="error">{errors.linkurl}</span>}
                            <label className="input-label-master-examine">Url Link</label>
                        </div>
                        <div className="input-box-examine">
                            <select
                                name="type"
                                className="input-examine-master"
                                value={inputValues.type}
                                onChange={handleDataStore}
                                required
                            >
                                <option value="">Select type</option>
                                <option value="0">Slider Image</option>
                                <option value="1">Ads Image</option>
                            </select>
                            {errors.type && <span className="error">{errors.type}</span>}
                            <label className="input-label-master-examine-fixed">Types</label>
                        </div>
                        <div className="input-box-examine">
                            <select
                                name="status"
                                className="input-examine-master"
                                value={inputValues.status}
                                onChange={handleDataStore}
                                required
                            >
                                <option value="">Select Status</option>
                                <option value="0">Active</option>
                                <option value="1">Inactive</option>
                            </select>
                            {errors.status && <span className="error">{errors.status}</span>}
                            <label className="input-label-master-examine-fixed">Status</label>
                        </div>

                       
                        {/* <div className="input-box-examine">
                            <input
                                type="file"
                                name="uploadImg"
                                className="input-examine-master"
                                onChange={handleImageChange}
                                required
                            />
                            {errors.uploadImg && <span className="error">{errors.uploadImg}</span>}
                        </div> */}
                        <div className="input-box-examine">
                            <div className="uploadfilebuttcntr">
                            <ImageUpload onImageUpload={handleImageChange} />
                            <label className="input-label-master-examine-fixed">Image Upload</label>

                                {errors.uploadImg && <span className="error">{errors.uploadImg}</span>}
                            </div>
                        </div>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {success && <p style={{ color: 'green' }}>{success}</p>}
                        {loading && (< InsertSuccessAlert />)}
                        {updateSuccess && <UpdateAlert message={message} nextPage={nextPage} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Banners;

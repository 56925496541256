import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "./../../Api/Server";

function Operator() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [login, setLogin] = useState([]);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            setError("Please fill in both fields.");
            return;
        }

        try {
            const response = await api.post('/login', {
                username: email,
                password: password
            });
            
            const userData = response.data.user;
            console.log(response.data);
            console.log('adminId:', userData);

            if (userData.type === 'operator' || userData.type === 'approval') {
                const AdminLoginId = JSON.stringify(userData);
                setLogin(AdminLoginId);

                localStorage.setItem('token', response.data.token); // Save token
                localStorage.setItem('user', AdminLoginId); // Save user data

                navigate('/Admin/Dashboard'); // Navigate to the operator dashboard
            } else {
                setError("Access denied. Only operators can log in.");
            }
        } catch (err) {
            setError(err.response?.data?.message || 'An error occurred');
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/Admin/Dashboard');
        }
    }, [navigate]);

    return (
        <div className="login-container">
            <div className="background">
                <div className="shape"></div>
                <div className="shape"></div>
            </div>
            <form className="form-operator" onSubmit={handleLogin}>
                <h3>Login Here</h3>

                <label className="label-operator" htmlFor="username">Username</label>
                <input
                    type="text"
                    placeholder="Email or Phone"
                    className="input input-operator"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <label className="pass-xl label-operator" htmlFor="password">Password</label>
                <input
                    type="password"
                    placeholder="Password"
                    className="input input-operator"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                {error && <p style={{ color: "red" }} className="error-message">{error}</p>}

                <button className="button-operator" type="submit">Log In</button>

                <div className="social">
                    {/* Social login buttons could go here */}
                </div>
            </form>
        </div>
    );
}

export default Operator;

import React, { useEffect, useState } from "react";
import ImageUpload from "../ImageUpload";
import { useNavigate } from 'react-router-dom';
import Header from "./Props/Header";
import { getByIdAdmin } from "./../../Api/Admin"

const Account = () => {
    const navigate = useNavigate();
    const [updatedData, setUpdatedData] = useState({})
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [inputValues, setInputValues] = useState({
        name: "",
        email: "",
        mobile: "",
        mobileSecond: "",
        address: "",
        type: "",
    })
    const handleLogout = () => {
        localStorage.clear();
        // localStorage.removeItem('token');
        navigate('/Admin');
    };
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/Admin');
        }
    }, [])
    const userData = localStorage.getItem('user');
    const user = JSON.parse(userData);
    const type = user?.type || { name: 'default' };
    const id = user?.id || { name: 'default' };

    useEffect(() => {
        const fetchCategoryById = async () => {
            // setloading(true);
            var data = await getByIdAdmin(id);
            if (data) {
                console.log(data);
                setUpdatedData(data.data)
                // setloading(false);
                setInputValues(data.data)
            }
        }
        fetchCategoryById()
    }, [id])



    return (
        <>
            <div className="main-container-examine-btn-section">
                < Header type={type} handleLogout={handleLogout} showBtn={true} />
            </div>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className=" main-header-examine-m">
                    Account 
                    </div>
                    <div className="input-text-box-main">
                        <div className="input-box-examine">
                            <input type="text" name="name" value={inputValues.name} className="input-examine-master" required />
                            <label className="input-label-master-examine"> Name</label>
                        </div>

                        
                        <div className="input-box-examine">
                            <input type="email" name="email" value={inputValues.email} className="input-examine-master" required />
                            <label className="input-label-master-examine"> Email Id</label>
                        </div>
                        <div className="input-box-examine">
                            <input type="number" name="mobile" value={inputValues.mobile} className="input-examine-master" required />
                            <label className="input-label-master-examine"> Mob No </label>
                        </div>
                        <div className="input-box-examine">
                            <input type="number" name="mobileSecond" value={inputValues.mobileSecond} className="input-examine-master" required />
                            <label className="input-label-master-examine"> WhatsApp No </label>
                        </div>
                        <div className="input-box-examine">
                            <input type="text" name="address" value={inputValues.address} className="input-examine-master" required />
                            <label className="input-label-master-examine"> Address </label>
                        </div>
                       
                        <div className="input-box-examine">
                            <input type="text" className="input-examine-master" required />
                            <label className="input-label-master-examine"> Facebook Link</label>
                        </div>
                        <div className="input-box-examine">
                            <input type="text" className="input-examine-master" required />
                            <label className="input-label-master-examine"> Instagram Link</label>
                        </div>
                        <div className="input-box-examine">
                            <input type="text" className="input-examine-master" required />
                            <label className="input-label-master-examine"> Twitter Link</label>
                        </div>
                        <div className="input-box-examine">
                            <input type="text" className="input-examine-master" required />
                            <label className="input-label-master-examine"> Youtube Link</label>
                        </div>

                    
                        <div className="input-box-examine">
                            <ImageUpload />
                        </div>

                    </div>
                </div>


            </div>
        </>
    );
};

export default Account;

import React, { useState, useEffect, useContext } from "react";
import { getExaminer, createExaminer, getByIdExaminer, updateExaminer } from "../../../Api/Examiner";
import { getcCategories } from "../../../api";
import { getcSubCategory, getSubCategoryIdBaseData } from "../../../Api/SubCategory";
import { getChildCategory, getChildCategoryIdBaseData } from "../../../Api/ChildCategory";
import { useParams } from "react-router-dom";
import InsertSuccessAlert from "../../../Components/Alert/Create";
import UpdateAlert from "../../../Components/Alert/Update";
import { useNavigate } from "react-router-dom";
import 'multiselect-combo-box/multiselect-combo-box.js';
import { getSubject } from "./../../../Api/Subject"
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";
import { getSubChildCategoryIdBaseData } from "../../../Api/SubChildCategory";
import ImageUpload from "../../ImageUpload";
import { createPreviousYearPaper, getByIdPreviousYearPaper, updatePreviousYearPaper } from "../../../Api/PreviousYearPaper";
import { getPaperYear } from "../../../Api/PaperYears";
import { Button, ButtonBase } from "@mui/material";

const UploadPaper = () => {
    const { type, handleLogout } = useContext(MyContext)
    const navigate = useNavigate();
    const [getCategory, setCategory] = useState([]);
    const [getSubCategoryData, setSubCategoryData] = useState([]);
    const [getChildCategoryData, setChildCategoryData] = useState([]);
    const [getSubChildCategoryData, setSubChildCategoryData] = useState([]);
    const [subData, setSubData] = useState([]);
    const [paperYears, setPaperYears] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [insertSuccess, setInsertSuccess] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updatedData, setUpdatedData] = useState({})
    const [errors, setErrors] = useState({});
    const { id } = useParams();
    const message = "Examine ";
    const nextPage = "/Admin/UploadPaper";
    const [inputValues, setInputValues] = useState({
        category_id: "",
        sub_category_id: "",
        child_category_id: "",
        SubChild_Category_id: "",
        subject_id: "",
        year: "",
        file_path_solve: "",
        file_path_unsolve: "",
        file_path_solve_english: "",
        file_path_unsolve_english: "",
        price: "",
    });

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/Admin');
        }
    }, [])

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await getcCategories();
            const categories = response.data.filter(cat => (cat.status === "0"))
            setCategory(categories);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch categories');
            setLoading(false);
        }

    };
    useEffect(() => {
        if (inputValues.category_id) {
            fetchSubCategories(inputValues.category_id);
            setSubCategoryData([]);
            setChildCategoryData([]);
            setSubChildCategoryData([]);
        }
    }, [inputValues.category_id]);

    //    **************Category******************

    const fetchSubCategories = async (categoryId) => {
        try {
            const category = await getSubCategoryIdBaseData(categoryId);
            setLoading(true);
            setSubCategoryData(category);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };
    useEffect(() => {
        if (inputValues.sub_category_id) {
            fetchChildCategories(inputValues.sub_category_id);
            setChildCategoryData([]);
            setSubChildCategoryData([]);
        }
    }, [inputValues.sub_category_id]);

    // ************Sub Category******************

    const fetchChildCategories = async (subCategoryId) => {
        try {
            const subcategory = await getChildCategoryIdBaseData(subCategoryId);
            setLoading(true);
            setChildCategoryData(subcategory)
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };
    useEffect(() => {
        if (inputValues.child_category_id) {
            fetchSubChildCategories(inputValues.child_category_id);
            // setSubChildCategoryData([]);
        }
    }, [inputValues.child_category_id]);

    // **********Child Category*************

    // **********Child Category*************
    const fetchSubChildCategories = async (childCategoryId) => {
        try {
            console.log('childCategoryId',childCategoryId);
            
            const subChildCategoryData = await getSubChildCategoryIdBaseData(childCategoryId);  // Fetch sub-child category data from API
            setLoading(true);
            setSubChildCategoryData(subChildCategoryData);  // Update sub-child category data in state
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch sub-child categories');
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     console.log("---------getsub-------->", getSubChildCategoryData);

    //     if (inputValues.SubChild_Category_id) {
    //         setSubChildCategoryData(inputValues.SubChild_Category_id);

    //         // setChildCategoryData([]);
    //         // setSubChildCategoryData([]);
    //     }
    // }, [inputValues.child_category_id]);
    useEffect(() => {
        if (inputValues.child_category_id) {
            fetchSubChildCategories(inputValues.child_category_id);  // Fetch sub-child categories
            // setSubChildCategoryData([]);  // Reset sub-child categories when child category changes
        }
    }, [inputValues.child_category_id]);


    const handleCategoryChange = async (event) => {
        const selectedCategoryId = event.target.value;
        setInputValues({
            ...inputValues,
            category_id: selectedCategoryId,
            sub_category_id: '',
            child_category_id: '',
            SubChild_Category_id: '',
        });
    };
    const handleSubCategoryChange = async (event) => {
        const selectedSubCategoryId = event.target.value;
        setInputValues({
            ...inputValues,
            sub_category_id: selectedSubCategoryId,
            child_category_id: '',
            SubChild_Category_id: '',
        });
    };
    const handleCategoryChange3 = async (event) => {
        const selectedSubCategoryId = event.target.value;
        setInputValues({
            ...inputValues,
            child_category_id: selectedSubCategoryId,
            SubChild_Category_id: '',
        });
    };
    const handleCategoryChange4 = async (event) => {
        const selectedSubChildCategoryId = event.target.value;
        setInputValues({
            ...inputValues,
            SubChild_Category_id: selectedSubChildCategoryId,
        });
    };
    const handleDataStore = (e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };



    const SaveBtn = async (e) => {

        e.preventDefault();  // Prevent form submission

        if (Object.keys(updatedData).length !== 0) {
            // Update the existing record
            try {
                const result = await updatePreviousYearPaper(inputValues.id, inputValues);
                console.log('Updating data:', result);
                // setSuccess('Category updated successfully');
                setUpdateSuccess(true)
            } catch (error) {
                console.error('Error updating category:', error);
                setError('Internal Server Error: Please try again later');
            }
        } else {
            const formData = new FormData();
            formData.append('category_id', inputValues.category_id);
            formData.append('sub_category_id', inputValues.sub_category_id);
            formData.append('child_category_id', inputValues.child_category_id);
            formData.append('SubChild_Category_id', inputValues.SubChild_Category_id);
            formData.append('subject_id', inputValues.subject_id);
            formData.append('year', inputValues.year);
            formData.append('file_path_solve', inputValues.file_path_solve);
            formData.append('file_path_unsolve', inputValues.file_path_unsolve);
            formData.append('file_path_solve_english', inputValues.file_path_solve_english);
            formData.append('file_path_unsolve_english', inputValues.file_path_unsolve_english);
            formData.append('price', inputValues.price);

            try {
                const isSuccess = await createPreviousYearPaper(formData);
                if (isSuccess) {
                    console.log('Submitting data:', isSuccess);
                    // setSuccess('Category added successfully');
                    setInsertSuccess(true);
                    setInputValues({
                        category_id: "",
                        sub_category_id: "",
                        child_category_id: "",
                        SubChild_Category_id: "",
                        subject_id: "",
                        year: "",
                        file_path_solve: "",
                        file_path_unsolve: "",
                        file_path_solve_english: "",
                        file_path_unsolve_english: "",
                        price: "",

                    });
                    setError(null);
                    window.location.reload();
                } else {
                    setError('Failed to add category');
                    setSuccess(null);
                }
            } catch (error) {
                console.error('Error adding category:', error);
                setError('Internal Server Error: Please try again later');
                setSuccess(null);
            }
        }
    };

    useEffect(() => {
        const fetchCategoryById = async () => {
            // setloading(true);
            var data = await getByIdPreviousYearPaper(id);
            if (data) {
                console.log(data.data);
                setUpdatedData(data.data)
                setInputValues(data.data)
            }
        }
        fetchCategoryById()
    }, [id])
    useEffect(() => {
        const fetchCategoryById = async () => {
            var subjectdata = await getSubject()
            if (subjectdata) {
                console.log("--------------------->", subjectdata);
                setSubData(subjectdata.data)
                setInputValues(subjectdata.data)
            }
        }
        fetchCategoryById()
    }, [id])

    useEffect(() => {
        const fetchCategoryById = async () => {
            var subjectdata = await getPaperYear()
            if (subjectdata) {
                console.log("--------------------->", subjectdata);
                setPaperYears(subjectdata.data)
                setInputValues(subjectdata.data)
            }
        }
        fetchCategoryById()
    }, [id])




    return (
        <>
            <div className="main-container-examine-btn-section">
                < Header type={type} handleLogout={handleLogout} />
            </div>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className="main-header-examine-m">Upload PDF Master</div>
                    <div className="input-text-box-main">
                        <div className="input-box-examine">
                            <select
                                name="category_id"
                                className="input-examine-master"
                                value={inputValues.category_id}
                                required
                                onChange={handleCategoryChange}
                            >
                                <option value="" disabled>--Select Category--</option>
                                {getCategory.map(category => (
                                    <option key={category.id} value={category.id}>{category.category_name}</option>
                                ))}
                            </select>
                            <label className="input-label-master-examine-fixed2">Category</label>
                        </div>

                        <div className="input-box-examine">
                            <select
                                name="sub_category_id"
                                className="input-examine-master"
                                value={inputValues.sub_category_id}
                                required
                                onChange={(event) => handleSubCategoryChange(event)}
                            >
                                <option value="">--Select Subcategory--</option>
                                {getSubCategoryData.length > 0 ? (
                                    getSubCategoryData.map(subCategory => (
                                        <option key={subCategory.id} value={subCategory.id}>
                                            {subCategory.sub_category_name}
                                        </option>
                                    ))
                                ) : (
                                    <option value="" disabled>No subcategories available</option>
                                )}
                            </select>
                            <label className="input-label-master-examine-fixed2">Sub Category</label>
                        </div>

                        <div className="input-box-examine">
                            <select
                                name="child_category_id"
                                className="input-examine-master"
                                value={inputValues.child_category_id}
                                required

                                onChange={(event) => handleCategoryChange3(event)}
                            >
                                <option value="" disabled>--Select Child Category--</option>
                                {getChildCategoryData.length > 0 ? (
                                    getChildCategoryData.map(childCategory => (
                                        <option key={childCategory.id} value={childCategory.id}>
                                            {childCategory.child_name}
                                        </option>
                                    ))
                                ) : (
                                    <option value="" disabled>No child categories available</option>
                                )}
                            </select>
                            <label className="input-label-master-examine-fixed2">Child Category</label>
                        </div>

                        <select
                            name="SubChild_Category_id"
                            className="input-examine-master"
                            value={inputValues.SubChild_Category_id}
                            required
                            onChange={handleCategoryChange4}  // Handle changes in sub-child category
                        >
                            <option value="" disabled>--Select Sub Child Category--</option>
                            {getSubChildCategoryData.length > 0 ? (
                                getSubChildCategoryData.map(subChildCategory => (
                                    <option key={subChildCategory.id} value={subChildCategory.id}>
                                        {subChildCategory.name}
                                    </option>
                                ))
                            ) : (
                                <option value="" disabled>No Sub Child Categories available</option>
                            )}
                        </select>


                        <div className="input-box-examine">
                            <select
                                name="subject_id"
                                className="input-examine-master"
                                value={inputValues.subject_id}
                                onChange={handleDataStore}
                                required
                            >
                                <option value="" disabled>--Select Subject--</option>
                                {subData.map((item, index) => (
                                    <option key={index} value={item.id}>
                                        {item.subject_name}
                                    </option>
                                ))}
                            </select>
                            <label className="input-label-master-examine-fixed">Subject</label>
                        </div>

                        <div className="input-box-examine">
                            <select
                                name="year"
                                className="input-examine-master"
                                value={inputValues.year}
                                onChange={handleDataStore}
                                required
                            >
                                <option value="" disabled>--Select Year--</option>
                                {paperYears.map((index) => (
                                    <option key={index.id} value={index.id}>
                                        {index.year}
                                    </option>
                                ))}
                            </select>
                            <label className="input-label-master-examine-fixed">Year</label>
                        </div>

                        <div className="input-box-examine">
                            <input
                                type="file"
                                accept=".pdf"
                                name="file_path_solve"
                                className="input-examine-master"
                                onChange={(e) => setInputValues({ ...inputValues, file_path_solve: e.target.files[0] })}
                                required
                            />
                            <label className="input-label-master-examine-fixed">Solve Paper Hindi</label>
                        </div>

                        <div className="input-box-examine">
                            <input
                                type="file"
                                accept=".pdf"
                                name="file_path_unsolve"
                                className="input-examine-master"
                                onChange={(e) => setInputValues({ ...inputValues, file_path_unsolve: e.target.files[0] })}
                                required
                            />
                            <label className="input-label-master-examine-fixed">Unsolve Paper Hindi</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="file"
                                accept=".pdf"
                                name="file_path_solve_english"
                                className="input-examine-master"
                                onChange={(e) => setInputValues({ ...inputValues, file_path_solve_english: e.target.files[0] })}
                                required
                            />
                            <label className="input-label-master-examine-fixed">solve Paper English</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="file"
                                accept=".pdf"
                                name="file_path_unsolve_english"
                                className="input-examine-master"
                                onChange={(e) => setInputValues({ ...inputValues, file_path_unsolve_english: e.target.files[0] })}
                                required
                            />
                            <label className="input-label-master-examine-fixed">Unsolve Paper English</label>
                        </div>

                        <div className="input-box-examine">
                            <input
                                type="number" name="price"
                                value={inputValues.price}
                                className="input-examine-master"
                                onChange={handleDataStore}
                                required
                            />
                            <label className="input-label-master-examine">Price</label>
                        </div>
                    </div>
                    <div style={{ paddingRight: "10px", display: "flex", justifyContent: "center" }}>
                        <Button onClick={SaveBtn} variant="contained">Submit</Button>
                    </div>
                </div>
                {insertSuccess && (< InsertSuccessAlert />)}
                {updateSuccess && (< UpdateAlert message={message} nextPage={nextPage} />)}
            </div>
        </>
    );
};

export default UploadPaper;

import React, { useState, useEffect, useContext } from "react";
import { getExaminer, deleteExaminer } from "../../../Api/Examiner";
import { getSubject } from "../../../Api/Subject";

import DeleteConfirmationModal from "../../../Components/DeleteConfirmationModal";
import { json, useNavigate } from "react-router-dom";
import Loader from "../../../Components/Loader";
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";


const ExaminerList = () => {
    const { type , handleLogout, API_BASE_URL } = useContext(MyContext)

    const [examinerData, setExaminerData] = useState([]);
    const [subjectData, setSubjectData] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [success, setSuccess] = useState(null);
    // const [selectedCategory, setSelectedCategory] = useState('');
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const navigate = useNavigate();
    const [filteredSubjects, setFilteredSubjects] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/Admin');
        }
    },[])
    
    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            setLoading(true);
            const examinerResponse = await getExaminer();
            const subjectResponse = await getSubject();
            const examinerData = examinerResponse.data;
            const subjectData = subjectResponse.data;

            setExaminerData(examinerData);
            setSubjectData(subjectData);
            console.log('------------>', examinerData);

            // Filter subjects based on exam_master_id
            const filtered = examinerData.filter(subject =>
                console.log('get subject---------->', subject)
                // examinerData.some(exam => exam.id === subject.exam_master_id)
            );

            setFilteredSubjects(filtered);
            console.log(filtered);
        } catch (err) {
            setError('Failed to fetch data');
        } finally {
            setLoading(false);
        }
    };



    // ***************Delete*********************

    const handleDelete = async (id) => {
        try {
            await deleteExaminer(id);
            setExaminerData(examinerData.filter(item => item.id !== id));
        } catch (error) {
            setError('Error deleting category');
        }
    };

    const confirmDelete = (id) => {
        setDeleteId(id);
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        await handleDelete(deleteId);
        setShowModal(false);
        setDeleteId(null);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
        setDeleteId(null);
    };

    // *******************Edit**********************
    const handleEdit = (id) => {
        navigate(`/Admin/ExamineMaster/${id}`);
    };


    return (
        <>
            <div className="main-container-examine-btn-section">
                < Header type={type} handleLogout={handleLogout} />
                <div>
                    {/* <button >Submit</button> */}
                </div>
            </div>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className="main-header-examine-m">Examine Master</div>
                    <div className="input-text-box-main">

                        {examinerData.map((exam, exam_subject) => (
                            <div className="exam-type-card" key={exam.id}>
                                <button className="exam-type-mail">
                                    {/* Mail button content (if any) */}
                                    <span><i className="fa-solid fa-pen-to-square edi10" onClick={() => handleEdit(exam.id)}></i></span>
                                    <span><i className="fa-solid fa-trash-can del10" onClick={() => confirmDelete(exam.id)}></i></span>
                                </button>

                                <div className="exam-type-profile-pic">
                                    <img src={`${API_BASE_URL}${exam.exam_image}`} alt={`${exam.exam_name} profile`} width="666.66669" height="666.66669" />
                                </div>
                                <div className="exam-type-bottom">
                                    <div className="exam-type-content">
                                        <div>
                                            <span className="exam-type-name">{exam.exam_name}</span>
                                        </div>
                                        <div>
                                            <span className="exam-type-about-me">{exam.exam_description}</span>
                                        </div>
                                        {/* <div className="social-links-container">
                                            <div className="exam-type-about-me4">SUbject :
                                                {exam.subject.length > 0 ? (
                                                    exam.subject.map((examData, index) => (
                                                        <div key={index} className="exam-type-about-me2"><span className="sp112">{examData.subject_name}</span></div>
                                                    ))
                                                ) : (
                                                    <span> -No subjects available </span>
                                                )}
                                            </div>
                                        </div> */}
                                    </div>



                                   
                                    <div className="exam-type-bottom-bottom">
                                        <div className="social-links-container">
                                            <div className="exam-type-about-me2">Time :<span className="sp112">{exam_subject.subject_name}Min</span></div>
                                            <div className="exam-type-about-me2">Time :<span className="sp112">{exam.exam_duration}Min</span></div>
                                            <div className="exam-type-about-me2">T Marks : <span className="sp112">{exam.total_marks}</span></div>
                                            <div className="exam-type-about-me2">Negative: <span className="sp112">{exam.marks_negative}</span></div>
                                            <div className="exam-type-about-me2">Marks: <span className="sp112">{exam.marks_count}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>
                {showModal && (
                    <DeleteConfirmationModal
                        onConfirm={handleConfirmDelete}
                        onCancel={handleCancelDelete}
                    />
                )}
                {loading && <Loader />}
            </div>
        </>
    );
};

export default ExaminerList;

import React, { useState, useEffect, useContext } from "react";
import Table from 'rc-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import DeleteConfirmationModal from "../../../Components/DeleteConfirmationModal";
import { useNavigate } from 'react-router-dom';
import { getQuestion, deleteQuestion, createQuestion, ApproveQuestion } from "../../../Api/ExamQuestion";
import Loader from "../../../Components/Loader";
import ApproveConfirmationModal from "../../../Components/Alert/ApproveConfirmationModal";
import { getSubject } from "../../../Api/Subject";
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";

const FinalQuestionList = () => {
    const {type , handleLogout} = useContext(MyContext)
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [currentId, setCurrentId] = useState({});
    const [deleteId, setDeleteId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10); // Fixed at 10 rows per page
    const navigate = useNavigate();
    const [userId, setUserId] = useState(""); // Add state for userId
    const [subjectId, setSubjectId] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    

    useEffect(() => {
        fetchCategories();
    }, [userId, subjectId]);

    const fetchCategories = async () => {
        try {
            setLoading(true);
            const response = await getQuestion();
            // const SubjectData = await getSubject();
            // setSubjectId(SubjectData)
            let filteredData = response.data.filter(category => category.status === "1");
            // if (userId) {
            //     filteredData = filteredData.filter(category => category.subject_id === userId);
            // }
            // if (subjectId) {
            //     filteredData = filteredData.filter(category => category.subjectId === subjectId);
            // }
            setData(filteredData);
            setFilteredData(filteredData); // Update filtered data
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
        
    };

    const handleDelete = async (id) => {
        try {
            await deleteQuestion(id);
            setData(data.filter(item => item.id !== id));
        } catch (error) {
            setError('Error deleting category');
        }
    };

    const confirmDelete = (id) => {
        setDeleteId(id);
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        await handleDelete(deleteId);
        setShowModal(false);
        setDeleteId(null);
    };
    const confirmApprove = (id, status) => {
        const data = {
            id: id,
            status: status
        }
        setCurrentId(data);
        setShowApproveModal(true); // Show the approval modal
    };

    const handleCancelDelete = () => {
        setShowModal(false);
        setDeleteId(null);
    };
    const handleConfirmApprove = async () => {
        await handleApprove(currentId);
        setShowApproveModal(false); // Hide the approval modal
        setCurrentId(null);
    };
    const handleCancelApprove = () => {
        setShowApproveModal(false); // Hide the approval modal
        setCurrentId(null);
    };

    const handleEdit = (id) => {
        navigate(`/Admin/ChildCategory/${id}`);
    };
    const handleApprove = async (data) => {

        try {

            await ApproveQuestion(data); // Replace with your API call for approval
            fetchCategories();
        } catch (error) {
            setError('Error approving question');
        }
    };


    const columns = [
        { title: 'S No', dataIndex: 'id', key: 'id', width: 50, className: 'table-header-xl', render: (text, record, index) => (currentPage - 1) * rowsPerPage + index + 1 },
        { title: 'Operator', dataIndex: 'admin', key: 'admin.name', width: 50, className: 'table-header-xl tab-wid-x1', render: (text, record) => record.admin?.name, },
        { title: 'Question', dataIndex: 'question_name', key: 'question_name', width: 200, className: 'table-header-xl tab-wid-x2' },
        { title: 'Obj A', dataIndex: 'objective_a', key: 'objective_a', width: 200, className: 'table-header-xl tab-wid-x2' },
        { title: 'Obj B', dataIndex: 'objective_b', key: 'objective_b', width: 200, className: 'table-header-xl tab-wid-x2' },
        { title: 'Obj C', dataIndex: 'objective_c', key: 'objective_c', width: 200, className: 'table-header-xl tab-wid-x2' },
        { title: 'Obj D', dataIndex: 'objective_d', key: 'objective_d', width: 200, className: 'table-header-xl tab-wid-x2' },
        { title: 'Answer', dataIndex: 'answer', key: 'answer', width: 200, className: 'table-header-xl tab-wid-x2' },
        { title: 'Answer_Desc', dataIndex: 'answer_description', key: 'answer_description', width: 100, className: 'table-header-xl tab-wid-x2' },
        { title: 'Date', dataIndex: 'updated_at', key: 'updated_at', width: 100, className: 'table-header-xl tab-wid-x2', render : (text) =>{
            const date = new Date(text);
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
            const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
            return `${formattedDate} ${formattedTime}`;
        }  },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            className: 'table-header-xl tab-wid-x3',
            render: (text, record) => {
                const status = Number(record.status); // Convert to number if needed

                return (
                    <span
                        style={{
                            padding: '5px 10px',
                            borderRadius: '4px',
                            color: 'white',
                            backgroundColor: status === 0 ? 'red' : 'green',
                        }}
                    >
                        {status === 0 ? 'Pending' : 'Approved'}
                    </span>
                );
            },
        },
        {
            title: 'Operations',
            key: 'operations',
            className: 'table-header-xl tab-wid-x3',
            render: (text, record) => (
                <>
                    {/* {!record.approved && (
                        <span>
                            <FontAwesomeIcon
                                onClick={() => confirmApprove(record.id, record.status == 0 ? 1 : 0)}
                                className="approve-icon"
                                icon={faCircleCheck}
                            />
                        </span>
                    )} */}
                    <span className="ant-divider" />
                    <span>
                        <FontAwesomeIcon
                            className="del-icon"
                            onClick={() => confirmDelete(record.id)}
                            icon={faTrashAlt}
                        />
                    </span>
                </>
            ),
        },
    ];


    // Pagination logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentData = data.slice(indexOfFirstRow, indexOfLastRow);

    const totalPages = Math.ceil(data.length / rowsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>
            <div className="main-container-examine-btn-section">
                < Header type={type} handleLogout={handleLogout} />
            </div>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <div className="input-text-box-main">
                        {/* <div className="input-box-examine">
                            <select
                                className="input-examine-master"
                                value={userId}
                                onChange={(e) => setUserId(e.target.value)}
                            >
                                <option value="">--User--</option>
                              
                            </select>
                        </div> */}

                        {/* <div className="input-box-examine">
                            <select
                                className="input-examine-master"
                                value={subjectId}
                                onChange={(e) => setSubjectId(e.target.value)}
                            >
                                <option value="">--Subject--</option>
                                {subjectId.data && subjectId.data.length > 0 ? (
                                    subjectId.data.map((subData) => (
                                        <option key={subData.id} value={subData.id}>
                                            {subData.subject_name}
                                        </option>
                                    ))
                                ) : (
                                    <option value="">No subjects available</option>
                                )}
                            </select>
                        </div> */}
                    </div>

                    <div className="approval-title-box-xl">
                        <div className="scroll-container">
                            <Table columns={columns} data={currentData} />
                            <div style={{ marginTop: "10px" }} className="pagination-controls">
                                <button className="BtnLeftRight" onClick={handlePrevPage} disabled={currentPage === 1}>
                                    <i class="fa-solid fa-angles-left"></i>
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button className="BtnLeftRight" onClick={handleNextPage} disabled={currentPage === totalPages}>
                                    <i class="fa-solid fa-angles-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {showModal && (
                    <DeleteConfirmationModal
                        onConfirm={handleConfirmDelete}
                        onCancel={handleCancelDelete}
                    />
                )}
                {showApproveModal && (
                    <ApproveConfirmationModal
                        onConfirm={handleConfirmApprove}
                        onCancel={handleCancelApprove}
                    />
                )}
                {loading && <Loader />}
            </div>
        </>
    );
};

export default FinalQuestionList;

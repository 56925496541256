import api, { getHeaders } from './Server';


export const getChildCategory = async () => {
    try {
        const response = await api.get('/childcategories',{
            headers: getHeaders()
        });
        console.log(response.data)
        return response.data;
    } catch (error) {
        console.error('Error fetching Child Categories:', error);
        throw error;
    }
};

export const createChildCategory = async (data) => {
    try {
        const response = await api.post('/childcategories', data,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error creating Child Categories:', error);
        throw error;
    }
};

export const getByIdChildCategory = async (id) => {
    try {
        const response = await api.get(`/childcategories/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching Child Categories ${id}:`, error);
        throw error;
    }
};

export const updateChildCategory = async (id, data) => {
    try {
        const response = await api.put(`/childcategories/${id}`, data);
        return response.data;
    } catch (error) {
        console.error(`Error updating Child Categories ${id}:`, error);
        throw error;
    }
};

export const deleteChildCategory = async (id) => {
    try {
        const response = await api.delete(`/childcategories/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting Child Categories ${id}:`, error);
        throw error;
    }
};

export const getChildCategoryIdBaseData = async (subcategoryId) => {
    try {
        const response = await api.get(`/childcategories/getById/${subcategoryId}`);
        if (response.data.data.length > 0) {
            return response.data.data;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching Child Categories:', error);
        throw error;
    }
};
// export default api;

import axios from 'axios';

// const local = 'http://localhost:8000/api';
const local = 'https://api.clearexams.co.in/api';
const api = axios.create({
    baseURL:  local, 
    // baseURL: server ? server : local, 
});

export const getHeaders = () => {
  const token = localStorage.getItem('token');
  if (token) {
      return {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
      };
  } else {
      return {
          'Content-Type': 'multipart/form-data',
      };
  }
};

export default api;  // Make sure to export the api instance

import React, { useState, useEffect, useContext } from "react";
import { createQuestion, getByIdQuestion, updateQuestion } from "../../../Api/ExamQuestion";
import { getSubject } from "../../../Api/Subject";
import { useParams } from "react-router-dom";
import InsertSuccessAlert from "../../../Components/Alert/Create";
import UpdateAlert from "../../../Components/Alert/Update";
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";
import CKEditor from "../CKEditor";
import { getPaperYear } from "../../../Api/PaperYears";
import { Button } from "bootstrap";

const EditQuestion = () => {
    const { type, handleLogout } = useContext(MyContext);
    const { id } = useParams(); 
    const [subjects, setSubjects] = useState([]);
    const [paperYears, setPaperYears] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [years, setYears] = useState(''); // Initialize with an empty string or default value
    const [insertSuccess, setInsertSuccess] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    useEffect(() => {
        const fetchUser = () => {
            const userData = localStorage.getItem('user');
            if (userData) {
                const user = JSON.parse(userData);
                setAdminLoginId(user);
            }
        };
    
        fetchUser(); // Call the function to actually set the state
    }, []);
    const [adminLoginId, setAdminLoginId] = useState(''); 

    const [inputValues, setInputValues] = useState({
        exam_questions: {
            subject_id: "",
            year_id: "", // Initialize with an empty string
            question_name: "",
            objective_a: "",
            objective_b: "",
            objective_c: "",
            objective_d: "",
            answer: "",
            answer_description: "",
            source: "",
            status: "0",
            adminLoginId: 'adminLoginId',
        }
    });

    // Fetch subjects
    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                setLoading(true);
                const response = await getSubject();
                setSubjects(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch data');
                setLoading(false);
            }
        };
        fetchSubjects();
    }, []);

    // Fetch question by ID for editing
    useEffect(() => {
        const fetchQuestionById = async () => {
            try {
                const response = await getByIdQuestion(id);
                const data = response.data;
                setInputValues({
                    exam_questions: {
                        subject_id: data.subject_id,
                        year_id: data.year_id, // Set year_id from the fetched data
                        question_name: data.question_name,
                        objective_a: data.objective_a,
                        objective_b: data.objective_b,
                        objective_c: data.objective_c,
                        objective_d: data.objective_d,
                        answer: data.answer,
                        answer_description: data.answer_description,
                        source: data.source,
                        status: data.status,
                    }
                });
                setYears(data.year_id); // Update the years state
            } catch (error) {
                console.error('Failed to fetch question by ID:', error);
            }
        };
        if (id) {
            fetchQuestionById();
        }
    }, [id]);

    // Handle input changes for non-RichEditor fields (like select)
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prevState) => ({
            ...prevState,
            exam_questions: {
                ...prevState.exam_questions,
                [name]: value,
            },
        }));
    };

    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        setYears(selectedYear);
        setInputValues((prevState) => ({
            ...prevState,
            exam_questions: {
                ...prevState.exam_questions,
                year_id: selectedYear, // Update year_id in inputValues
            },
        }));
    };

    useEffect(() => {
        const fetchPaperYears = async () => {
            try {
                const response = await getPaperYear();
                setPaperYears(response.data);
            } catch (error) {
                console.error('Failed to fetch paper years:', error);
            }
        };
        fetchPaperYears();
    }, []);

    // Handle changes from RichEditor
    const handleRichEditorChange = (name, value) => {
        setInputValues((prevState) => ({
            ...prevState,
            exam_questions: {
                ...prevState.exam_questions,
                [name]: value,
            },
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const formData = new FormData();
            const examQuestions = inputValues.exam_questions;
    
            // Append each field from exam_questions to formData
            formData.append('subject_id', examQuestions.subject_id || "");
            formData.append('year_id', examQuestions.year_id || "");
            formData.append('question_name', examQuestions.question_name || "");
            formData.append('objective_a', examQuestions.objective_a || "");
            formData.append('objective_b', examQuestions.objective_b || "");
            formData.append('objective_c', examQuestions.objective_c || "");
            formData.append('objective_d', examQuestions.objective_d || "");
            formData.append('answer', examQuestions.answer || "");
            formData.append('answer_description', examQuestions.answer_description || "");
            formData.append('source', examQuestions.source || "");
            formData.append('status', examQuestions.status || "0");
            formData.append('adminLoginId', examQuestions.adminLoginId || "0");
    
            // Call API to submit formData
            const isSuccess = await createQuestion(formData); // Replace with your API call function
            if (isSuccess) {
                setInsertSuccess(true);
                // Reset form values
                setInputValues({
                    exam_questions: {
                        subject_id: "",
                        year_id: "",
                        question_name: "",
                        objective_a: "",
                        objective_b: "",
                        objective_c: "",
                        objective_d: "",
                        answer: "",
                        answer_description: "",
                        source: "",
                        status: "0",
                        adminLoginId: 'adminLoginId',
                    }
                });
                window.location.reload();
            } else {
                setError('Failed to add question');
            }
        } catch (error) {
            console.error('Error in submitting:', error);
            setError('Internal Server Error: Please try again later');
        }
    };
    
    

    return (
        <>
            <div className="main-container-examine-btn-section">
                <Header SaveBtn={handleSubmit}  handleLogout={handleLogout} />
            </div>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className="main-header-examine-m">Question Master</div>

                    <div className="input-text-box-main">
                        <div className="input-box-examine">
                            <select
                                name="subject_id"
                                className="input-examine-master"
                                value={inputValues.exam_questions.subject_id}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="" disabled>--Select Subject--</option>
                                {subjects.map((subject) => (
                                    <option key={subject.id} value={subject.id}>
                                        {subject.subject_name}
                                    </option>
                                ))}
                            </select>
                            <label className="input-label-master-examine-fixed2">Subject</label>
                        </div>
                        <div className="input-box-examine">
                            <select
                                name="year_id"
                                className="input-examine-master"
                                value={years}
                                onChange={handleYearChange}
                                required
                            >
                                <option value="" disabled>--Select Year--</option>
                                {paperYears.map((data) => (
                                    <option key={data.id} value={data.id}>{data.year}</option>
                                ))}
                            </select>
                            <label className="input-label-master-examine-fixed2">Paper Years</label>
                        </div>
                    </div>

                    <div>
                        <div className="main-header-examine-m">Question</div>
                        <div className="input-box-examine2">
                            <CKEditor
                                className="input-text-master"
                                value={inputValues.exam_questions.question_name}
                                onChange={(value) => handleRichEditorChange('question_name', value)} 
                            />
                            <label className="input-label-master-examine-fixed">English Question</label>
                        </div>

                        <div className="input-text-box-main">
                            {["objective_a", "objective_b", "objective_c", "objective_d"].map((opt, index) => (
                                <div className="input-box-examine2" key={index}>
                                    <CKEditor
                                        value={inputValues.exam_questions[opt]}
                                        onChange={(value) => handleRichEditorChange(opt, value)} 
                                    />
                                    <label className="input-label-master-examine-fixed">
                                        Option {String.fromCharCode(65 + index)}
                                    </label>
                                </div>
                            ))}

                            <div className="input-box-examine2">
                                <select
                                    className="input-examine-master"
                                    value={inputValues.exam_questions.answer}
                                    name="answer"
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select an answer</option>
                                    {["objective_a", "objective_b", "objective_c", "objective_d"].map((opt, index) => (
                                        <option key={index} value={inputValues.exam_questions[opt]}>
                                            Option {String.fromCharCode(65 + index)}: {inputValues.exam_questions[opt]}
                                        </option>
                                    ))}
                                </select>
                                <label className="input-label-master-examine-fixed">Answer</label>
                            </div>
                        </div>

                        <div className="input-box-examine2">
                            <CKEditor
                                value={inputValues.exam_questions.answer_description}
                                onChange={(value) => handleRichEditorChange('answer_description', value)} 
                            />
                            <label className="input-label-master-examine-fixed">Answer Description</label>
                        </div>

                        <div className="input-box-examine2">
                            <CKEditor
                                value={inputValues.exam_questions.source}
                                onChange={(value) => handleRichEditorChange('source', value)} 
                            />
                            <label className="input-label-master-examine-fixed">Source</label>
                        </div>
                    </div>
                    <div style={{ paddingRight: "10px", display: "flex", justifyContent: "center" }}>
                        <Button onClick={handleSubmit} variant="contained">Submit</Button>
                    </div>
                </div>

                {insertSuccess && (<InsertSuccessAlert />)}
                {updateSuccess && (<UpdateAlert />)}
            </div>
        </>
    );
};

export default EditQuestion;

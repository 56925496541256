export const getHeaders = () => {
    const token = localStorage.getItem('token');
    if (token) {
        return {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
        };
    } else {
        return {
            'Content-Type': 'multipart/form-data',
        };
    }
};

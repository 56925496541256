import React, { useState, useEffect, useContext } from "react";
import { getcCategories } from "./../../../api"
import { createPaperYear, getByIdPaperYear, updatePaperYear, getPaperYear, deletePaperYear } from "../../../Api/PaperYears";
import { useParams } from "react-router-dom";
import Loader from "../../../Components/Loader";
import InsertSuccessAlert from "../../../Components/Alert/Create";
import UpdateAlert from "../../../Components/Alert/Update";
import Header from "../Props/Header";
import { useNavigate } from 'react-router-dom';
import { MyContext } from "../../../AppRoutes";
import DeleteConfirmationModal from "../../../Components/DeleteConfirmationModal";
import { Button } from "@mui/material";

const CreateYear = () => {
    const { type, handleLogout } = useContext(MyContext)
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [insertSuccess, setInsertSuccess] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [data, setData] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updatedData, setUpdatedData] = useState({})
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [errors, setErrors] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    const message = "Paper Years ";
    const nextPage = "/Admin/YearList";
    const [inputValues, setInputValues] = useState({
        category_id: "",
        year: "",
    });

    useEffect(() => {
        const fetchCategoryById = async () => {
            // setloading(true);
            var data = await getByIdPaperYear(id);
            if (data) {
                console.log(data);
                setUpdatedData(data.data)
                setInputValues(data.data)
            }
        }
        fetchCategoryById()
    }, [id])

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await getcCategories();
                setData(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch categories');
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const handleDataStore = (e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    useEffect(() => {
        fetchCategoriesAndSubcategories();
    }, []);
    const fetchCategoriesAndSubcategories = async () => {
        try {
            const [categoriesResponse, subcategoriesResponse] = await Promise.all([
                getcCategories(),
                getPaperYear(),
            ]);

            const categoriesData = categoriesResponse.data;
            const subcategoriesData = subcategoriesResponse.data;

            const combinedData = subcategoriesData.map(subcategory => {
                const category = categoriesData.find(cat => cat.id === subcategory.category_id);
                return {
                    ...subcategory,
                    category_name: category ? category.category_name : 'Unknown',
                };
            });
            setLoading(true);

            setCategories(categoriesData);
            setSubjects(combinedData);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };

    const rowsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(subjects.length / rowsPerPage);
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedSubjects = subjects.slice(startIndex, endIndex);
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    // ***************Delete*********************

    const handleDelete = async (id) => {
        try {
            await deletePaperYear(id);
            setSubjects(subjects.filter(item => item.id !== id));
        } catch (error) {
            setError('Error deleting category');
        }
    };

    const confirmDelete = (id) => {
        setDeleteId(id);
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        await handleDelete(deleteId);
        setShowModal(false);
        setDeleteId(null);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
        setDeleteId(null);
    };

    // *******************Edit**********************

    const handleEdit = async (id) => {
        debugger
        navigate(`/Admin/CreateYear/${id}`);
        try {
            const data = await getByIdPaperYear(id);
            if (data) {
                setInputValues(data.data);
                setUpdatedData(data.data);
                console.log(data.data);
            }
        } catch (err) {
            setError('Failed to fetch subject data for editing');
        }
    };

    const validateInputs = () => {
        const newErrors = {};

        if (!inputValues.category_id) {
            newErrors.category_id = 'Category Id is required';
        }
        if (!inputValues.year) {
            newErrors.year = 'year  is add required';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };


    const SaveBtn = async (e) => {
        debugger
        e.preventDefault();
        if (Object.keys(updatedData).length !== 0) {
            // Update the existing record
            const updatedData = await updatePaperYear(inputValues.id, inputValues);
            console.log('Updating data:', updatedData);
            // setSuccess('Category updated successfully');
            setUpdateSuccess(true);
        } else {

            if (!validateInputs()) {
                return; // Stop execution if validation fails
            }
            const formData = new FormData();
            formData.append('category_id', inputValues.category_id);
            formData.append('year', inputValues.year);
            try {
                const isSuccess = await createPaperYear(formData);
                console.log('Submitting data:', isSuccess);
                // setSuccess('Sub Category added successfully');
                setInsertSuccess(true)

                if (isSuccess) {
                    // Clear input values
                    setInputValues({
                        category_id: "",
                        year: "",

                    });
                    setError(null);
                    // Refresh the page after successful save
                    window.location.reload();
                } else {
                    setError('Failed to add category');
                    setSuccess(null);
                }
            } catch (error) {
                console.error('Error adding category:', error);
                setError('Internal Server Error: Please try again later');
                setSuccess(null);
            }
        }
    };


    return (
        <>
            <div className="main-container-examine-btn-section">
                < Header type={type}  handleLogout={handleLogout} />

            </div>

            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className="main-header-examine-m">
                        Paper Year Master
                    </div>
                    <div className="input-text-box-main">

                        <div className="input-box-examine">
                            <select
                                name="category_id"
                                className="input-examine-master"
                                value={inputValues.category_id}
                                onChange={handleDataStore}
                                required
                            >
                                <option value="" disabled>--Select Category--</option>
                                {data && data
                                    .filter((category) => category.status === "0").map
                                    ((category) => (
                                        <option key={category.id} value={category.id}>{category.category_name}</option>
                                    ))}
                            </select>
                            {errors.category_id && <span className="error">{errors.category_id}</span>}
                            <label className="input-label-master-examine-fixed2"> Category</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                name="year"
                                type="text"
                                className="input-examine-master"
                                value={inputValues.year}
                                onChange={handleDataStore}
                                required
                            />
                            {errors.year && <span className="error">{errors.year}</span>}
                            <label className="input-label-master-examine">Year</label>
                        </div>

                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {success && <p style={{ color: 'green' }}>{success}</p>}
                        {loading && (< Loader />)}
                        {insertSuccess && (<InsertSuccessAlert />)}
                        {updateSuccess && (<UpdateAlert message={message} nextPage={nextPage} />)}

                    </div>
                    <div className="App">
                        <h1 className="h2-tag-define">Year List</h1>
                        <div style={{ display: "flex", width: "60%" }}></div>
                        <table style={{ width: "90%" }}>
                            <thead>
                                <tr className="tr-xl">
                                    <th>SNo</th>
                                    <th>Category Name</th>
                                    <th>Years </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedSubjects.map((subject, index) => (
                                    <tr key={subject.id || index} className="tr-xl2">
                                        <td>{startIndex + index + 1}</td>
                                        <td>{subject.category_name}</td>
                                        <td>{subject.year}</td>
                                        <td>
                                            <button onClick={() => handleEdit(subject.id)} className="delete-btn-sub-xl">Edit</button>
                                            <button onClick={() => confirmDelete(subject.id)} className="delete-btn-sub-xl">Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                    <div style={{ paddingRight: "10px", display: "flex", justifyContent: "center" }}>
                        <Button onClick={SaveBtn} variant="contained">Submit</Button>
                    </div>
                </div>
                {insertSuccess && (<InsertSuccessAlert />)}
                {updateSuccess && (<UpdateAlert message={message} nextPage={nextPage} />)}
                {showModal && (
                    <DeleteConfirmationModal
                        onConfirm={handleConfirmDelete}
                        onCancel={handleCancelDelete}
                    />
                )}
            </div>
        </>
    );
};

export default CreateYear;

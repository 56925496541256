import React, { useState, useEffect, useContext } from "react";
import { getcCategories } from "./../../../api"
import { createSubCategory, getByIdSubCategory, updateSubCategory } from "../../../Api/SubCategory";
import { useParams } from "react-router-dom";
import Loader from "../../../Components/Loader";
import InsertSuccessAlert from "../../../Components/Alert/Create";
import UpdateAlert from "../../../Components/Alert/Update";
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";
import { Button } from "@mui/material";

const SubCategoryMaster = () => {
    const {type, handleLogout} = useContext(MyContext)
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [insertSuccess, setInsertSuccess] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updatedData, setUpdatedData] = useState({})
    const [errors, setErrors] = useState({});
    const { id } = useParams();
    const message = "Sub Category ";
    const nextPage = "/Admin/SubCategoryList";
    const [inputValues, setInputValues] = useState({
        category_id: "", // Add a field for the parent category ID
        sub_category_name: "",
        status: "",
        priority: "",
    });

    useEffect(() => {
        const fetchCategoryById = async () => {
            // setloading(true);
            var data = await getByIdSubCategory(id);
            if (data) {
                console.log('-----sreqwa---->',data);
                setUpdatedData(data.data)
                // setloading(false);
                setInputValues(data.data)
            }
        }
        fetchCategoryById()
    }, [id])

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await getcCategories();
                setData(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch categories');
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const handleDataStore = (e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateInputs = () => {
        const newErrors = {};

        if (!inputValues.category_id) {
            newErrors.category_id = 'Category Id is required';
        }
        if (!inputValues.sub_category_name) {
            newErrors.sub_category_name = 'Sub Category name is required';
        }

        if (!inputValues.status) {
            newErrors.status = 'Status is required';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };


    const SaveBtn = async (e) => {
        debugger
        e.preventDefault();
        if (Object.keys(updatedData).length !== 0) {
            // Update the existing record
            const updatedData = await updateSubCategory(inputValues.id, inputValues);
            console.log('Updating data:', updatedData);
            // setSuccess('Category updated successfully');
            setUpdateSuccess(true);
        } else {

            if (!validateInputs()) {
                return; // Stop execution if validation fails
            }
            const formData = new FormData();
            formData.append('category_id', inputValues.category_id);
            formData.append('sub_category_name', inputValues.sub_category_name);
            formData.append('status', inputValues.status);
            formData.append('priority', inputValues.priority);

            try {
                const isSuccess = await createSubCategory(formData);
                console.log('Submitting data:', isSuccess);
                // setSuccess('Sub Category added successfully');
                setInsertSuccess(true)

                if (isSuccess) {
                    // Clear input values
                    setInputValues({
                        category_id: "",
                        sub_category_name: "",
                        status: "",
                        priority: "",
                    });
                    setError(null);
                    // Refresh the page after successful save
                    window.location.reload();
                } else {
                    setError('Failed to add category');
                    setSuccess(null);
                }
            } catch (error) {
                console.error('Error adding category:', error);
                setError('Internal Server Error: Please try again later');
                setSuccess(null);
            }
        }
    };


    return (
        <>
         <div className="main-container-examine-btn-section">
                < Header type={type}  handleLogout={handleLogout} />
               
            </div>
          
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className="main-header-examine-m">
                        Sub Category Master
                    </div>
                    <div className="input-text-box-main">

                        <div className="input-box-examine">
                            <select
                                name="category_id" 
                                className="input-examine-master"
                                value={inputValues.category_id}
                                onChange={handleDataStore}
                                required
                            >
                                <option value="" disabled>--Select Category--</option>
                                {data && data
                                    .filter((category) => category.status === "0").map
                                    ((category) => (
                                        <option key={category.id} value={category.id}>{category.category_name}</option>
                                    ))}
                            </select>
                            {errors.category_id && <span className="error">{errors.category_id}</span>}
                            <label className="input-label-master-examine-fixed2"> Category</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                name="sub_category_name"
                                type="text"
                                className="input-examine-master"
                                value={inputValues.sub_category_name}
                                onChange={handleDataStore}
                                required
                            />
                            {errors.sub_category_name && <span className="error">{errors.sub_category_name}</span>}
                            <label className="input-label-master-examine">Sub Category Name</label>
                        </div>
                        <div className="input-box-examine">
                            <select
                                name="status"
                                className="input-examine-master"
                                value={inputValues.status}
                                onChange={handleDataStore}
                                required
                            >
                                <option value="">Select Status</option>
                                <option value="0">Active</option>
                                <option value="1">Inactive</option>
                            </select>
                            <label className="input-label-master-examine-fixed">Status</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="priority"
                                className="input-examine-master"
                                value={inputValues.priority}
                                onChange={handleDataStore}
                                required
                            />
                            <label className="input-label-master-examine">Priority</label>
                        </div>

                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {success && <p style={{ color: 'green' }}>{success}</p>}
                        {loading && (< Loader />)}
                        {insertSuccess && (<InsertSuccessAlert />)}
                        {updateSuccess && (<UpdateAlert message={message} nextPage={nextPage} />)}

                    </div>
                    <div style={{ paddingRight: "10px", display: "flex", justifyContent: "center" }}>
                        <Button onClick={SaveBtn} variant="contained">Submit</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubCategoryMaster;

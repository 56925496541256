import api, {getHeaders} from "./Server"

export const getSubject = async () => {
    try {
        const response = await api.get('/subjects',{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching subjects:', error);
        throw error;
    }
};


export const createSubject = async (subjects) => {
    try {
        console.log('Subject Name:', subjects.subject_name);
        console.log('Priority:', subjects.priority);
        console.log('status:', subjects.status);
        // const response = await fetch('/subjects', {
        //     method: 'POST',
        //     body: JSON.stringify(subjects),
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        // });
        const response = await api.post('/subjects', subjects, {
          headers: getHeaders()
        });
        console.log(response.subjects);
        return response.data;
    } catch (error) {
        console.error('Error creating subjects:', error);
        throw error;
    }
};




export const getByIdSubject = async (id) => {
    try {
        const response = await api.get(`/subjects/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching category ${id}:`, error);
        throw error;
    }
};

export const updateSubject = async (id, data) => {
    try {
        const response = await api.put(`/subjects/${id}`, data,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error updating category ${id}:`, error);
        throw error;
    }
};

export const deleteSubject = async (id) => {
    try {
        const response = await api.delete(`/subjects/${id}`,{
            headers: getHeaders()
        });
        return response.data;
    } catch (error) {
        console.error(`Error deleting subcategories ${id}:`, error);
        throw error;
    }
};


export default api;

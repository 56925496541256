
import Mainpage from "../AppRoutes";
import "./../Assets/css/style1.css"
// import "./../Assets/css/taiwan.scss"
import "./../Assets/css/NavBarNew.css"
import "./../Assets/css/ResponsiveTablet.css"
import "./../Assets/css/MobileResponsive.css";
import "./../Assets/css/admin.css";
import "./../Assets/css/ExamCard.css"
import "./../Assets/css/UserSideBar.css"
import "./../Assets/css/UsersNavbar.css"
import "./../Assets/css/Privacy.css"


function HomePage() {
    return (
       
      <Mainpage />
      

    )

}

export default HomePage;

import React, { useState, useEffect, useContext } from "react";
import { createSubject, updateSubject, getByIdSubject, getSubject, deleteSubject } from "../../../Api/Subject";
import { useParams } from "react-router-dom";
import InsertSuccessAlert from "../../../Components/Alert/Create";
import UpdateAlert from "../../../Components/Alert/Update";
import DeleteConfirmationModal from "../../../Components/DeleteConfirmationModal";
import { useNavigate } from "react-router-dom";
import Header from "../Props/Header";
import { MyContext } from "../../../AppRoutes";
import { Button } from "@mui/material";

const SubjectMaster = () => {
    const { type , handleLogout} = useContext(MyContext)
    const navigate = useNavigate();
    const [updatedData, setUpdatedData] = useState({});
    const [subjects, setSubjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [insertSuccess, setInsertSuccess] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const { id } = useParams();
    const message = "Subject";
    const nextPage = "/Admin/SubjectMaster";
    const [inputValues, setInputValues] = useState({
        subject_name: "",
        status: "",
        priority: ""
    });

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/Admin');
        }
    },[])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        const fetchCategoryById = async () => {
            try {
                const data = await getByIdSubject(id);
                if (data) {
                    setUpdatedData(data.data);
                    setInputValues(data.data);
                }
            } catch (err) {
                setError('Failed to fetch subject data');
            }
        };
        if (id) {
            fetchCategoryById();
        }
    }, [id]);

    const SaveBtn = async (e) => {
        e.preventDefault();
        if (Object.keys(updatedData).length !== 0) {
            const updatedData = await updateSubject(inputValues.id, inputValues);
            console.log('Updating data:', updatedData);
            setUpdateSuccess(true);
        } else {
            try {
                const formData = new FormData();
                console.log('Input Values:', inputValues);
                formData.append('subject_name', inputValues.subject_name);
                formData.append('status', inputValues.status);
                formData.append('priority', inputValues.priority);
                
                const isSuccess = await createSubject(formData);
                console.log('Submitting data:', isSuccess);
                setInsertSuccess(true);
    
                if (isSuccess) {
                    setInputValues({
                        subject_name: "",
                        status: "",
                        priority: "",
                    });
                    setError(null);
                    window.location.reload();
                } else {
                    setError('Failed to add category');
                    setSuccess(null);
                }
            } catch (error) {
                console.error('Error adding category:', error);
                setError('Internal Server Error: Please try again later');
                setSuccess(null);
            }
        }
    };
    
    useEffect(() => {
        fetchSubject();
    }, []);

    const fetchSubject = async () => {
        try {
            setLoading(true);
            const response = await getSubject();
            setSubjects(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };

    const rowsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(subjects.length / rowsPerPage);
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedSubjects = subjects.slice(startIndex, endIndex);
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    // ***************Delete*********************

    const handleDelete = async (id) => {
        try {
            await deleteSubject(id);
            setSubjects(subjects.filter(item => item.id !== id));
        } catch (error) {
            setError('Error deleting category');
        }
    };

    const confirmDelete = (id) => {
        setDeleteId(id);
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        await handleDelete(deleteId);
        setShowModal(false);
        setDeleteId(null);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
        setDeleteId(null);
    };

    // *******************Edit**********************
    const handleEdit = async (id) => {
        debugger
        try {
            const data = await getByIdSubject(id);
            if (data) {
                setInputValues(data.data);
                setUpdatedData(data.data);
                console.log(data.data);
            }
        } catch (err) {
            setError('Failed to fetch subject data for editing');
        }
    };

    return (
        <>
            <div className="main-container-examine-btn-section">
                < Header type={type}  handleLogout={handleLogout} />
            </div>
            <div className="main-container-examine">
                <div className="main-container-examine-master">
                    <div className="main-header-examine-m">Subject Master</div>
                    <div className="input-text-box-main">
                        <div className="input-box-examine">
                            <input
                                type="text"
                                name="subject_name"
                                value={inputValues.subject_name}
                                className="input-examine-master"
                                onChange={handleInputChange}
                                required
                            />
                            <label className="input-label-master-examine">Subject Name</label>
                        </div>
                        <div className="input-box-examine">
                            <select
                                name="status"
                                className="input-examine-master"
                                value={inputValues.status}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Status</option>
                                <option value="0">Active</option>
                                <option value="1">Inactive</option>
                            </select>
                            <label className="input-label-master-examine-fixed">Status</label>
                        </div>
                        <div className="input-box-examine">
                            <input
                                type="number"
                                name="priority"
                                value={inputValues.priority}
                                className="input-examine-master"
                                onChange={handleInputChange}
                                required
                            />
                            <label className="input-label-master-examine">Priority</label>
                        </div>
                    </div>
                    <div style={{ paddingRight: "10px", display: "flex", justifyContent: "center" }}>
                        <Button onClick={SaveBtn} variant="contained">Submit</Button>
                    </div>
                    <div className="App">
                        <h1 className="h2-tag-define">Subject List</h1>
                        <div style={{ display: "flex", width: "60%" }}></div>
                        <table style={{ width: "90%" }}>
                            <thead>
                                <tr className="tr-xl">
                                    <th>SNo</th>
                                    <th>Subject Name</th>
                                    <th>Status</th>
                                    <th>Priority</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedSubjects.map((subject, index) => (
                                    <tr key={subject.id || index} className="tr-xl2">
                                        <td>{startIndex + index + 1}</td>
                                        <td>{subject.subject_name}</td>
                                        <td>{subject.status}</td>
                                        <td>{subject.priority}</td>
                                        <td>
                                            <button onClick={() => handleEdit(subject.id)} className="delete-btn-sub-xl">Edit</button>
                                            <button onClick={() => confirmDelete(subject.id)} className="delete-btn-sub-xl">Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
                {insertSuccess && (<InsertSuccessAlert />)}
                {updateSuccess && (<UpdateAlert message={message} nextPage={nextPage} />)}
                {showModal && (
                    <DeleteConfirmationModal
                        onConfirm={handleConfirmDelete}
                        onCancel={handleCancelDelete}
                    />
                )}
            </div>
        </>
    );
};

export default SubjectMaster;

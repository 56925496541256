import React, { useState } from 'react';
import { GoArrowLeft } from "react-icons/go";
import img from './../Assets/img/logo.jpeg';
import Man from "./../Assets/img/man.png";
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Instructions = () => {
    // const history = useHistory();
    const navigate = useNavigate();

    const [showSecondSection, setShowSecondSection] = useState(false);

    const toggleSecondSection = () => {
        setShowSecondSection(true);
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleButtonClick = () => {
        if (isChecked) {
            navigate('/TestSection');
        }
    };

    return (
        <div className="test-container">
            <div className="company_logo instraction-test-container-title">
                <div>
                    <img className='texi-img-xl2' src={img} alt="logo" />
                </div>
                <div><span className='text-title-ss-xl'>SSC Selection Post Expected Paper (Based on: 20th June 2024)</span></div>
            </div>

            <div className='instraction-text-title-xxl'>
                <div className='instraction-text-title-xxl-left'>
                    {!showSecondSection && (
                        <>
                            <div className="instraction-text-title-xxl-left-xxl">
                                <span className='General-title-text-xn'>General Instructions:</span>
                                <div className="test_paragraph ">
                                    <p>1. The clock will be set at the server. The countdown timer at the top right corner of the screen will
                                        display the remaining time available for you to complete the examination. When the timer reaches zero,
                                        the examination will end by itself. You need not terminate the examination or submit your paper.</p>
                                    <p>2. The Question Palette displayed on the right side of the screen will show the status of each question
                                        using one of the following symbols:</p>
                                    <ul>
                                        <li>
                                            <i className="fa-regular fa-circle hh-curcle-normal"></i> You have not visited the question yet
                                        </li>
                                        <li>
                                            <i className="fa-regular fa-circle hh-curcle-danger"></i> You have not answered the question.
                                        </li>
                                        <li>
                                            <i className="fa-regular fa-circle hh-curcle-text-success"></i>  You have answered the question.
                                        </li>
                                        <li>
                                            <i className="fa-regular fa-circle hh-curcle-text-info"></i>   You have NOT answered the question but have marked the question
                                            for review.
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-check hh-curcle-text-success2"></i>
                                            You have answered the question but marked it for
                                            review.
                                        </li>
                                    </ul>
                                    <p>The <strong>Mark For Review</strong> status for a question simply indicates that you would like to look
                                        at the question again. If a question is answered but marked for review, then the answer will be
                                        considered for evaluation unless the status is modified by the candidate.</p>
                                    <strong>Navigation to a Question:</strong>
                                    <p>3. To answer a question, do the following:</p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Click on the question number in the Question Palette at the right
                                        of your screen to go to that numbered question directly. Note that using this option does NOT save your
                                        answer to the current question.</p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Click on <strong>SAVE & Next</strong> to save your answer for the
                                        current question and then go to the next question.</p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. Click on <strong>Mark for Review & Next</strong> to save your
                                        answer for the current question and also mark it for review and then go to the next question.</p>
                                    <p>Note that your answer for the current question will not be saved if you navigate to another question
                                        directly by clicking on a question number without saving the answer to the previous question.</p>
                                    <p>You can view all the questions by clicking on the <strong>Question Paper</strong> button. <strong
                                        className='text-danger'>This feature is provided so that if you want, you can just see the entire
                                        question paper at a glance.</strong></p>

                                    <h4>Answering a Question :</h4>
                                    <div >
                                        <div>
                                            <p> 4. Procedure for answering a multiple choice (MCQ) type question:</p>
                                            <ol className='custom-counter' style={{ marginLeft: "30px" }}>
                                                <li>Choose one answer from the 4 options (A,B,C,D) given below the question
                                                    <span className="hide-on-railway">, click on the bubble placed before the chosen option.</span>
                                                </li>
                                                <li className="hide-on-railway">To deselect your chosen answer, click on the bubble of the chosen option again or click on the <b><span className="hide-on-railway">Clear Response</span> <span className="show-on-railway">Erase Answer</span></b> button</li>
                                                <li>To change your chosen answer, click on the bubble of another option.</li>
                                                <li>To save your answer, you MUST click on the <b>Save &amp; Next</b></li>
                                            </ol>
                                        </div>
                                        <li>
                                            <p> 5. Procedure for answering a numerical answer type question :</p>
                                            <ol className='custom-counter' style={{ marginLeft: "30px" }}>
                                                <li>To enter a number as your answer, use the virtual numerical keypad.</li>
                                                <li>A fraction (e.g. -0.3 or -.3) can be entered as an answer with or without "0" before the decimal point. <span >As many as four decimal points, e.g. 12.5435 or 0.003 or -932.6711 or 12.82 can be entered.</span></li>
                                                <li>To clear your answer, click on the <b>Clear Response</b> button</li>
                                                <li>To save your answer, you MUST click on the <b>Save &amp; Next</b></li>
                                            </ol>
                                        </li>
                                        <li ng-show="isJeeTestInterfaceUsed" className="ng-hide">
                                            <p> 6. Procedure for answering a multiple correct answers (MCAQ) type question</p>
                                            <ol className='custom-counter' style={{ marginLeft: "30px" }}>
                                                <li>Choose one or more answers from the 4 options (A,B,C,D) given below the question, click on the bubble placed before the chosen option.</li>
                                                <li>To deselect your chosen answer, click on the checkbox of the chosen option again</li>
                                                <li>To clear your marked responses, click on the <b>Clear Response</b> button</li>
                                                <li>To save your answer, you MUST click on the <b>Save &amp; Next</b> button</li>
                                            </ol>
                                        </li>
                                        <div>
                                            <p>6. To mark a question for review, click on the <b>Mark for Review <span className="hide-on-railway">&amp; Next</span></b> button. If an answer is selected (for MCQ/MCAQ) entered (for numerical answer type) for a question that is <b>Marked for Review</b> , that answer will be considered in the evaluation unless the status is modified by the candidate.</p>
                                        </div>
                                        <div>
                                            <p>7. To change your answer to a question that has already been answered, first select that question for answering and then follow the procedure for answering that type of question.</p>
                                        </div>
                                        <div>
                                            <p>8. Note that ONLY Questions for which answers are <b>saved</b> or <b>marked for review after answering</b> will be considered for evaluation.</p>
                                        </div>
                                        <div>
                                            <p>9. Sections in this question paper are displayed on the top bar of the screen. Questions in a Section can be viewed by clicking on the name of that Section. The Section you are currently viewing will be highlighted.</p>
                                        </div>
                                        <div>
                                            <p>10. After clicking the <b>Save &amp; Next</b> button for the last question in a Section, you will automatically be taken to the first question of the next Section in sequence.</p>
                                        </div>
                                        <div>
                                            <p> 11. You can move the mouse cursor over the name of a Section to view the answering status for that Section.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="test_pagination">
                                <div>
                                    <button style={{ border: "none" }} className='text-info-xl-go-to' to="">
                                        <GoArrowLeft /> Go to Back</button>
                                </div>
                                <div>
                                    <button onClick={toggleSecondSection} className='btn-btn-info-xl1a'>Next</button>

                                </div>
                            </div>
                        </>
                    )}

                    {showSecondSection && (
                        <>
                            <div className="instraction-text-title-xxl-left-xxl">
                                <>
                                    <div className='start_mark_dist'>
                                        <div className='text-title-main-xa'>
                                            <h4 className='hhh4'>SSC Selection Post (Graduation Level) Memory Based Paper(Held on: 21st June Shift 3)</h4>
                                        </div>
                                        <div className="pro_user-flex">
                                            <div className="mark-distribute_min ">
                                                <p className='mark_min'><strong>Duration: 60 Mins</strong></p>
                                            </div>
                                            <div className="mark-distribute_ma ">
                                                <p className='mark_min'><strong>Maximum Marks: 200</strong></p>
                                            </div>
                                        </div>
                                        <div className="instruction">
                                            <p className='hhh-title'><strong>Reading the following instruction carefully.</strong></p>
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;1. The Test contain 100 questions</p>
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;2. Each question has 4 options out of which only one is correct.</p>
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;3. You have to finish the test in 60 mins.</p>
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;4. Try not to guess the answer as their nagative marking.</p>
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;5. You will be awarded 2 marks for each correct and there answer and there is 0.5 nagetive marking.</p>
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;6. There is no panalty for the questions that you have not attempted.</p>
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;7. Once you start the quiz,you will not be allowed to reattempt it.Make sure that you complete the quiz before you submit the quiz and/or close the browser. </p>
                                        </div>
                                        <div className=" selecting-langauge-text">
                                            <h5 className='text-title-hhh2'>Choose your default language:</h5>
                                            <select name="language-text-cc" className="language-text-cc">
                                                <option value="01">Select</option>
                                                <option value="02">English</option>
                                                <option value="03">Hindi</option>
                                            </select>
                                        </div>
                                        <div className="come_language-wewq">
                                            <p className='sdf-danger-color'>&nbsp;&nbsp;&nbsp;&nbsp;Please all note questions will appear in your default language can be changed for a particular question later on </p>
                                        </div>
                                        <div className="declare">
                                            <p><strong>Declaration:</strong></p>
                                            <div style={{ display: "flex" }}>

                                                <div>
                                                    <label className="checkbox-container" >
                                                        <input className="custom-checkbox"
                                                            checked={isChecked}
                                                            onChange={handleCheckboxChange} type="checkbox" />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div>

                                                    <p className='text-disclaimer-xl2'>&nbsp;&nbsp;<strong>I have read all the instructions carefully and have understood team.I agree not to cheat or use unfair means in this examination.
                                                        I understood that using  unfair means of any sort for my own or someone else's advantage will lead to my immediate disqualification.The decision of Pariksha.
                                                        com will be final these matters and cannot be appealed. </strong></p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </>
                            </div>
                            <div style={{ position: "relative" }} className="test_pagination2">
                                <div className='previous-text-sec1'>
                                    <button className='previous-btn-xl'>Previous</button>
                                </div>
                                <div >
                                    <div className="tooltip-container">
                                        <button
                                            className={`btn-btn-info-xl1a2 ${!isChecked ? 'disabled' : ''}`}
                                            disabled={!isChecked}
                                            onClick={handleButtonClick}
                                            data-tooltip="You must agree to the declaration to proceed"
                                        >
                                            I am Ready to Begin
                                        </button>
                                        {!isChecked && (
                                            <div className="tooltip">
                                                You must agree to the declaration to proceed
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="instraction-text-title-xxl-right">
                    <div className="panel-profile ">
                        <div className='img-main-xl5'>
                            <img className='texi-img-xl4' src={Man} alt="logo" />
                        </div>
                        <div className='text-title-name-xm'>
                            <h3 className='profile-member-name-xl '>Viliyan</h3>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    )
}

export default Instructions;